import React from 'react';
import {ConfigProvider, Space} from "antd";
import {Collapse, CollapseProps} from "../components/Collapse";

const CollapsesPage = () => {
    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Сведения о лице, подозреваемом, обвиняемом в совершении преступления',
            children: <p>{text}</p>,
        }
    ];

    const multiItems: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Сведения о лице, подозреваемом, обвиняемом в совершении преступления',
            children: <p>{text}</p>,
        },
        {
            key: '2',
            label: 'Сведения о лице, подозреваемом, обвиняемом в совершении преступления',
            children: <p>{text}</p>,
        },
        {
            key: '3',
            label: 'Сведения о лице, подозреваемом, обвиняемом в совершении преступления',
            children: <p>{text}</p>,
        }
    ];


    return (
        <Space direction="vertical" size={20}>
            <h3>Collapses</h3>
            <ConfigProvider theme={{
                token: {
                    fontFamily: "Montserrat, sans-serif",
                },
                components: {
                    Collapse: {
                        colorBorder: "rgb(182,203,220)",
                        headerBg: "rgb(255,255,255)",
                        colorTextHeading: "rgb(1,63,84)",
                        colorTextDisabled: "rgb(189,194,197)",
                        headerPadding: "8px 12px",
                        contentPadding: "16px 36px",
                    },
                }
            }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: 10, width: 1000}}>
                    <Collapse items={items} />
                    <Collapse items={multiItems} />
                    <Collapse items={items} />
                    <Collapse items={items} collapsible="disabled"/>
                    <Collapse items={items} bordered={false}/>
                    <Collapse items={multiItems} bordered={false}/>
                </div>

            </ConfigProvider>
        </Space>
    );
};

export default CollapsesPage;
