import * as React from "react";
import { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="filter" className="anticon anticon-filter">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.324 17.649a7.324 7.324 0 1 1 5.97-3.08L21 19.273 19.274 21l-4.706-4.705a7.292 7.292 0 0 1-4.244 1.354Zm0-2.442a4.883 4.883 0 1 0 0-9.766 4.883 4.883 0 0 0 0 9.766Z"
    />
    <circle className='active' cx="20" cy="4" r="2" fill="#EF5550"/>
  </svg>
</span>);
export default SvgSearch;
