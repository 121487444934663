import * as React from "react";
import { SVGProps } from "react";
const SvgStatForms = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="forms" className="anticon anticon-forms">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8H2V4h8l2 2h10v14H2v-2h18V8h-9L9 6H4v2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 18H2V8h8l2 2h10v8h-2v-6h-9l-2-2H4v8Z"
    />
  </svg>
</span>);
export default SvgStatForms;
