import * as React from "react";
import { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="copy" className="anticon anticon-copy">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15 5H21V22H7V17H15V5ZM15 7H19V20H9V17H15V7Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2H17V19H3V7L8 2ZM9 4H15V17H5V8L9 4Z" />
    <rect x="8" y="2" width="2" height="7" />
    <rect x="3" y="7" width="7" height="2" />
    </svg>
    </span>
);
export default SvgCopy;