import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const TooltipComponentToken = {
	/// This color is used to draw the user's strong attention to the background color, and is currently only used in the background color of Tooltip.	string rgba(0, 0, 0, 0.85)
	colorBgSpotlight: Colors.mainBlue,
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: Colors.mainBlue,
	/// Control the highlight color of text with background color, such as the text in Primary Button components.	string #fff
	colorTextLightSolid: Colors.mainWhite,
	/// Border radius of base components	number 6
	borderRadius: 6,
	/// XS size border radius, used in some small border radius components, such as Segmented, Arrow and other components with small border radius.	number 2
	borderRadiusXS: 2,
	/// Control the secondary box shadow style of an element.	string 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
	boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
	/// The height of the basic controls such as buttons and input boxes in Ant Design	number 32
	controlHeight: 32,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	// fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 14,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Motion speed, fast speed. Used for small element animation interaction.	string 0.1s
	motionDurationFast: '0.1s',
	/// Preset motion curve.	string cubic-bezier(0.78, 0.14, 0.15, 0.86)
	motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
	/// Preset motion curve.	string cubic-bezier(0.08, 0.82, 0.17, 1)
	motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
	/// Control the small padding of the element.	number 12
	paddingSM: 12,
	/// Control the extra small padding of the element.	number 8
	paddingXS: 8,
	/// The size of the component arrow	number 16
	sizePopupArrow: 16,
}