import React, {Key, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {assign, castArray, difference, filter, find, flatMap, includes, keys, map, union, without} from "lodash";
import {TreeNode, DepartmentTreeProps, DepartmentShortInfo} from './component.types';
import {StyledTreeSelect, StyledWrapper, } from "./styled";
import {ContextMenu} from './contextmenu';
import { loadUnits } from './api/deps-api';
import { buildTreeData, getDescendants, getSiblings, translateToNode } from './funcs';
import { Tree, TreeSelect, TreeSelectProps } from 'antd';

const DepartmentTree = ({mode, withContextMenu, withSelectAll, initValues, showCodes, loadData, ...props}: DepartmentTreeProps) => {
  const [deps, setDeps] = useState<DepartmentShortInfo[]>();
  const [treeData, setTreeData] = useState<TreeNode[]>( [] );
  const [rawData, setRawData] = useState<TreeNode[]>( [] );
  const [value, setValue] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(true);
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
      loadUnits()
          .then((result) => {
              const {data} = result;
              setDeps(data);

              const raw = map(data, translateToNode);
              setRawData(raw);

              setTreeData(buildTreeData(raw));
          });
  }, []);

  const onChange = (value, label, extra) => {
    console.log(value, label, extra);
    const keys = map(value, v => v.value);
    setValue(prev => {
      let result;
      const target = find(rawData, {key: extra.triggerValue});
      const toDo = map(getDescendants(target), String);
            toDo.push(String(extra.triggerValue));
      if(extra.checked) {
        result = union(prev, toDo);
      }
      else {
        filter(prev, key => !includes(toDo, key))
        result = keys;
      }
      return result;
    });
  };

  const innerLoadData = async ( parent: number|string, deep?: boolean ) => {
    if(!deep) {
      return filter(deps, dep => parent ? parent == dep.parent_key : !dep.parent_key);
    }
    else {
      console.error('Not implemented');
    }
  };

  const loadNode:TreeSelectProps['loadData'] = async ( dataNode ) => {
    const parentKey = (dataNode as TreeNode).key;
    return loadNodeByParentKey(parentKey);
  };

  const loadNodeByParentKey = async ( parent: string ) => {
    try {
      const nodeChildren = await (loadData ?? innerLoadData)(parent);
      if(nodeChildren?.length) {
        setRawData(prev => {
          const parentNode = find(prev, {key: parent});
          parentNode.children = map(nodeChildren, translateToNode);
          return [...prev, ...parentNode.children];
        });
      }
    }
    catch {
      console.log('error loadnode');
      setRawData( [] );
    }
  };

  // const onTreeExpand: DepartmentTreeProps['onTreeExpand'] = (data)  =>  {
  //   let newTree: typeof treeData;
  //   let node: TreeNode;
  //   data.forEach(async (item:number, idx) => {
  //     try {
  //       await loadNodeByParentKey(item);
  //     }
  //     catch {
  //       console.log('onTreeExpand ' + item);
  //     }
  //   });
  // };

  const onSelect: DepartmentTreeProps['onSelect'] = (keys, info) => {
    console.log('Trigger Select', keys, info);
  };

  const onPopupScroll: DepartmentTreeProps['onPopupScroll'] = (e) => {
    console.log('onPopupScroll', e);
  };

  const onContextMenuHandler = (node: TreeNode, contextAction: Key) => {
    switch(contextAction) {
      case 'select_all':
        setValue(map(rawData, dep => dep.key));
        break;
      case 'deselect_all':
        setValue([]);
        break;

      case 'select_children': 
        setValue(prev => union(prev, getDescendants(node, false)));
        break;
      case 'deselect_children':
        const descendants = getDescendants(node, false);
        setValue(prev => filter(prev, key => !includes(descendants, key)));
        break;

      case 'select_siblings':
        if(node.parent_key) {
          const parentKey = node.parent_key;
          const parentNode = find(rawData, {key: parentKey});
          if(parentNode) {
            const siblings = getDescendants(parentNode, false);
            setValue(prev => union(prev, siblings) );
          }
        }
        break;
      case 'deselect_siblings':
        if(node.parent_key) {
          const parentKey = node.parent_key;
          const parentNode = find(rawData, {key: parentKey});
          if(parentNode) {
            const siblings = getDescendants(parentNode, false);
            setValue(prev => filter(prev, key => !includes(siblings, key)));
          }
        }
        break;
      // case 'expand_all':
      //   break;
      // case 'collapse_all':
      //   break;
    }

    
  };

  return (<>
      <StyledTreeSelect<TreeNode>
        open={open}

        value={value as any}
//        onChange={onChange}
        loadData={loadNode}
        treeData={treeData}
        fieldNames={{ label: 'name', value: 'key', children: 'children' }}
        labelInValue
        treeDataSimpleMode={{ id:'key', pId:'parent_key' }}
        treeExpandAction="click"
        treeCheckStrictly

//        treeDefaultExpandAll
       // onTreeExpand={onTreeExpand}

        popupClassName='deptree-dropdown'
        popupMatchSelectWidth={500}
        getPopupContainer={(node:HTMLElement) => refWrapper.current as HTMLElement}
        listHeight={400}
        treeIcon={true}
        treeLine={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
//        showSearch
//        allowClear
        treeCheckable
        multiple
//        placeholder={"Найти"}

        /* onPopupScroll={onPopupScroll} */
        onChange={onChange}

        maxTagCount={0}
        maxTagPlaceholder={(props) => props[0]?.label}
        onDropdownVisibleChange={(open)=>{setOpen(true)}}
        treeTitleRender={(node: TreeNode) => 
          <ContextMenu onSelect={onContextMenuHandler} node={node}>
            <span>[{node.key}] {node.name}</span>
          </ContextMenu>
        }
        tagRender={(props) => {
          //console.log('tagRender', props);
          return <>{value.length>1 ? `Выбрано: ${value.length}` : props?.label}</>;
        }}
      />
      {<StyledWrapper ref={refWrapper} className="popup-container"></StyledWrapper>}
  </>);
};

// TreeSelect.Option = StyledTreeSelect.Option;
// TreeSelect.OptGroup = StyledTreeSelect.OptGroup;

export { DepartmentTree };
