import * as React from "react";
import { SVGProps } from "react";
const SvgExlamation = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="exlamation" className="anticon anticon-exlamation">
    <svg width="1em" height="1em" viewBox="0 0 15 12" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M6.22998 1.45695C6.61704 0.801924 7.56477 0.801925 7.95184 1.45695L13.2903 10.4913C13.6842 11.1579 13.2037 12 12.4294 12H1.75245C0.978148 12 0.497613 11.1579 0.891522 10.4913L6.22998 1.45695Z" fill="#FF626E"/>
<rect x="6.54555" y="4.36359" width="1.09091" height="4.36364" fill="white"/>
<rect x="6.54555" y="9.81818" width="1.09091" height="1.09091" fill="white"/>
</svg>
    </span>
);
export default SvgExlamation;