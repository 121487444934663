import styled from "styled-components";
import { Colors } from '../../Theme/colors';
// import "../../vars.css";

export const DrawerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 24px 24px 24px 32px;

  & h1 {
    margin-bottom: 0;
  }

  .title-wrapper {
    width: calc(100% - 20px);
    color: ${Colors.mainBlack}
  }
`;

export const DrawerHeaderButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: 60px;
`;

export const CloseBtn = styled.div`
  font-size: 25px;
  position: absolute;
  right: 0;
  top: -8px;
  cursor: pointer;
  color: var(--main-steelblue);
`;
