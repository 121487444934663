import * as React from "react";
import { SVGProps } from "react";
const SvgDoSearch = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="dosearch" className="anticon anticon-dosearch">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 8H18V6H6V18H8V20H4L4 4H20V8ZM14 19C11.2386 19 9 16.7614 9 14C9 11.2386 11.2386 9 14 9C16.7614 9 19 11.2386 19 14C19 15.0191 18.6951 15.967 18.1716 16.7574L20.8284 19.4142L19.4142 20.8284L16.7574 18.1716C15.967 18.6951 15.0191 19 14 19ZM14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17Z" />
    </svg>
    </span>
);
export default SvgDoSearch;
