import { useState } from "react";
import type { RadioChangeEvent } from "antd";

export const useRadio = <ValueType>(initValue: ValueType) => {
  const [value, setValue] = useState(initValue);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
  };
};
