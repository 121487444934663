import React, {useCallback, useContext, useEffect} from "react";
import {each, every, find, findIndex} from 'lodash';
import {ConfigProvider, TableColumnsType} from 'antd';
import {Table} from 'antd';
import {DndContext} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import type {DragEndEvent} from '@dnd-kit/core';
import {Checkbox, Button, theme} from "@gp-frontend-ui/ui-kit-5v2";
import {Row, RowContext} from './columnsRow';
// import { ColumnSettings } from '@entities/Settings/types';
//import { ModuleCtx } from '@features/Module/context';
import {HolderOutlined} from "@ant-design/icons";
import {ColumnSettings} from "./settings_types";

// rowSelection object indicates the need for row selection


const DragHandle: React.FC = () => {
    const {setActivatorNodeRef, listeners} = useContext(RowContext);

    return (
        <Button
            view="primary-text"
            size="small"
            icon={<HolderOutlined/>}
            style={{cursor: 'move', backgroundColor: 'transparent'}}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const columns: TableColumnsType<ColumnSettings> = [
    {title: 'Выделить все / снять все', dataIndex: 'name'},
    {key: 'sort', align: 'center', width: 24, render: (text, record, index) => index > 0 ? <DragHandle/> : null},
];

enum CheckedAllState {ALL, NONE, INDETERMINATE};

type ColumnsProps = {
    properties: ColumnSettings[],
    onChange?: (data: ColumnSettings[]) => void,
};

export const ColumnsList: React.FC<ColumnsProps> = ({properties: initData, onChange}) => {
    //const {type: module} = useContext(ModuleCtx);
    let module = "datainput";

    const [dataSource, setDataSource] = React.useState<ColumnSettings[]>([]);

    useEffect(() => {
        setDataSource(initData);
    }, [initData]);

    const changeData = useCallback((data: any) => {
        setDataSource(data);
        if (onChange)
            setTimeout(() => onChange(data));
    }, []);

    const isCheckedAll = React.useMemo(() => {
        return every(dataSource, 'visibility');
    }, [dataSource]);

    const checkedAllState = React.useMemo(() => {
        if (isCheckedAll)
            return CheckedAllState.ALL;
        else {
            if (find(dataSource, {'visibility': true, 'lock': false}))
                return CheckedAllState.INDETERMINATE;
            return every(dataSource,);
            return CheckedAllState.NONE;
        }
    }, [dataSource]);

    const onCheckAllChange = useCallback((event: any) => {
        const checked = event.target.checked;
        let newDS: typeof dataSource;
        if (checked) {
            newDS = each(dataSource, (colset) => {
                colset.visibility = true;
            });
        }
        else {
            newDS = each(dataSource, (colset) => {
                if (!colset.lock) colset.visibility = false;
            });
        }
        changeData([...newDS]);
    }, [dataSource, changeData]);

    // @ts-ignore
    const onVisibilityChange = useCallback((record: ColumnSettings, event) => {
        const checked = event.target.checked;
        let newDS: typeof dataSource = dataSource;
        const idx = findIndex(newDS, {'key': record.key});
        newDS[idx].visibility = checked;
        changeData([...newDS]);
    }, [dataSource]);

    // @ts-ignore
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ColumnSettings[]) => {

        },
        /* getCheckboxProps: (record: ColumnSettings) => ({
          disabled: record.lock!=="ENABLED", // Column configuration not to be checked
          name: record.name,
        }), */
        renderCell: (checked: any, record: ColumnSettings, index: any, originNode: any) =>
            record && <Checkbox
            disabled={record.lock === "ENABLED"}
            checked={record.visibility}
            onChange={(ev) => onVisibilityChange(record, ev)}
          />,
        columnTitle: <Checkbox
            onChange={onCheckAllChange}
            checked={checkedAllState === CheckedAllState.ALL}
            indeterminate={checkedAllState === CheckedAllState.INDETERMINATE}
        />,
    };

    const onDragEnd = ({active, over}: DragEndEvent) => {
        if (over?.id === 'num') return; // guard

        if (active.id !== over?.id) {
            const activeIndex = dataSource.findIndex((record) => record.key === active?.id);
            const overIndex = dataSource.findIndex((record) => record.key === over?.id);
            const newDS = arrayMove(dataSource, activeIndex, overIndex);
            changeData([...newDS]);
        }
    };

    // @ts-ignore
    return (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={dataSource.map((i) => i?.key)} strategy={verticalListSortingStrategy}>
                <ConfigProvider theme={theme}>
                    <Table
                        rowKey="key"
                        components={{body: {row: Row}}}
                        columns={columns}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        pagination={false}
                        size="small"
                        showHeader={module !== 'journal'}
                    />
                </ConfigProvider>
            </SortableContext>
        </DndContext>
    );
}; /* /ColumnsList */
