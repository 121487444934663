import * as React from "react";
import { SVGProps } from "react";
const SvgHistoryIcon = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="historyicon" className="anticon anticon-historyicon">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.75 2v1H20v18H3V3h4.25V2h8.5Zm0 4h-8.5V5H5.125v14h12.75V5H15.75v1Zm0 7v2h-8.5v-2h8.5Zm0-4v2h-8.5V9h8.5Z" />
  </svg>
    </span>
);
export default SvgHistoryIcon;
