import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const PaginationComponentToken = {
	/// Background color of active Pagination item	string #ffffff
	itemActiveBg: Colors.mainOrange,
	/// Background color of disabled active Pagination item	string rgba(0, 0, 0, 0.15)
	itemActiveBgDisabled: 'rgba(0, 0, 0, 0.15)',
	/// Text color of disabled active Pagination item	string rgba(0, 0, 0, 0.25)
	itemActiveColorDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Background color of Pagination item	string #ffffff
	itemBg: Colors.mainLighterBlue,
	/// Background color of input	string #ffffff
	itemInputBg: Colors.mainWhite,
	/// Background color of Pagination item link	string #ffffff
	itemLinkBg: Colors.mainWhite,
	/// Size of Pagination item	number 32
	itemSize: 32,
	/// Size of small Pagination item	number 24
	itemSizeSM: 24,
	/// Top of Pagination size changer	number 0
	miniOptionsSizeChangerTop: 0,

	/// Container background color, e.g: default button, input box, etc. Be sure not to confuse this with `colorBgElevated`.	string #ffffff
	colorBgContainer: Colors.mainLighterBlue,
	/// Control the background color of container in disabled state.	string rgba(0, 0, 0, 0.04)
	colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
	/// Control the background color of text in active state.	string rgba(0, 0, 0, 0.15)
	colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
	/// Control the background color of text in hover state.	string rgba(0, 0, 0, 0.06)
	colorBgTextHover: Colors.mainLighterBlue,
	/// Default border color, used to separate different elements, such as: form separator, card separator, etc.	string #d9d9d9
	colorBorder: 'transparent',
	/// Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.	string #1677ff
	colorPrimary: Colors.mainWhite,
	/// The stroke color under the main color gradient, used on the stroke of components such as Slider.	string #91caff
	colorPrimaryBorder: 'transparent',
	/// Hover state under the main color gradient.	string #4096ff
	colorPrimaryHover: Colors.mainWhite,
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: Colors.mainBlue,
	/// Control the color of text in disabled state.	string rgba(0, 0, 0, 0.25)
	colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Control the color of placeholder text.	string rgba(0, 0, 0, 0.25)
	colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
	/// Border radius of base components	number 6
	borderRadius: 0,
	/// LG size border radius, used in some large border radius components, such as Card, Modal and other components.	number 8
	borderRadiusLG: 0,
	/// SM size border radius, used in small size components, such as Button, Input, Select and other input components in small size	number 4
	borderRadiusSM: 0,
	/// The height of the basic controls such as buttons and input boxes in Ant Design	number 32
	controlHeight: 32,
	/// LG component height	number 40
	controlHeightLG: 40,
	/// SM component height	number 24
	controlHeightSM: 24,
	/// Control the outline color of input component.	string rgba(5, 145, 255, 0.1)
	controlOutline: 'rgba(5, 145, 255, 0.1)',
	/// Control the outline width of input component.	number 2
	controlOutlineWidth: 2,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	//fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 14,
	/// Small font size	number 12
	fontSizeSM: 12,
	/// Control the font weight of heading components (such as h1, h2, h3) or selected item.	number 600
	fontWeightStrong: 600,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Line height of large text.	number 1.5
	lineHeightLG: 1.5,
	/// Border style of base components	string solid
	lineType: 'solid',
	/// Border width of base components	number 1
	lineWidth: 1,
	/// Control the width of the line when the component is in focus state.	number 4
	lineWidthFocus: 4,
	/// Control the margin of an element, with a medium size.	number 16
	margin: 16,
	/// Control the margin of an element, with a medium-small size.	number 12
	marginSM: 12,
	/// Control the margin of an element, with a small size.	number 8
	marginXS: 0,
	/// Control the margin of an element, with the smallest size.	number 4
	marginXXS: 4,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Motion speed, slow speed. Used for large element animation interaction.	string 0.3s
	motionDurationSlow: '0.3s',
	/// Control the extra extra small padding of the element.	number 4
	paddingXXS: 4,
	/// Control the screen width of large screens.	number 992
	screenLG: 1600,
	/// Control the screen width of small screens.	number 576
	screenSM: 1000,
}
