import {Button as AntdButton} from 'antd';
import styled from "styled-components";

import {BtnProps} from "./components.types";
import {Colors} from "@gp-frontend-ui/ui-kit-5v2";

export const StyledBtn = styled(AntdButton)<BtnProps>`
    &.ant-btn-color-primary.ant-btn-variant-text {
        &:hover:not(.accent) {
            background-color: ${Colors.cloudy.superlight} !important;
        }
    }
    
    &.ant-btn-background-ghost {
        background-color: transparent !important;
    }
    
    &.ant-btn-variant-link {
        text-decoration: underline;
        text-underline-offset: 2px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }
    
    &.pagetitle {
        min-width: 38px;
        min-height: 38px;
        border: 0;
        color: ${Colors.cloudy.dark} !important;

        .anticon {
            font-size: 24px;
        }

        &.accent {
            max-width: 48px;
            max-height: 48px;
            width: 48px;
            height: 48px;
            border-radius: 0;
            margin-right: -20px;
            margin-left: 8px;
            background-color: ${Colors.orange.main};
            border: ${Colors.orange.main} solid 1px !important;
            color: ${Colors.white} !important;

            &:hover:not([disabled]) {
                background-color: ${Colors.orange.light} !important;
                border: ${Colors.orange.light} solid 1px !important;
                color: ${Colors.white} !important;
            }

            &:active:not([disabled]) {
                background-color: ${Colors.orange.dark} !important;
                border: ${Colors.orange.dark} solid 1px !important;
                color: ${Colors.white} !important;
            }
        }

        &:hover {
            color: ${Colors.blue.main} !important;
            background-color: transparent !important;
        }

        &:disabled {
            border: none !important;
            color: ${Colors.gray.main} !important;
            background-color: transparent !important;
        }
    }

    &.sidebar {
        min-width: 48px;
        min-height: 48px;
        border-radius: 0;
        color: ${Colors.white} !important;
        background-color: transparent !important;
        border: none;

        &:hover {
            background-color: ${Colors.blue.light} !important;
            color: ${Colors.white} !important;
        }

        .anticon {
            font-size: 24px;
        }

        &.open {
            background-color: ${Colors.white};
            color: ${Colors.blue.dark} !important;
            border: none;
        }


        &:disabled {
            background-color: transparent !important;
            border: none !important;
            color: ${Colors.blue.light} !important;
        }
    }

    &.table {
        border: none !important;
        width: 24px;
        height: 24px;

        &:hover:not([disabled]) {
            border: 0 !important;
            background-color: transparent !important;
        }

        &:disabled, &[disabled] {
            border: none;
            background-color: transparent !important;
            color: ${Colors.gray.main} !important;
        }

        &.off {
            color: #aaa;
        }
    }

    &.active {
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            background-color: red;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 5;
        }
    }

    .active {
        display: ${(props) => props.active ? 'block' : 'none'}
    }

    svg {
        position: relative;

        &::after {
            content: '*';
            display: block;
            width: 10px;
            height: 10px;
            color: red;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 5;
        }
    }
`
