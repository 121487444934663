import React from "react";
import {Tag as TagAnt} from 'antd';
import {TagProps} from "./components.types";



export const Tag: React.FC<TagProps> = ({
                                           children,
                                            ...arg
                                        }) => {
    return (
         <TagAnt {...arg}>{children}</TagAnt>
    );
};
