import React, { useEffect, useState } from 'react';
import { Button, Col, ConfigProvider, Form, Flex, Row, Space, DatePicker, Tabs, Upload, Collapse,  } from 'antd';
import { ArrowLeftOutlined, BuildOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {createStyles, useTheme} from 'antd-style';
import {ThemeConfig} from "antd/lib";
import dayjs from 'dayjs';
import {Input, Typography, Select, Modal} from "../ui-kit";

import {Colors} from '../colors';

const FormEdit = () => {
    const [theme, setTheme] = useState<ThemeConfig>({});

    const getThemeData = async () => {
        const response = await fetch(
            "theme.json"
        ).then((response) => response.json());

        // Обновим состояние
        setTheme(response);
    };

    useEffect(() => {
        getThemeData();
    }, []);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState<false | 'footer' | 'centered' | 'styled'>(false);

    const modalHandleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const modalHandleCancel = () => {
        setOpen(false);
    };

    return (<ConfigProvider theme={theme}>
        <div className={"page-wrap"}>
            <Typography.TitleH2><span style={{color:Colors.cloudy.dark}}><ArrowLeftOutlined /></span> Test for ui-kit</Typography.TitleH2>
            <Row>
                <Col flex="auto">
                    <Form initialValues={{version:1}} layout='vertical'>
                        <Space>
                            <Form.Item label='Период действия' name='version'>
                                <Select>
                                    <Select.Option value={1}>04.09.2023 — 15.03.2024</Select.Option>
                                </Select>
                            </Form.Item>
                            <span style={{color:Colors.cloudy.dark}}><Button color="primary" variant="text" size='small' icon={<DeleteOutlined />} onClick={() => setOpen('centered')} /></span>
                            <span style={{color:Colors.cloudy.dark}}><Button color="primary" variant="text" icon={<BuildOutlined />} /></span>
                        </Space>
                    </Form>
                </Col>
                <Col flex='1'>
                    <Space.Compact direction='vertical' style={{color:Colors.cloudy.dark}}>
                        <div>Статус версии: <span style={{color:Colors.tag.success}}>Актуальная</span></div>
                        <div>Дата, время последнего изменения формы: <span style={{color:Colors.black}}>02.02.2024 13:43:31</span></div>
                        <div>ФИО редактора: <span style={{color:Colors.black}}>Петров А.В.</span></div>
                        <div>Статус формы: <span style={{color:Colors.black}}>Готова к публикации</span></div>
                    </Space.Compact>
                </Col>
            </Row>

            <Tabs items={[
                {
                    key: '1',
                    label: 'Base',
                    children: <BasePane />,
                },
                {
                    key: '2',
                    label: 'Шаблон',
                    children: <BlankPane />,
                }]}
                defaultActiveKey="1"
            />
        </div>
        <Modal
            open={!!open}
            title="Удаление формы отчетности"
            onOk={modalHandleOk}
            onCancel={modalHandleCancel}
            footer={[
                <Button key="back" type="primary" onClick={modalHandleCancel}>
                    Удалить
                </Button>,
                <Button key="submit" type="default" loading={loading} onClick={modalHandleOk}>
                    Отменить
                </Button>,
            ]}
        >
            <p><b>Вы уверены, что хотите удалить форму отчетности целиком?</b></p>
            <p>У этой формы существует только один период действия.</p>
        </Modal>
    </ConfigProvider>);
};

//dayjs.extend(customParseFormat)

const initBase = {
    id: 352154646461321,
    name: '511',
    short: 'ОН',
    full: 'Надзор за исполнением законов, соблюдением прав и свобод человека и гражданина является одной из важнейших функций прокуратуры Российской Федерации. Это конституционно закрепленная роль прокуратуры, направленная на защиту законных интересов граждан, обеспечение верховенства закона',
    start: '04.09.2023',
    stop: '15.03.2024',
    prikaz_start: undefined,
    prikaz_stop: undefined,
    period: 1,
    transport: 1,
};

const dateFormat = 'DD.MM.YYYY';

const BasePane = () => {
    return (<div>
        <Form
            initialValues={initBase}
            layout='inline'
        >
            <Space direction='vertical' size={[20,20]}>
                <Form.Item label='Идентификатор формы' name='id'>
                    <Input disabled />
                </Form.Item>

                <Form.Item required label='Наименование формы*'>
                    <Row gutter={[10,20]}>
                        <Col span={6}>
                            <Form.Item name='name' help='Числовой код формы'>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item name='short' help='Сокращенное наименование формы'>
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item name='full' help='Полное наименование'>
                                <Input.Textarea rows={5}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item label='Даты начала и окончания эксплуатации версии'>
                    <Row gutter={[20,20]}>
                        <Col span={12}>
                            <Form.Item required name='start'
                                getValueProps={(value) => ({ value: value && dayjs(value, "DD.MM.YYYY") })}
                                normalize={(value) => value && `${dayjs(value).valueOf()}`}
                            >
                                <DatePicker format={dateFormat} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='stop'
                                getValueProps={(value) => ({ value: value && dayjs(value, "DD.MM.YYYY") })}
                                normalize={(value) => value && `${dayjs(value).valueOf()}`}
                            >
                                <DatePicker format={dateFormat} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label='Сканы приказов'>
                    <Row>
                        <Col span={12}>
                            <Form.Item name='prikaz_start'>
                                <Upload name="logo" action="#" listType="picture">
                                    <Button type='primary' icon={<PlusOutlined />}>Добавить файл</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='prikaz_stop'>
                                <Button type='primary' icon={<PlusOutlined />}>Добавить файл</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item required label='Периодичность представления информации' name='period'>
                    <Select>
                        <Select.Option value={1}>Ежемесячно</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Способ представления информации*' name='transport'>
                    <Select>
                        <Select.Option value={1}>Электронный</Select.Option>
                    </Select>
                </Form.Item>
            </Space>
        </Form>
    </div>);
};


const BlankPane = () => {
    const form =
    <Form
        initialValues={initBase}
        layout='vertical'
    >
        <Row>
            <Col>
                <Form.Item label='Кол-во разделов' name='dim'>
                    <Input />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item label='#'>
                    <Input disabled />
                </Form.Item>
            </Col>
            <Col>
                <Form.List
                    name="names"
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            label={index === 0 ? 'Строки' : ''}
                            required={false}
                            key={field.key}
                        >
                            <Form.Item
                                {...field}
                                noStyle
                            >
                                <Input placeholder="passenger name" style={{ width: '60%' }} />
                            </Form.Item>
                        </Form.Item>
                        ))}
                    </>
                    )}
                </Form.List>
            </Col>
            <Col>
            </Col>
        </Row>
    </Form>;

    return (<div>
        <Collapse
            collapsible="header"
            defaultActiveKey={['1']}
            items={[
                {
                    key: '1',
                    label: 'Загрузить шаблон',
                    children: form,
                },
            ]}
        />
    </div>);
};


export default FormEdit;
// <Button variant={"outlined"}>Button from ui-kit</Button>
