import * as React from "react";
import { SVGProps } from "react";
const SvgCross = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="cross" className="anticon anticon-cross">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5.20096L18.5858 3.78674L11.929 10.4436L5.34319 3.85781L3.92898 5.27202L10.5148 11.8578L3.85791 18.5147L5.27212 19.9289L11.929 13.272L18.6569 19.9999L20.0711 18.5857L13.3432 11.8578L20 5.20096Z" />
    <mask id="mask0_0_1952" style={{"maskType":'alpha'}} maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="17">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5.20096L18.5858 3.78674L11.929 10.4436L5.34319 3.85781L3.92898 5.27202L10.5148 11.8578L3.85791 18.5147L5.27212 19.9289L11.929 13.272L18.6569 19.9999L20.0711 18.5857L13.3432 11.8578L20 5.20096Z" />
    </mask>
    <g mask="url(#mask0_0_1952)"></g>
    </svg>
    </span>
);
export default SvgCross;