import * as React from "react";
import { SVGProps } from "react";
const SvgTableMenu = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="tablemenu" className="anticon anticon-tablemenu">
    <svg width="1em" height="1em" viewBox="0 0 12 8" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M3.58 2.063V.77h7.75v1.292H3.58Zm0 2.583V3.354h7.75v1.292H3.58Zm0 2.583V5.938h7.75v1.291H3.58Zm-2.583 0V5.938H2.29v1.291H.997ZM2.29 2.063H.997V.77H2.29v1.292ZM.997 4.646V3.354H2.29v1.292H.997Z" />
    </svg>
    </span>
);
export default SvgTableMenu;
