import * as React from "react";
import { SVGProps } from "react";
const SvgShow = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="show" className="anticon anticon-show">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6646 15.2121C13.8737 15.2121 15.6646 13.4212 15.6646 11.2121C15.6646 10.4584 15.4561 9.75351 15.0938 9.15173C18.0524 10.6 20.6646 13.2121 20.6646 13.2121C20.6646 13.2121 15.6646 18.2121 11.6646 18.2121C7.66455 18.2121 2.66455 13.2121 2.66455 13.2121C2.66455 13.2121 5.27667 10.6 8.23529 9.15173C7.87296 9.75351 7.66455 10.4584 7.66455 11.2121C7.66455 13.4212 9.45541 15.2121 11.6646 15.2121Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.32873 10.7474C8.94984 5.75086 14.3789 5.75086 20 10.7474L21.3287 9.25259C14.9498 3.58247 8.37889 3.58247 2 9.25259L3.32873 10.7474Z" />
    </svg>
    </span>
);
export default SvgShow;
