import * as analyticsIcons from './svgreact/analyticIcons';
import * as commonIcons from './svgreact/commonIcons';
import * as formatIcons from './svgreact/formatIcons';
import * as menuIcons from './svgreact/sidebarIcons';
import * as statusIcons from './svgreact/statusIcons';
import * as tableIcons from './svgreact/tableIcons';

export * as AnalyticIcons from './svgreact/analyticIcons';
export * from './svgreact/commonIcons';
export * from './svgreact/formatIcons';
export * from './svgreact/statusIcons';
export * as TableIcons from './svgreact/tableIcons';

export * from './helpers';

export const Icons = {
	'Analytics': {
		'Add': analyticsIcons.Add,
		'Attach': analyticsIcons.Attach,
		'Copy': analyticsIcons.Copy,
		'Cross': analyticsIcons.Cross,
		'Delete': analyticsIcons.Delete,
		'Dots': analyticsIcons.Dots,
		'Drop': analyticsIcons.Drop,
		'Edit': analyticsIcons.Edit,
		'Empty': analyticsIcons.Empty,
		'Error': analyticsIcons.Error,
		'Export': analyticsIcons.Export,
		'Folder': analyticsIcons.Folder,
		'Folders': analyticsIcons.Folders,
		'Mark': analyticsIcons.Mark,
		'Passport': analyticsIcons.Passport,
		'Plus': analyticsIcons.Plus,
		'Restore': analyticsIcons.Restore,
		'Run': analyticsIcons.Run,
		'Save': analyticsIcons.Save,
		'Sum': analyticsIcons.Sum,
		'Upload': analyticsIcons.Upload,
		'View': analyticsIcons.View,
		'ZoomIn': analyticsIcons.ZoomIn,
		'ZoomOut': analyticsIcons.ZoomOut,
		'ZoomReset': analyticsIcons.ZoomReset,

		'CardView': tableIcons.View,
		'CardEdit': tableIcons.Edit,
		'CardLinked': tableIcons.Chain,
		'CardAttached': tableIcons.Attach,
		'CardAttachedA': tableIcons.Attach,

		'CheckCircleOutlined': analyticsIcons.CheckCircle,
		'QuestionCircleOutlined': analyticsIcons.QuestionCircle,
		'ExclamationCircleOutlined': analyticsIcons.ExclamationCircle,
	},

	'Doc': formatIcons.Doc,
	'Pdf': formatIcons.Pdf,
	'Scd': formatIcons.Scd,
	'Xls': formatIcons.Xls,

	'Sidebar' : {
		'Analytics': menuIcons.Analytics,
		'Clock': menuIcons.Clock,
		'Draft': menuIcons.Draft,
		'Filter': menuIcons.Filter,
		'FilterA': menuIcons.FilterA,
		'List': menuIcons.ListIcon,
		'ListA': menuIcons.ListA,
		'Report': menuIcons.Report,
		'Search': menuIcons.Search,
		'Setting': menuIcons.Setting,
		'SettingA': menuIcons.SettingA,
		'StatAdmin': menuIcons.StatAdmin,
		'StatClassifiers': menuIcons.StatClassifiers,
		'StatForms': menuIcons.StatForms,
		'StatReports': menuIcons.StatReports,
		'StatRequests': menuIcons.StatRequests,
	},

	'StatusNormal': statusIcons.StatusNormal,
	'StatusViolation': statusIcons.StatusViolation,

	'Add': analyticsIcons.Add,
	'Plus': analyticsIcons.Add,
	'AddFile': commonIcons.AddFile,
	'Arrow': commonIcons.Arrow,
	'Base': commonIcons.Base,
	'BaseActive': commonIcons.BaseActive,
	'Calendar': commonIcons.Calendar,
	'Circle': commonIcons.CircleIcon,
	'CreateTemplate': commonIcons.CreateTemplate,
	'Delayed': commonIcons.Delayed,
	'Delete': commonIcons.DeleteIcon,
	'Dots': commonIcons.Dots,
	'Download': commonIcons.Download,
	'Dropdown': commonIcons.DropdownIcon,
	'Edit': commonIcons.Edit,
	'EditActive': commonIcons.EditActive,
	'Export': commonIcons.Export,
	'Graph': commonIcons.Graph,
	'History': commonIcons.HistoryIcon,
	'Humburger': commonIcons.HumburgerIcon,
	'Journal': commonIcons.JournalIcon,
	'Mark': commonIcons.Mark,
	'Menu': commonIcons.MenuIcon,
	'MessageInfo': commonIcons.MessageInfo,
	'NoSigned': commonIcons.NoSigned,
	'Refresh': commonIcons.Refresh,
	'Related': commonIcons.Related,
	'Rename': commonIcons.Rename,
	'Save': analyticsIcons.Save,
	'Search': commonIcons.Search,
	'Signed': commonIcons.Signed,
	'TableGraph': commonIcons.TableGraph,
	'TableMenu': commonIcons.TableMenu,
	'Transfer': commonIcons.Transfer,
	'TransitionTo': commonIcons.TransitionTo,
	'Trash': commonIcons.Trash,
	'Triangle': commonIcons.Triangle,
	'Upload': commonIcons.UploadIcon,
	'UploadActive': commonIcons.UploadActive,
	'ViewCard': commonIcons.ViewCard,

	'Chain': commonIcons.Chain,
	'Differences': commonIcons.Differences,
	'DoSearch': commonIcons.DoSearch,
	'Exlamation': commonIcons.Exlamation,
	'Expand': commonIcons.Expand,
	'EyeOpen': commonIcons.EyeOpen,
	'Map': commonIcons.Map,
	'Reload': commonIcons.Reload,
	'Requisites': commonIcons.Requisites,
	'Magnify': commonIcons.Magnify,
	'Sign': commonIcons.Sign,
	'Sort': commonIcons.Sort,
	'Templates': commonIcons.Templates,
	'Hidden': commonIcons.Hidden,
	'Visible': commonIcons.Visible,
	'Hide': commonIcons.Hide,
	'Show': commonIcons.Show,
	'OK': commonIcons.OK,
	'Cancel': commonIcons.Cancel,

	'PaperError': commonIcons.PaperError,
	'PaperOk': commonIcons.PaperOk,
	'PaperDisabled': commonIcons.Paper,
	'Down': commonIcons.Down,


	'Drop': analyticsIcons.Drop,
	'Clear': commonIcons.Clear,

	'Table' : {
		'Attach': tableIcons.Attach,
		'Chain': tableIcons.Chain,
		'Delete': tableIcons.Delete,
		'Edit': tableIcons.Edit,
		'Export': tableIcons.Export,
		'Globe': tableIcons.Globe,
		'View': tableIcons.View,
		'Web': tableIcons.Web,
	}
};
