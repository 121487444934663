import React, { useRef } from "react";
import { DatePicker as AntDatePicker } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import updateLocale from 'dayjs/plugin/updateLocale';

import { DatePickerProps } from '.';
import {StyledPicker} from './styled';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import { Reset } from '../Reset';
import { Resettable } from '../Reset/types';

dayjs.locale('en_EN');
dayjs.extend(updateLocale);
dayjs.updateLocale('en_EN', {
  weekStart: 1,
  monthsShort: [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ],
  DatePicker: {
    lang: {
      monthFormat: "MMMM",
      dateFormat: "DD.MM.YYYY"
    }
  }
});

locale.lang.monthFormat = "MMMM";

export const DatePicker: React.FC<DatePickerProps> = ({
  onReset, allowReset, ...args
}) => {
  const refWrapper = useRef<HTMLDivElement>(null);

  return (
    <Reset allowReset={allowReset} onReset={onReset}>
      <StyledPicker>
        <AntDatePicker
          locale={locale}
          popupClassName="styled-date-picker"
          getPopupContainer={(node:HTMLElement) => refWrapper.current as HTMLElement}
          format="DD.MM.YYYY"
          {...args}
        />
      </StyledPicker>
    </Reset>
  );
};
