// import "../vars.css";
import styled from "styled-components";
import { Form as AntForm } from "antd";
import { fontMixin } from "../Typography/mixins";
import { Text14_20Mixin, Text12_16Mixin } from "../Typography/Text/mixins";
import { Colors } from '../Theme/colors';
import { FormProps, FormItemProps } from './components.types';

import { FormToken } from '../Theme/token.form';
const token = FormToken;

const {Item:AntFormItem} = AntForm;

export const StyledForm = styled(AntForm)<FormProps>`
`;

export const StyledFormItem = styled(AntFormItem)<FormItemProps>`
	&.ant-form-item {
		padding: 8px 0;
	}

	.ant-form-item-label {
		text-align: start;
		white-space: wrap;

		>label {
			padding-right: 16px;
			font-weight: 500;
			min-height: ${token.labelHeight};
			height: unset;

			&::after {
				content: '';
			}
		}
	}

	.ant-form-item-control {
		margin-left: 16px;
		padding-left: 16px;
		border-left: 2px #CED4DA solid;		
		justify-content: center;
	}
`;

