import * as React from "react";
import { SVGProps } from "react";
const SvgExport = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="export" className="anticon anticon-export">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M15.5 3v5h3.745L12.5 15.495 5.755 8H9.5V3h6ZM21 22v-6H4v6h17ZM6 18h13v2H6v-2Zm5.5-13h2v5h1.255L12.5 12.505 10.245 10H11.5V5Z" />
    </svg>
    </span>
);
export default SvgExport;
