import * as React from "react";
import { SVGProps } from "react";
const SvgDots = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="dots" className="anticon anticon-dots"> 
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6C14 7.10457 13.1046 8 12 8ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14ZM10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18Z" />
    </g>
    </svg>
    </span>
);
export default SvgDots;