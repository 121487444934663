import styled from "styled-components";
// import "../../vars.css";

export const DrawerFooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--main-white);
  z-index: 2;
  left: 0;
  right: 0;
  padding: 10px 24px 30px 32px;
`;
