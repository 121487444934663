import * as Text from "./Text";
import * as Title from "./Title";

import * as mixins from "./mixins";

const { TitleMixins, ...TitleComponents } = Title;
const { TextMixins, ...TextComponents } = Text;

export const Typography = {
  ...TitleComponents,
  ...TextComponents,
};

export const TypographyMixins = {
  ...mixins,
  ...TitleMixins,
  ...TextMixins,
};
