import * as React from "react";
import { SVGProps } from "react";
const SvgDraft = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="draft" className="anticon anticon-draft">
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M20 3H9L4 8v13h5v-2H6v-9h5V5h7v5h2V3ZM9 8V6L7 8h2Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M11.002 21h2.54l7.46-7.496L18.5 11l-7.498 7.503V21Z"
      fill="#FF626E"
    />
  </svg>
</span>);
export default SvgDraft;
