import * as React from "react";
import { SVGProps } from "react";
const SvgStatAdmin = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="admin" className="anticon anticon-admin">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1h14v2H1zM1 7h14v2H1zM1 13h14v2H1z" />
    <circle cx={7} cy={2} r={2} />
    <circle cx={4} cy={8} r={2} />
    <circle cx={12} cy={8} r={2} />
    <circle cx={9} cy={14} r={2} />
  </svg>
</span>);
export default SvgStatAdmin;
