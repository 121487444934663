import { Colors } from './colors';

export const DatePickerToken = {
    // Background color when the input box is activated	string	#ffffff
    activeBg: Colors.mainLightestBlue,
    // Active border color	string	#1677ff
    activeBorderColor: Colors.mainLighterBlue,
    // Box-shadow when active	string	0 0 0 2px rgba(5, 145, 255, 0.1)
    activeShadow: 'none',
    // Background color of addon	string	rgba(0, 0, 0, 0.02)
    addonBg: 'rgba(0, 0, 0, 0.02)',
    // Background color of cell in range	string	#e6f4ff
    cellActiveWithRangeBg: '#e6f4ff',
    // Background color of disabled cell	string	rgba(0, 0, 0, 0.04)
    cellBgDisabled: 'rgba(0, 0, 0, 0.04)',
    // Height of cell	number	24
    cellHeight: 36,
    // Background color of cell hover state	string	rgba(0, 0, 0, 0.04)
    cellHoverBg: 'rgba(0, 0, 0, 0.04)',
    // Background color of hovered cell in range	string	#c8dfff
    cellHoverWithRangeBg: '#c8dfff',
    // Border color of cell in range when picking	string	#7cb3ff
    cellRangeBorderColor: '#7cb3ff',
    // Width of cell	number	36
    cellWidth: 36,
    // Box-shadow when active in error status	string	0 0 0 2px rgba(255, 38, 5, 0.06)
    errorActiveShadow: '0 0 0 2px rgba(255, 38, 5, 0.06)',
    // Background color when the input box hovers	string	#ffffff
    hoverBg: '#ffffff',
    // Hover border color	string	#4096ff
    hoverBorderColor: '#4096ff',
    // Font size	number	14
    inputFontSize: 14,
    // Font size of large	number	16
    inputFontSizeLG: 16,
    // Font size of small	number	14
    inputFontSizeSM: 14,
    // Background color of multiple tag	string	rgba(0, 0, 0, 0.06)
    multipleItemBg: 'rgba(0, 0, 0, 0.06)',
    // Border color of multiple tag	string	transparent
    multipleItemBorderColor: 'transparent',
    // Border color of multiple tag when disabled	string	transparent
    multipleItemBorderColorDisabled: 'transparent',
    // Text color of multiple tag when disabled	string	rgba(0, 0, 0, 0.25)
    multipleItemColorDisabled: 'rgba(0, 0, 0, 0.25)',
    // Height of multiple tag	number	24
    multipleItemHeight: 24,
    // Height of multiple tag with large size	number	32
    multipleItemHeightLG: 32,
    // Height of multiple tag with small size	number	16
    multipleItemHeightSM: 16,
    // Background color of multiple selector when disabled	string	rgba(0, 0, 0, 0.04)
    multipleSelectorBgDisabled: 'rgba(0, 0, 0, 0.04)',
    
    // Vertical padding of input	number	4
    paddingBlock: 4,
    // Vertical padding of large input	number	7
    paddingBlockLG: 7,
    // Vertical padding of small input	number	0
    paddingBlockSM: 2,

    // Horizontal padding of input	number	11
    paddingInline: 12,
    // Horizontal padding of large input	number	11
    paddingInlineLG: 11,
    // Horizontal padding of small input	number	7
    paddingInlineSM: 7,
    
    // Max width of preset area	number	200
    presetsMaxWidth: 200,
    // Width of preset area	number	120
    presetsWidth: 120,
    // Height of cell text	number	40
    textHeight: 40,
    // Height of time cell	number	28
    timeCellHeight: 28,
    // Height of time column	number	224
    timeColumnHeight: 224,
    // Width of time column	number	56
    timeColumnWidth: 56,
    // Box-shadow when active in warning status	string	0 0 0 2px rgba(255, 215, 5, 0.1)
    warningActiveShadow: '0 0 0 2px rgba(255, 215, 5, 0.1)',
    // Height of decade/year/quarter/month/week cell	number	66
    withoutTimeCellHeight: 66,
    // z-index of popup	number	1050
    zIndexPopup: 1050,


    // Border radius of base components	number	6
    borderRadius: 0,
    borderRadiusLG: 0,
    borderRadiusSM: 0,
    borderRadiusXS: 0,

    // Control the padding of the element.	number	16
    padding: 10,
    paddingSM: 0,
    paddingXS: 0,
    paddingXXS: 0,

    controlHeight: 48,
    color: Colors.mainOrange,
    colorIcon: Colors.mainBlue,
    margin: 1,
    // colorBgContainer: Colors.mainRed,
}