import * as React from "react";
import { SVGProps } from "react";
const SvgPaperError = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="papererror" className="anticon anticon-papererror">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_2451)">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 10V19H11V21H3V8L8 3H19V9H17V5H10V10H5ZM8 6L6 8H8V6Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6391 11.457C17.0261 10.8019 17.9739 10.8019 18.3609 11.457L23.1085 19.4913C23.5024 20.1579 23.0219 21 22.2476 21H12.7524C11.9781 21 11.4976 20.1579 11.8915 19.4913L16.6391 11.457Z" fill="#FF626E"/>
    <rect x="17" y="14" width="1" height="4" fill="white"/>
    <rect x="17" y="19" width="1" height="1" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_2451">
<rect width="21" height="18" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>
    </span>
);
export default SvgPaperError;
