import * as React from "react";
import { SVGProps } from "react";
const SvgRename = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="rename" className="anticon anticon-rename">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.70902 20L6.15802 16.598H13.508L14.978 20H17.834L11.198 5.29999H8.51002L1.89502 20H4.70902ZM12.605 14.456H7.08202L9.83302 8.02999L12.605 14.456Z" />
    <rect x="20" y="3" width="2" height="18" />
    </svg>
    </span>
);
export default SvgRename;