import * as React from "react";
import { SVGProps } from "react";
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="edit" className="anticon anticon-edit">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M16.038 2 22 7.962 7.962 22l-5.366-.596L2 16.038 16.038 2Zm-3.374 6.6L4.3 16.964l.264 2.372 2.372.264 8.364-8.364L12.664 8.6ZM14.3 6.96l2.64 2.64 1.76-1.76-2.64-2.64-1.76 1.76Z" />
    </svg>
    </span>
);
export default SvgEdit;
