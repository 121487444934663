import { Sidebar as SidebarComponent } from "./components";
import { Context } from "./Context";
import { Item } from "./Item";

export * from "./useSidebarContext";

type SidebarType = typeof SidebarComponent & {
  Context: typeof Context;
  Item: typeof Item;
};

const Sidebar: SidebarType = SidebarComponent as any;

Sidebar.Context = Context;
Sidebar.Item = Item;

export { Sidebar };
