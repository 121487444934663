import * as React from "react";
import { SVGProps } from "react";
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="upload" className="anticon anticon-upload">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M21 16v6H4v-6h17Zm-2 2H6v2h13v-2ZM12.5 2.505 19.245 10H15.5v5h-6v-5H5.755L12.5 2.505Zm0 2.99L10.245 8H11.5v5h2V8h1.255L12.5 5.495Z" />
    </svg>
    </span>
);
export default SvgUpload;
