import * as React from "react";
import { SVGProps } from "react";
const SvgRestore = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="restore" className="anticon anticon-restore">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.7441 9.9999H21L17.8 14.9999L14.6 9.9999H16.6521C15.8196 7.68077 13.5881 6 11 6C7.71229 6 5 8.71229 5 12C5 15.2877 7.71229 18 11 18C12.749 18 14.0002 17.1959 14.0002 17.1959L14.9998 18.9281C14.9998 18.9281 13.0347 20 11 20C6.60773 20 3 16.3923 3 12C3 7.60772 6.60772 4 11 4C14.7026 4 17.8477 6.56368 18.7441 9.9999Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.75222 13.3L9.25622 14.5H7.92822L10.4242 8.90002H11.7042L14.2082 14.5H12.8482L12.3522 13.3H9.75222ZM10.1682 12.316H11.9442L11.0562 10.172L10.1682 12.316Z" />
    </svg>
    </span>
);
export default SvgRestore;