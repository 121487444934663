import styled from "styled-components";
import { Collapse as AntCollapse } from 'antd';
import {Colors} from "@gp-frontend-ui/ui-kit-5v2";


export const StyledCollapse = styled(AntCollapse)`
        border-color: ${(props) => {return props.collapsible === 'disabled' ? Colors.gray.main : ''}};
        > .ant-collapse-item {
            &.ant-collapse-item-disabled {
                .ant-collapse-header {
                    background-color: ${Colors.gray.light};
                }

                > .ant-collapse-collapsible-disabled {
                    border-radius: 6px;
                }
            }
        }
    
    
    > .ant-collapse-item {
        > .ant-collapse-header {
            align-items: center;
            height: 46px;
            border-radius: 6px !important;
            .ant-collapse-header-text {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
            
        }
    }
    
    > .ant-collapse-item-active {
        > .ant-collapse-header {
            border-bottom: 1px solid ${Colors.cloudy.main};
            border-radius: 6px !important;
            background-color: ${Colors.cloudy.superlight};
        }
        > .ant-collapse-content-active {
            border-top: 0;
        }
    }
    
    &.ant-collapse-borderless {
        border-radius: 0;
        border-bottom: 1px solid ${Colors.cloudy.main};
        .ant-collapse-header {
            padding: 16px 0;
        }
        .ant-collapse-item-active {
            .ant-collapse-header {
                border-radius: 0 !important;
                border-bottom: 1px solid ${Colors.blue.main};
                background-color: ${Colors.white};
            }
            .ant-collapse-content-box {
                padding: 16px 24px 24px 24px ;
            }
        }
    }
    
    
`;

