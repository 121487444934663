import React, {  } from "react";
import { StyledRadio, StyledRadioGroup } from "./styled";
import { RadioProps, RadioGroupProps } from '.';


function Radio({...arg}: RadioProps) {
  return (
        <StyledRadio
          {...arg}
        />
  );
};

Radio.Group = function RadioGroup({ ...arg }: RadioGroupProps) {
  return (
        <StyledRadioGroup
          {...arg}
        />
  );
};


export { Radio };
