import { css } from "styled-components";

import { fontMixin } from "../mixins";

export const HMixin = css`
  ${fontMixin}
  font-weight: 600;
`;

export const H1Mixin = css`
  font-size: 20px;
  line-height: 28px;
`;
export const H2Mixin = css`
  font-size: 18px;
  line-height: 22px;
`;
export const H3Mixin = css`
  font-size: 16px;
  line-height: 22px;
`;
