import React, { useContext } from "react";
import {StyledContainer, StyledFooterFlex, StyledWrapperFlex} from "./styled";
import { ColumnsList } from "./columns";
import {Form, useColumnsSettings} from "./useColumnsSettings";
//import {Button} from "antd";
//import { ModuleCtx } from '@features/Module/context';
import {Button} from "@gp-frontend-ui/ui-kit-5v2";
import {Dictionary} from "./types";
import {ColumnSettings} from "./settings_types";


const ColumnsSettings: React.FC<{forms: Dictionary<Form>, columns: ColumnSettings[], onClose:()=>void}> = ({
                                                                                                               forms,
                                                                                                           columns,
                                                                                                               onClose
}) => {
    //const {type: module} = useContext(ModuleCtx);
    const module = "datainput";

    const {
        tableKey,
        sortedColumns,
        clientTemplates,
        selectedTemplate,
        chooseTemplatesHandler,
        onOk,
        onCancel,
        onChange,
        dirty
    } = useColumnsSettings(forms, columns, onClose);

    return (
        <StyledContainer>
            <StyledWrapperFlex>

                {sortedColumns?.length &&
                    <ColumnsList properties={sortedColumns} onChange={onChange}/>
                }
            </StyledWrapperFlex>

            <StyledFooterFlex gap={10}>
                <Button onClick={onOk} disabled={!dirty} view="primary">Применить</Button>
                <Button onClick={onCancel} view="primary-outlined">Сбросить</Button>
            </StyledFooterFlex>
        </StyledContainer>
    );
}

export default React.memo(ColumnsSettings);
