import styled from "styled-components";

export const Container = styled.div<{ size?: "s" | "m" | "l" }>`
  width: ${({ size = "m" }) => {
    switch (size) {
      case "s":
        return "318px";
      case "m":
        return "651px";
      case "l":
        return "calc(100vw - 64px)";
    }
  }};
`;
