import { css } from "styled-components";

export const Text14_20Mixin = css`
  font-size: 14px;
  line-height: 20px;
`;

export const Text13_18Mixin = css`
  font-size: 13px;
  line-height: 18px;
`;

export const Text13_15Mixin = css`
  font-size: 13px;
  line-height: 15px;
`;

export const Text12_16Mixin = css`
  font-size: 12px;
  line-height: 16px;
`;

export const Text10_14Mixin = css`
  font-size: 10px;
  line-height: 1.4;
`;

export const Text8_10Mixin = css`
  font-size: 8px;
  line-height: 10px;
`;
