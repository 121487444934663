import styled from "styled-components";
import { Table as AntTable } from "antd";
import { Colors } from '../../colors';
import { AnyObject } from 'antd/lib/_util/type';
import { RefTable } from 'antd/es/table/interface';
import { TableProps as AntTableProps } from 'antd/lib';
import { Table } from './index2';


export const StyledTable = styled(AntTable)<React.PropsWithChildren<typeof Table>>`
.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    &-track {
      background-color: ${Colors.gray.light};
      // opacity: 0.15;
    }
    &-thumb {
      border-radius: 50%;
      background-color: ${Colors.orange.main};
      // opacity: 0.5;
    }
  }
}

.ant-table-column-sorter {
  &-up,
  &-down {
    color: ${Colors.cloudy.main};
    &.active {
      color: ${Colors.blue.light};
    }
  }
},

.ant-table-summary {
    &, &>& {
      background-color: ${Colors.blue.light};
      color: ${Colors.black};

    }
  }

  .ant-table-cell {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: ${Colors.cloudy.main};
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: ${Colors.blue.main};
        }
      }
    }
  }
` as any;

export const SpanMax = styled.span`
  display: inline-block;
  width: 23px;
  height: 14px;
  margin-left: 0.3em;
  cursor: pointer;
  &::before {
    content: '';
    display: block;
    align-items: flex-end;
    width: 23px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='14' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='23' height='14' rx='7' fill='%23E2EEF4'/%3E%3Cpath d='M15.472 5.88889C15.2289 5.88889 14.9957 5.98546 14.8238 6.15737C14.6519 6.32928 14.5553 6.56244 14.5553 6.80555C14.5553 7.04867 14.6519 7.28182 14.8238 7.45373C14.9957 7.62564 15.2289 7.72222 15.472 7.72222C15.7151 7.72222 15.9483 7.62564 16.1202 7.45373C16.2921 7.28183 16.3887 7.04867 16.3887 6.80555C16.3887 6.56244 16.2921 6.32928 16.1202 6.15737C15.9483 5.98546 15.7151 5.88889 15.472 5.88889ZM11.4998 5.88889C11.2567 5.88889 11.0235 5.98546 10.8516 6.15737C10.6797 6.32928 10.5831 6.56244 10.5831 6.80555C10.5831 7.04867 10.6797 7.28182 10.8516 7.45373C11.0235 7.62564 11.2567 7.72222 11.4998 7.72222C11.7429 7.72222 11.9761 7.62564 12.148 7.45373C12.3199 7.28182 12.4164 7.04867 12.4164 6.80555C12.4164 6.56244 12.3199 6.32928 12.148 6.15737C11.9761 5.98546 11.7429 5.88889 11.4998 5.88889ZM7.52756 5.88889C7.28444 5.88889 7.05129 5.98546 6.87938 6.15737C6.70747 6.32928 6.61089 6.56244 6.61089 6.80555C6.61089 7.04867 6.70747 7.28182 6.87938 7.45373C7.05129 7.62564 7.28444 7.72222 7.52756 7.72222C7.77067 7.72222 8.00383 7.62564 8.17574 7.45373C8.34765 7.28182 8.44423 7.04867 8.44423 6.80555C8.44423 6.56244 8.34765 6.32928 8.17574 6.15737C8.00383 5.98546 7.77067 5.88889 7.52756 5.88889Z' fill='%2380A3C0'/%3E%3C/svg%3E%0A");
  }

  &:hover::before {
      /* background-image: url("data:image/svg+xml,%3Csvg width='23' height='14' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='23' height='14' rx='7' fill='%23FF6626'/%3E%3Cpath d='M15.472 5.88889C15.2289 5.88889 14.9957 5.98546 14.8238 6.15737C14.6519 6.32928 14.5553 6.56244 14.5553 6.80555C14.5553 7.04867 14.6519 7.28182 14.8238 7.45373C14.9957 7.62564 15.2289 7.72222 15.472 7.72222C15.7151 7.72222 15.9483 7.62564 16.1202 7.45373C16.2921 7.28183 16.3887 7.04867 16.3887 6.80555C16.3887 6.56244 16.2921 6.32928 16.1202 6.15737C15.9483 5.98546 15.7151 5.88889 15.472 5.88889ZM11.4998 5.88889C11.2567 5.88889 11.0235 5.98546 10.8516 6.15737C10.6797 6.32928 10.5831 6.56244 10.5831 6.80555C10.5831 7.04867 10.6797 7.28182 10.8516 7.45373C11.0235 7.62564 11.2567 7.72222 11.4998 7.72222C11.7429 7.72222 11.9761 7.62564 12.148 7.45373C12.3199 7.28182 12.4164 7.04867 12.4164 6.80555C12.4164 6.56244 12.3199 6.32928 12.148 6.15737C11.9761 5.98546 11.7429 5.88889 11.4998 5.88889ZM7.52756 5.88889C7.28444 5.88889 7.05129 5.98546 6.87938 6.15737C6.70747 6.32928 6.61089 6.56244 6.61089 6.80555C6.61089 7.04867 6.70747 7.28182 6.87938 7.45373C7.05129 7.62564 7.28444 7.72222 7.52756 7.72222C7.77067 7.72222 8.00383 7.62564 8.17574 7.45373C8.34765 7.28182 8.44423 7.04867 8.44423 6.80555C8.44423 6.56244 8.34765 6.32928 8.17574 6.15737C8.00383 5.98546 7.77067 5.88889 7.52756 5.88889Z' fill='white'/%3E%3C/svg%3E%0A"); */ /* orange */

      background-image: url("data:image/svg+xml,%3Csvg width='23' height='14' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='23' height='14' rx='7' fill='%2308566E'/%3E%3Cpath d='M15.472 5.88889C15.2289 5.88889 14.9957 5.98547 14.8238 6.15737C14.6519 6.32928 14.5553 6.56244 14.5553 6.80556C14.5553 7.04867 14.6519 7.28183 14.8238 7.45374C14.9957 7.62565 15.2289 7.72222 15.472 7.72222C15.7151 7.72222 15.9483 7.62565 16.1202 7.45374C16.2921 7.28183 16.3887 7.04867 16.3887 6.80556C16.3887 6.56244 16.2921 6.32928 16.1202 6.15737C15.9483 5.98547 15.7151 5.88889 15.472 5.88889ZM11.4998 5.88889C11.2567 5.88889 11.0235 5.98547 10.8516 6.15737C10.6797 6.32928 10.5831 6.56244 10.5831 6.80556C10.5831 7.04867 10.6797 7.28183 10.8516 7.45374C11.0235 7.62565 11.2567 7.72222 11.4998 7.72222C11.7429 7.72222 11.9761 7.62565 12.148 7.45374C12.3199 7.28183 12.4164 7.04867 12.4164 6.80556C12.4164 6.56244 12.3199 6.32928 12.148 6.15737C11.9761 5.98547 11.7429 5.88889 11.4998 5.88889ZM7.52756 5.88889C7.28444 5.88889 7.05129 5.98547 6.87938 6.15737C6.70747 6.32928 6.61089 6.56244 6.61089 6.80556C6.61089 7.04867 6.70747 7.28183 6.87938 7.45374C7.05129 7.62565 7.28444 7.72222 7.52756 7.72222C7.77067 7.72222 8.00383 7.62565 8.17574 7.45374C8.34765 7.28183 8.44423 7.04867 8.44423 6.80556C8.44423 6.56244 8.34765 6.32928 8.17574 6.15737C8.00383 5.98547 7.77067 5.88889 7.52756 5.88889Z' fill='white'/%3E%3C/svg%3E%0A");
  }
`;