import styled from "styled-components";
import { Collapse as AntCollapse } from 'antd';
import {Colors} from "../../colors";

export const StyledCollapse = styled(AntCollapse)`
    
    > .ant-collapse-item-disabled {
        .ant-collapse-header {
            background-color: ${Colors.gray.light};
            border-radius: 10px;
        }
    }

    > .ant-collapse-item {
        > .ant-collapse-header {
            align-items: center;

            .ant-collapse-header-text {
                font-weight: 600;
                line-height: 20px;
            }
        }
    }
    
`;

