import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const InputComponentToken = 
{
	'2022': {
		// Background color when the input box is activated	string	#ffffff
		activeBg: Colors.mainLighterBlue,
		// Active border color	string	#1677ff
		activeBorderColor: Colors.mainLighterBlue,
		// Box-shadow when active	string	0 0 0 2px rgba(5, 145, 255, 0.1)
		activeShadow: 'none',
		// Background color of addon	string	rgba(0, 0, 0, 0.02)
		addonBg: Colors.mainLightestBlue,
		// Box-shadow when active in error status	string	0 0 0 2px rgba(255, 38, 5, 0.06)
		errorActiveShadow: 'none',
		// Background color when the input box hovers	string	#ffffff
		hoverBg: Colors.mainLighterBlue,
		// Hover border color	string	#4096ff
		hoverBorderColor: Colors.mainLighterBlue,
		// Font size	number	14
		inputFontSize: 18,
		// Font size of large	number	16
		inputFontSizeLG: 16,
		// Font size of small	number	14
		inputFontSizeSM: 12,
		// Vertical padding of input	number	4
		paddingBlock: 10,
		// Vertical padding of large input	number	7
		paddingBlockLG: 12,
		// Vertical padding of small input	number	0
		paddingBlockSM: 8,
		// Horizontal padding of input	number	11
		paddingInline: 16, 
		// Horizontal padding of large input	number	11
		paddingInlineLG: 24,
		// Horizontal padding of small input	number	7
		paddingInlineSM: 12,
		// Box-shadow when active in warning status	string	0 0 0 2px rgba(255, 215, 5, 0.1)
		warningActiveShadow: Colors.mainRed,

		borderRadius: 0,
		borderRadiusSM: 0,
		borderRadiusLG: 0,

		colorBgContainer: Colors.mainLightestBlue,
		colorText: Colors.mainBlack,
		colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
		fontWeightStrong: 600,
		controlHeight: 40,
		colorIcon: Colors.mainBlue,
	},
	'2024': {
		// Background color when the input box is activated	string	#ffffff
		activeBg: Colors.mainWhite,
		// Active border color	string	#1677ff
		activeBorderColor: Colors.mainOrange,
		// Box-shadow when active	string	0 0 0 2px rgba(5, 145, 255, 0.1)
		activeShadow: 'none',
		// Background color of addon	string	rgba(0, 0, 0, 0.02)
		addonBg: Colors.mainLightestBlue,
		// Box-shadow when active in error status	string	0 0 0 2px rgba(255, 38, 5, 0.06)
		errorActiveShadow: 'none',
		// Background color when the input box hovers	string	#ffffff
		hoverBg: Colors.mainLightestBlue,
		// Hover border color	string	#4096ff
		hoverBorderColor: Colors.mainLighterBlue,
		// Font size	number	14
		inputFontSize: 14,
		// Font size of large	number	16
		inputFontSizeLG: 16,
		// Font size of small	number	14
		inputFontSizeSM: 12,

		// Vertical padding of input	number	4
		paddingBlock: 2,
		// Vertical padding of large input	number	7
		paddingBlockLG: 4,
		// Vertical padding of small input	number	0
		paddingBlockSM: 1,

		// Horizontal padding of input	number	11
		paddingInline: 12, 
		// Horizontal padding of large input	number	11
		paddingInlineLG: 18,
		// Horizontal padding of small input	number	7
		paddingInlineSM: 8,
		// Box-shadow when active in warning status	string	0 0 0 2px rgba(255, 215, 5, 0.1)
		warningActiveShadow: Colors.mainRed,

		borderRadius: 0,
		borderRadiusSM: 0,
		borderRadiusLG: 0,

		colorBgContainer: Colors.mainWhite,
		borderColor: 'rgba(0, 0, 0, 0.25)',

		colorText: Colors.mainBlack, // #232431
		colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
		fontWeightStrong: 600,
		controlHeight: 32,
		colorIcon: Colors.mainBlue,

		colorError: Colors.mainRed,
		colorErrorBg: '#ff8800',
		colorErrorBgHover: `${Colors.mainRed}44`,
		colorErrorBorderHover: '#ff8800',
		colorErrorText: '#ff8800',
		colorTextDescription: '#ff8800',
		colorWarning: '#ff8800',
		colorWarningBg: '#ff8800',
		colorWarningBgHover: '#ff8800',
		colorWarningBorderHover: '#ff8800',
		colorWarningText: '#ff8800',
	},
}