import * as React from "react";
import { SVGProps } from "react";
const SvgView = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="view" className="anticon anticon-view">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 3H9L4 8V21H10V19H6V9L10 5H18V13H20V3Z" />
    <rect x="9" y="3" width="2" height="7" />
    <rect x="4" y="8" width="6" height="2" />
    <rect x="12" y="17" width="6" height="2" />
    <path d="M15.5859 16L17.0002 14.5858L20.4144 18L16.9061 21.4111L15.5891 19.906L17.6702 18.085L15.5859 16Z" />
    </svg>
    </span>
);
export default SvgView;