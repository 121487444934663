import * as React from "react";
import { SVGProps } from "react";
const SvgReload = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="reload" className="anticon anticon-reload">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 12C20 13.502 19.5861 14.9072 18.8661 16.108L17.6956 14.0806L17.6112 14.1294C17.8624 13.4677 18 12.7499 18 12C18 8.68629 15.3137 6 12 6C10.916 6 9.89914 6.28746 9.0215 6.79032L7.99109 5.07542C9.16979 4.39155 10.5392 4 12 4C16.4183 4 20 7.58172 20 12ZM5.69378 7.07687C4.6325 8.43438 4 10.1433 4 12C4 16.4183 7.58172 20 12 20C13.4189 20 14.7516 19.6306 15.907 18.9827L14.9067 17.2501C14.0456 17.7279 13.0546 18 12 18C8.68629 18 6 15.3137 6 12C6 10.8909 6.30096 9.852 6.82566 8.96064L5.69378 7.07687Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9059 17.8126L16.3306 13.1308L20.8621 15.2439L16.9059 17.8126Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.0563 6.18741L7.63159 10.8692L3.10005 8.75609L7.0563 6.18741Z" />
</svg>
    </span>
);
export default SvgReload;
