import styled from "styled-components";
import { Table } from "antd";
import { Colors } from '../../colors';


export const StyledTable = styled(Table)`
  background-color: red;
  .ant-table-cell {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: ${Colors.error} !important;
      }
    }
  }
`;

export const StyledTable_OLD: typeof Table = styled(Table)`
  .ant-table-body {
    scrollbar-width: auto;
    scrollbar-color: auto;
  }
  
  // custom scrollbar
  .ant-table-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  .ant-table-body::-webkit-scrollbar-track {
    background-color: ${Colors.blue.light15+'55'};
    opacity: 0.2;
  }
  
  .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${Colors.blue.light15+'55'};
    opacity: 0.5;
  }

  .activeRow {
    background-color: ${Colors.blue.light15}; // FFF2DE
  }

  .ant-table-column-sort {
    background-color: var(--bgc-gray-light);
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--main-orange);
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #c0d1e0;
  }

  .ant-table-thead {
    th {
      height: 30px;
      &.ant-table-column-sort {
        background-color: var(--bgc-gray-light);
      }
      &.ant-table-column-has-sorters {
        .ant-table-column-sorter {
          visibility: hidden;
        }

        &[aria-sort] {
          .ant-table-column-sorter {
            visibility: visible;
          }
        }
        &:hover {
          background-color: var(--bgc-gray-light);
          .ant-table-column-sorter {
            visibility: visible;
          }
        }


      }
    }

    .ant-table-cell, .ant-table-cell.react-resizable {
      position: relative;
      font-weight: 400;
      color: var(--main-blue);
      padding: 2px 10px;
      background-color: var(--bgc-gray-light);
    }

    .ant-table-selection-column {
      padding-inline-end: 8px;
      padding-inline-start: 8px;
      background-color: var(--bgc-gray-light);
    }
  }

  .ant-table-tbody > tr > td {
    padding: 3px 0 3px 10px;
    height: 30px;
    border-bottom: 1px solid var(--bgc-gray-light);
    font-weight: 400;
  }

  .ant-table-row-level-1,
  .ant-table-row-level-2,
  .ant-table-row-level-3 {
    .ant-table-cell.ant-table-selection-column {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: calc(100% + 1px);
        background-color: #ff976b;
      }
    }
  }

  .ant-btn-link {
    display: inline;
  }

  .ant-table-summary {
    &, &>& {
      background-color: ${Colors.blue.light};
      color: ${Colors.black};

    }
    table {
      tfoot {
        >tr {
          >td {
            padding: 0;
            border: 0;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
  }
`;