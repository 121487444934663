import { Modal } from 'antd';
import styled, { createGlobalStyle } from "styled-components";
import {Colors} from "../../colors";

export const StyledModal = styled(Modal)`    
    .ant-modal-content {
        padding: 0;
        margin: 0;

        > * {
            border-radius: 2px;
            padding: 16px 20px;
            margin: 0;
        }
        
        .ant-modal-header {
            border-bottom: 1px solid ${Colors.gray.main};
        }

        .ant-modal-footer {
            border-top: 1px solid ${Colors.gray.main};
        }
    }
`;

export const Title = styled.div`
  flex-grow: 3;
`;

export const Slot = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  column-gap: 5px;

  .ant-btn-icon-only {
    width: 24px;
    height: 24px;
  }
`;

export const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: ${Colors.white};
`;

export const GlobalModalStyles = createGlobalStyle<{ fullScreen?: boolean }>`
    .ant-modal-mask {
        background-color: ${Colors.blue.main};
    }

    .gp-modal {
        .ant-modal {
            padding-bottom: 0;
            transition: width .1s, height .1s, transform .1s;

            ${({fullScreen}) =>
                    fullScreen &&
                    `
                        top: 0;
                      `}
            &-content {
                border-radius: 0;
            }

            &-body {
                ${({fullScreen}) =>
                        fullScreen &&
                        `
                  height: calc(100vh - 150px);
                `}
            }
            
        }
    }
`;
