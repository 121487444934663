import * as React from "react";
import { SVGProps } from "react";
const SvgGraph = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="graph" className="anticon anticon-graph">
    <svg width="1em" height="1em" viewBox="0 0 26 25" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M3.924 21.875V11.458h6.25V3.125h6.25v8.333h6.25v10.417h-6.25v-8.333h-6.25v8.333h-6.25Zm2.083-2.083H8.09v-6.25H6.007v6.25Zm8.334-8.334h-2.084v-6.25h2.084v6.25Zm6.25 8.334h-2.084v-6.25h2.083v6.25Z" />
  </svg>
    </span>
);
export default SvgGraph;
