import React, {useState} from "react";
import {Modal as AntdModal, ConfigProvider} from "antd";
import {ExpandOutlined, CloseOutlined, ExpandAltOutlined} from "@ant-design/icons";

import {Button} from "../Button";

import type {ModalProps} from "./components.types";

import {StyledModal, TitleWrapper, Title, Slot, GlobalModalStyles} from "./styled";

export const Modal: React.FC<ModalProps> = ({
                                                children,
                                                wrapClassName,
                                                enableFullScreen = false,
                                                title,
                                                closable = true,
                                                onCancel,
                                                width,
                                                headerAddons,
                                                ...arg
                                            }) => {
    const [fullScreen, setFullScreen] = useState(arg.fullScreen);

    function updateSize() {
        setFullScreen(!fullScreen);
    }

    return (
        <>
            <StyledModal
                wrapClassName={`gp-modal ${wrapClassName ?? ""}`}
                title={
                    <TitleWrapper>
                        <Title>{title}</Title>
                        <Slot>
                            {headerAddons}
                            {enableFullScreen && (
                                // TODO: разобраться с кнопкой после реализации ui button
                                <Button
                                    className="fullscreen-btn"
                                    style={{color: "#B6CBDC"}}
                                    ghost
                                    icon={<ExpandAltOutlined />}
                                    onClick={updateSize}
                                />
                            )}
                            {closable && (
                                // TODO: разобраться с кнопкой после реализации ui button
                                <Button
                                    style={{color: "#B6CBDC"}}
                                    ghost
                                    icon={<CloseOutlined/>}
                                    onClick={onCancel}
                                />
                            )}
                        </Slot>
                    </TitleWrapper>
                }
                onCancel={onCancel}
                width={fullScreen ? "95%" : width || "520px"}
                closable={false}
                {...arg}
            >
                {children}
            </StyledModal>
            <GlobalModalStyles fullScreen={fullScreen} />
        </>
    );
};
