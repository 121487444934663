import * as React from "react";
import { SVGProps } from "react";
const SvgDoc = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="doc" className="anticon anticon-doc">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1739 17.2174H10.2174V12.6957H17.1739V9.21739H13V5.04348H6.04345V18.9565H17.1739V17.2174ZM18.2174 12.6957H19.6087V17.2174H18.2174V20H5V4H13.6957L18.2174 8.52174V12.6957ZM13.2782 13.593V14.5461C13.1321 14.3757 12.92 14.2957 12.68 14.2957C12.1408 14.2957 11.7408 14.6678 11.7408 15.2452C11.7408 15.8226 12.1408 16.1983 12.68 16.1983C12.9339 16.1983 13.1495 16.1113 13.2956 15.9339V16.1739H13.713V13.593H13.2782ZM12.7321 15.8261C12.4191 15.8261 12.1791 15.6 12.1791 15.2452C12.1791 14.8904 12.4191 14.6643 12.7321 14.6643C13.0452 14.6643 13.2852 14.8904 13.2852 15.2452C13.2852 15.6 13.0452 15.8261 12.7321 15.8261ZM15.1183 16.1983C15.6957 16.1983 16.1096 15.8017 16.1096 15.2452C16.1096 14.6887 15.6957 14.2957 15.1183 14.2957C14.5478 14.2957 14.1304 14.6887 14.1304 15.2452C14.1304 15.8017 14.5478 16.1983 15.1183 16.1983ZM15.1182 15.8261C14.8052 15.8261 14.5687 15.6 14.5687 15.2452C14.5687 14.8904 14.8052 14.6643 15.1182 14.6643C15.4348 14.6643 15.6713 14.8904 15.6713 15.2452C15.6713 15.6 15.4348 15.8261 15.1182 15.8261ZM17.379 16.1983C17.7408 16.1983 18.0295 16.0417 18.1721 15.7635L17.8382 15.5687C17.7269 15.7461 17.5599 15.8261 17.3756 15.8261C17.0556 15.8261 16.8121 15.607 16.8121 15.2452C16.8121 14.887 17.0556 14.6643 17.3756 14.6643C17.5599 14.6643 17.7269 14.7443 17.8382 14.9217L18.1721 14.727C18.0295 14.4452 17.7408 14.2957 17.379 14.2957C16.7912 14.2957 16.3738 14.6887 16.3738 15.2452C16.3738 15.8017 16.7912 16.1983 17.379 16.1983Z"  fill="#08566E"/>
    </svg>
    </span>
);
export default SvgDoc;
