import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const MenuComponentToken = {
	/// Border width of menu item active bar	number	1
	activeBarBorderWidth: 0,
	/// Height of menu item active bar	number	2
	// activeBarHeight: '',
	/// Width of menu item active bar	number	0
	// activeBarWidth: '',
	/// Size of icon when collapsed	number	16
	collapsedIconSize: 16,
	/// Width when collapsed	number	80
	collapsedWidth: 40,
	/// Color of active danger menu item text	string	#fff2f0
	dangerItemActiveBg: Colors.mainRed,
	/// Color of danger menu item text	string	#ff4d4f
	dangerItemColor: Colors.mainWhite,
	/// Hover color of danger menu item text	string	#ff4d4f
	dangerItemHoverColor: Colors.mainRedHover,
	/// Color of selected danger menu item text	string	#fff2f0
	dangerItemSelectedBg: Colors.mainRedActive,
	/// Color of selected danger menu item text	string	#ff4d4f
	dangerItemSelectedColor: Colors.mainWhite,
	/// Background of active danger menu item in dark mode	string	#ff4d4f
	// darkDangerItemActiveBg: Colors.mainRedActive,
	/// Color of danger menu item text in dark mode	string	#ff4d4f
	// darkDangerItemColor: Colors.main,
	/// Background of hovered danger menu item in dark mode	string	#ff7875
	// darkDangerItemHoverColor: '',
	/// Background of active danger menu item in dark mode	string	#ff4d4f
	// darkDangerItemSelectedBg: '',
	/// Color of selected danger menu item in dark mode	string	#fff
	// darkDangerItemSelectedColor: '',
	/// Color of group title text in dark mode	string	rgba(255, 255, 255, 0.65)
	// darkGroupTitleColor: '',
	/// Background of menu item in dark mode	string	#001529
	// darkItemBg: '',
	/// Color of menu item text in dark mode	string	rgba(255, 255, 255, 0.65)
	// darkItemColor: '',
	/// Color of disabled menu item in dark mode	string	rgba(255, 255, 255, 0.25)
	// darkItemDisabledColor: '',
	/// Background of hovered menu item in dark mode	string	transparent
	// darkItemHoverBg: '',
	/// Color of hovered menu item in dark mode	string	#fff
	// darkItemHoverColor: '',
	/// Background of active menu item in dark mode	string	#1677ff
	// darkItemSelectedBg: '',
	/// Color of selected menu item in dark mode	string	#fff
	// darkItemSelectedColor: '',
	/// The background color of the overlay menu in dark mode.	string	#001529
	// darkPopupBg: '',
	/// Background of submenu item in dark mode	string	#000c17
	// darkSubMenuItemBg: '',
	/// Width of popup menu	number	160
	dropdownWidth: 200,
	/// Color of group title text	string	rgba(0, 0, 0, 0.45)
	groupTitleColor: Colors.mainLightBlue,
	/// font-size of group title	number	14
	groupTitleFontSize: 14,
	/// line-height of group title	string | number	1.5714285714285714
	groupTitleLineHeight: 1.5714285714285714,
	/// Border radius of horizontal menu item	number	0
	horizontalItemBorderRadius: 0,
	/// Background color of horizontal menu item when hover	string	transparent
	horizontalItemHoverBg: Colors.mainBlue,
	/// Hover color of horizontal menu item text	string	#1677ff
	horizontalItemHoverColor: Colors.mainDarkBlue,
	/// Background color of horizontal menu item when selected	string	transparent
	horizontalItemSelectedBg: Colors.mainBlue,
	/// Color of selected horizontal menu item text	string	#1677ff
	horizontalItemSelectedColor: Colors.mainDarkBlue,
	/// LineHeight of horizontal menu item	undefined | LineHeight<string | number>	46px
	horizontalLineHeight: 50,
	/// Spacing between icon and text	undefined | MarginInlineEnd<string | number>	10
	iconMarginInlineEnd: 10,
	/// Size of icon	number	14
	iconSize: 20,
	/// Background color of menu item when active	string	#e6f4ff
	itemActiveBg: Colors.mainLightestBlue,
	/// 	string	#ffffff
	itemBg: Colors.mainWhite,
	/// Radius of menu item	number	8
	itemBorderRadius: 0,
	/// Color of menu item text	string	rgba(0, 0, 0, 0.88)
	itemColor: Colors.mainBlack,
	/// Color of disabled menu item text	string	rgba(0, 0, 0, 0.25)
	itemDisabledColor: Colors.mainLighterBlue,
	/// Height of menu item	number	40
	itemHeight: 32,
	/// Background color of menu item when hover	string	rgba(0, 0, 0, 0.06)
	itemHoverBg: Colors.mainLightestBlue,
	/// Hover color of menu item text	string	rgba(0, 0, 0, 0.88)
	itemHoverColor: Colors.mainBlack,
	/// margin-block of menu item	undefined | MarginBlock<string | number>	4
	itemMarginBlock: 0,
	/// Horizontal margin of menu item	number	4
	itemMarginInline: 0,
	/// padding-inline of menu item	undefined | PaddingInline<string | number>	16
	itemPaddingInline: 32,
	/// Background color of menu item when selected	string	#e6f4ff
	itemSelectedBg: Colors.mainLightestBlue,
	/// Color of selected menu item text	string	#1677ff
	itemSelectedColor: Colors.mainBlack,
	/// Background color of popup	string	#ffffff
	popupBg: Colors.mainWhite,
	/// Background color of sub-menu item	string	rgba(0, 0, 0, 0.02)
	subMenuItemBg: Colors.mainWhite,
	/// Radius of sub-menu item	number	4
	subMenuItemBorderRadius: 0,
	/// z-index of popup menu	number	1050
	zIndexPopup: 1050,

	borderRadius: 0,
	borderRadiusLG: 0,
	borderRadiusSM: 0,

	margin: 16,
	padding: 16,
}