import React from "react";
import { Tooltip } from "antd";

import type { ItemProps } from "./components.types";

import { Wrapper } from "./styled";

export const Item: React.FC<ItemProps> = ({
  children,
  title,
  modified,
  active,
  onClick,
}) => (
  <Wrapper active={active} modified={modified} onClick={onClick}>
    <Tooltip
      placement="bottomRight"
      arrowPointAtCenter
      autoAdjustOverflow
      title={title}
      color={"var(--bgc-dark-blue)"}
    >
      {children}
    </Tooltip>
  </Wrapper>
);
