import { Drawer } from "antd";
import styled from "styled-components";
// import "../../vars.css";

type PropsDrawer = {
  visible?: boolean;
};

/// TODO подстановка ANY для устранения ошибки сборки - надо чекать и фиксить 
export const StyledDrawer: any = styled(Drawer)`
  left: ${(props: PropsDrawer) => (props?.visible ? "64px" : "0")};

  .ant-drawer-mask {
    opacity: 0 !important;
    animation: none !important;
  }

  .ant-drawer-content {
    background-color: var(--main-white);
  }

  .ant-drawer-wrapper-body {
    position: relative;
  }

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }
`;
