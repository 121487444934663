import { ReactNode } from "react";
import type { ButtonProps as AntdButtonProps } from "antd";
import { Colors } from '../Theme';
import { ButtonComponentToken } from '../Theme/token.button';

export type ButtonProps = AntdButtonProps & {
  children?: ReactNode;
  context?: keyof typeof ContextToken;
  color?: keyof typeof ButtonStyles;
  active?: string|boolean;
  act?: boolean;
  open?: boolean;
  title?: string;
};

export const ButtonStyles = {
  'default': {},
  'lightblue': {},
  'blue': {
    colorPrimary: Colors.mainBlue,
    colorPrimaryBorder: Colors.mainBlue,
    colorPrimaryHover: Colors.mainDarkBlue,
    colorPrimaryActive: Colors.mainDarkBlue,
    primaryColor: Colors.mainLighterBlue,
    colorTextDisabled: Colors.mainLighterBlue, // opacity 30% - `${Colors.mainBlue}55`
    defaultBg: 'transparent',
    defaultHoverBg: Colors.mainLighterBlue,
    defaultBorderColor: Colors.mainBlue,
    defaultColor: Colors.mainBlue,
    defaultHoverColor: Colors.mainDarkBlue,
    defaultActiveColor: Colors.mainDarkBlue,
    defaultHoverBorderColor: Colors.mainDarkBlue,

    defaultGhostColor: Colors.mainBlue,
    textHoverBg: Colors.mainLighterBlue,
    colorLink: Colors.mainBlue,
    
    // colorBgBase: 'transparent',
    // colorFillContentHover: 'transparent',
    // colorFillContent: 'transparent',
    // colorBgTextHover: 'transparent',
    // controlItemBgHover:'transparent',
    // controlItemBgActive: 'transparent',
    // controlItemBgActiveHover:'transparent',

    dangerColor: Colors.mainWhite,
    colorError: '#D92020',
    colorErrorOutline: '#D92020',
    
  },
  'darkblue': {},
  'pink': {},
  'original': {},
  'colorful': {},
};

export const ContextToken = {
  'default': {
  },
  'styling': ButtonStyles,
  'sidebar': {
    controlHeight: 48,
    controlHeightLG: 48,
    controlHeightSM: 48,

    contentFontSize: 24,
    contentFontSizeLG: 24,
    contentFontSizeSM: 24,

    onlyIconSize: 24,
    onlyIconSizeLG: 24,
    onlyIconSizeSM: 24,
    
    paddingInline: 12,
    paddingBlock: 12,

    primaryColor: Colors.mainWhite,
    colorBgContainerDisabled: Colors.mainDarkBlue,
    
    colorPrimary: Colors.mainOrange,
    colorPrimaryBorder: Colors.mainOrange,
    colorPrimaryActive: Colors.mainOrangeActive,
    colorPrimaryHover: Colors.mainOrangeHover,

    defaultColor: Colors.mainLightBlue,
    defaultBg: Colors.mainDarkBlue,
    defaultBorderColor: Colors.mainDarkBlue,
    
    defaultActiveColor: Colors.mainDarkBlue,
    defaultActiveBg: Colors.mainWhite,
    defaultActiveBorderColor: Colors.mainDarkBlue,

    defaultHoverColor: Colors.mainWhite,
    defaultHoverBg: Colors.mainBlue,
    defaultHoverBorderColor: Colors.mainDarkBlue,

    colorTextDisabled: `${Colors.mainLighterBlue}55`, // Colors.grey,
  },
  'pagetitle': {
    primaryColor: Colors.mainWhite,
    colorBgContainerDisabled: 'transparent',
    colorTextDisabled: `${Colors.mainLighterBlue}55`, // opacity 30% - `${Colors.mainBlue}55`
    
    colorPrimary: Colors.mainOrange,
    colorPrimaryBorder: Colors.mainOrange,
    colorPrimaryActive: Colors.mainOrangeActive,
    colorPrimaryHover: Colors.mainOrangeHover,

    defaultColor: Colors.mainLightBlue,
    defaultBg: 'transparent',
    defaultBorderColor: 'transparent',
    
    defaultActiveColor: Colors.mainOrange,
    defaultActiveBg: 'transparent',
    defaultActiveBorderColor: 'transparent',

    defaultHoverColor: Colors.mainWhite,
    defaultHoverBg: 'transparent',
    defaultHoverBorderColor: 'transparent',

  },
  'table': {
    controlHeight: ButtonComponentToken.controlHeightSM,
    controlHeightLG: ButtonComponentToken.controlHeightSM,
    paddingInline: ButtonComponentToken.paddingInlineSM,
    paddingInlineLG: ButtonComponentToken.paddingInlineSM,
    paddingBlock: ButtonComponentToken.paddingBlockSM,
    paddingBlockLG: ButtonComponentToken.paddingBlockSM,
    onlyIconSize: ButtonComponentToken.onlyIconSizeSM,
    onlyIconSizeLG: ButtonComponentToken.onlyIconSizeSM,
    contentFontSize: ButtonComponentToken.contentFontSizeSM,
    contentFontSizeLG: ButtonComponentToken.contentFontSizeSM,
    primaryColor: Colors.mainOrange,
    colorTextDisabled: Colors.grey,
    borderColorDisabled: 'transparent',
    colorBgContainerDisabled: 'transparent',
    controlItemBgActiveDisabled: 'transparent',
    
    colorPrimary: 'transparent',
    colorPrimaryBorder: 'transparent',
    colorPrimaryActive: 'transparent',
    colorPrimaryHover: Colors.mainOrangeHover,

    defaultColor: Colors.mainLightBlue,
    defaultBg: 'transparent',
    defaultBorderColor: 'transparent',
    
    defaultActiveColor: Colors.mainOrange,
    defaultActiveBg: 'transparent',
    defaultActiveBorderColor: 'transparent',

    defaultHoverColor: Colors.mainOrange,
    defaultHoverBg: 'transparent',
    defaultHoverBorderColor: 'transparent'
  },
};

