import * as React from "react";
import { SVGProps } from "react";
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="menu" className="anticon anticon-menu">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M8 9V7h12v2H8Zm0 4v-2h12v2H8Zm0 4v-2h12v2H8Zm-4 0v-2h2v2H4Zm2-8H4V7h2v2Zm-2 4v-2h2v2H4Z" />
  </svg>
    </span>
);
export default SvgMenu;
