import * as React from "react";
import { SVGProps } from "react";
const SvgDrop = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="drop" className="anticon anticon-drop">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.372 6H12.372V8H15.1699L12.372 12.196L10.3275 9.13049L6 16.343L7.71499 17.372L10.416 12.869L12.372 15.8028L16.372 9.80278V12H18.372V6Z" />
    </svg>
    </span>
);
export default SvgDrop;
