import styled from "styled-components";

export const Wrapper = styled.div<{ active?: boolean; modified?: boolean }>`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #002a38;
  background-color: ${({ active }) =>
    active ? "var(--main-white)" : "var(--bgc-dark-blue)"};
  color: ${({ active }) =>
    active ? "var(--bgc-dark-blue)" : "var(--main-white)"};
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: #013f54;
  }
`;
