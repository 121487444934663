import { Colors } from './colors';

export const ButtonComponentToken = {
    /**
     * @descEN Font weight of text
     */
    fontWeight: 500,
    /**
     * @descEN Shadow of default button
     */
    defaultShadow: 'none',
    /**
     * @descEN Shadow of primary button
     */
    primaryShadow: 'none',
    /**
     * @descEN Shadow of danger button
     */
    dangerShadow: 'none',
    /**
     * @descEN Text color of primary button
     */
    primaryColor: Colors.mainWhite,
	/**
	 * Primary background
	 */
	colorPrimary: Colors.mainOrange,
	/**
	 * Primary border
	 */
	colorPrimaryBorder: Colors.mainOrange,
	/**
	 * Primary:active (press) background
	 */
	colorPrimaryActive: Colors.mainOrangeActive,
	/**
	 * Primary:hover background
	 */
	colorPrimaryHover: Colors.mainOrangeHover,

    /**
     * @descEN Text color of default button
     */
    defaultColor: Colors.mainOrange,
    /**
     * @descEN Background color of default button
     */
    defaultBg: Colors.mainWhite,
    /**
     * @descEN Border color of default button
     */
    defaultBorderColor: Colors.mainOrange,
    /**
     * @descEN Text color of danger button
     */
    dangerColor: Colors.mainWhite,
	colorError: '#D92020',

    /**
     * @descEN Background color of default button when hover
     */
    defaultHoverBg: '#0000000D',
    /**
     * @descEN Text color of default button when hover
     */
    defaultHoverColor: Colors.mainOrange,
    /**
     * @descEN Border color of default button
     */
    defaultHoverBorderColor: Colors.mainOrange,
    /**
     * @desc 默认按钮激活态背景色
     * @descEN Background color of default button when active
     */
    defaultActiveBg: '#0000001F',
    /**
     * @desc 默认按钮激活态文字颜色
     * @descEN Text color of default button when active
     */
    defaultActiveColor: Colors.mainOrange,
    /**
     * @desc 默认按钮激活态边框颜色
     * @descEN Border color of default button when active
     */
    defaultActiveBorderColor: Colors.mainOrange,
    /**
     * @desc 禁用状态边框颜色
     * @descEN Border color of disabled button
     */
    borderColorDisabled: 'transparent',
    /**
     * @desc 默认幽灵按钮文本颜色
     * @descEN Text color of default ghost button
     */
    defaultGhostColor: Colors.mainOrange,
    /**
     * @desc 幽灵按钮背景色
     * @descEN Background color of ghost button
     */
    ghostBg: 'transparent',
    /**
     * @desc 默认幽灵按钮边框颜色
     * @descEN Border color of default ghost button
     */
    defaultGhostBorderColor: 'transparent',
    /**
     * @desc 按钮横向内间距
     * @descEN Horizontal padding of button
     */
    paddingInline: 16,
    /**
     * @desc 大号按钮横向内间距
     * @descEN Horizontal padding of large button
     */
    paddingInlineLG: 24,
    /**
     * @desc 小号按钮横向内间距
     * @descEN Horizontal padding of small button
     */
    paddingInlineSM: 12,
    /**
     * @desc 按钮纵向内间距
     * @descEN Vertical padding of button
     */
    paddingBlock: 6,
    /**
     * @desc 大号按钮纵向内间距
     * @descEN Vertical padding of large button
     */
    paddingBlockLG: 8,
    /**
     * @desc 小号按钮纵向内间距
     * @descEN Vertical padding of small button
     */
    paddingBlockSM: 4,
    /**
     * @desc 只有图标的按钮图标尺寸
     * @descEN Icon size of button which only contains icon
     */
    onlyIconSize: 24,
    /**
     * @desc 大号只有图标的按钮图标尺寸
     * @descEN Icon size of large button which only contains icon
     */
    onlyIconSizeLG: 32,
    /**
     * @desc 小号只有图标的按钮图标尺寸
     * @descEN Icon size of small button which only contains icon
     */
    onlyIconSizeSM: 16,

    controlHeight: 32,
    controlHeightLG: 48,
    controlHeightSM: 20,

    /**
     * @desc 按钮组边框颜色
     * @descEN Border color of button group
     */
    groupBorderColor: Colors.mainOrange,
    /**
     * @desc 链接按钮悬浮态背景色
     * @descEN Background color of link button when hover
     */
    linkHoverBg: 'transparent',
    /**
     * @desc 文本按钮悬浮态背景色
     * @descEN Background color of text button when hover
     */
    textHoverBg: Colors.mainOrangeHover,
    /**
     * @desc 按钮内容字体大小
     * @descEN Font size of button content
     */
    contentFontSize: 14,
    /**
     * @desc 大号按钮内容字体大小
     * @descEN Font size of large button content
     */
    contentFontSizeLG: 16,
    /**
     * @desc 小号按钮内容字体大小
     * @descEN Font size of small button content
     */
    contentFontSizeSM: 12,
    /**
     * @desc 按钮内容字体行高
     * @descEN Line height of button content
     */
    contentLineHeight: 1,
    /**
     * @desc 大号按钮内容字体行高
     * @descEN Line height of large button content
     */
    contentLineHeightLG: 1,
    /**
     * @desc 小号按钮内容字体行高
     * @descEN Line height of small button content
     */
    contentLineHeightSM: 1,

	borderRadius: 0,
	borderRadiusLG: 0,
	borderRadiusSM: 0,
}