import * as React from "react";
import { SVGProps } from "react";
const SvgJournalIcon = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="journalicon" className="anticon anticon-journalicon">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M7.11584 3.80046L11.9171 2L16.7183 3.80046L20.8341 2V5.3439V14.0351V17.5415L11.9171 22L3 17.5415V14.0351V5.3439V2L7.11584 3.80046ZM11.9171 4.11632L14.1134 4.93995L11.9171 5.90073L9.72071 4.93995L11.9171 4.11632ZM7.05823 5.93838L4.98157 6.71712V12.8105L10.9263 15.782V7.63065L7.05823 5.93838ZM12.9078 7.63065V15.7818L18.8525 12.8095V6.71712L16.7759 5.93838L12.9078 7.63065ZM4.98157 16.3168V15.0259L11.9171 18.4936L18.8525 15.0259V16.3158L11.9171 19.7836L4.98157 16.3168Z" />
    </svg>
    </span>
);
export default SvgJournalIcon;
