import { AliasToken } from 'antd/es/theme/interface';
import { ThemeConfig } from 'antd/lib';
import { Colors } from './colors';
import { ButtonComponentToken } from './token.button';
import { CheckboxComponentToken } from './token.checkbox';
import { CollapseComponentToken } from './token.collapse';
import { DatePickerToken } from './token.datepicker';
import { DropdownComponentToken } from './token.dropdown';
import { FormToken } from './token.form';
import { InputComponentToken } from './token.input';
import { InputNumberComponentToken } from './token.inputnumber';
import { MenuComponentToken } from './token.menu';
import { ModalComponentToken } from './token.modal';
import { PaginationComponentToken } from './token.pagination';
import { RadioComponentToken } from './token.radio';
import { SelectComponentToken } from './token.select';
import { TooltipComponentToken } from './token.tooltip';
import { TableComponentToken } from './token.table';

export const globalThemeToken: ThemeConfig = {
	"components": {
		"Button": ButtonComponentToken,
		"Checkbox": CheckboxComponentToken['2024'],
		"Collapse": CollapseComponentToken,
		"DatePicker": DatePickerToken,
		"Dropdown": DropdownComponentToken,
		"Form": FormToken,
		"Input": InputComponentToken['2024'],
		"InputNumber": InputNumberComponentToken['2024'],
		"Menu": MenuComponentToken,
		"Modal": ModalComponentToken,
		"Pagination": PaginationComponentToken,
		"Radio": RadioComponentToken,
		"Select": SelectComponentToken,
		"Table": TableComponentToken,
		"Tooltip": TooltipComponentToken,

		"Cascader": {
		  "colorBgContainer": "rgb(145, 210, 113)",
		  "colorPrimaryBorder": "rgb(225, 21, 133)",
		  "colorPrimaryHover": "rgb(245, 34, 45)"
		},
	  },
	  "token": { // Partial<AliasToken>
		"fontFamily": "Montserrat, sans-serif",
		"colorPrimary": Colors.mainDarkBlue,
		"colorInfo": "#5075a9",
		"colorLink": "#148497",
		"colorError": Colors.mainRed,
	  }
};

/*
\t(.*?)\t(.*)\t([^\t]+)$

/// $2 $3
$1: $3,
*/