import {ColumnsSettings} from "../components/ColumnsSettings";
import {dataColumns, dataForms} from "../components/ColumnsSettings/data";
import {Dictionary} from "../components/ColumnsSettings/types";
import {Form} from "../components/ColumnsSettings/useColumnsSettings";

export const ColumnsSettingsPage = () => {
    const forms = dataForms as Dictionary<Form>;
    const columns = dataColumns;

    return (
        <>
            <ColumnsSettings forms={forms} columns={dataColumns} onClose={() => {}}/>
        </>
    );
}

export default ColumnsSettingsPage;