import React from "react";
import {Tooltip as AntTooltip } from "antd";
import {TooltipProps} from "antd";

export const Tooltip: React.FC<TooltipProps> = ({
  placement = "bottom",
  children,
  ...args
}) => {
  return (
      <AntTooltip
        placement={placement}
        autoAdjustOverflow
        {...args}
      >
        {children}
      </AntTooltip>
  );
};
