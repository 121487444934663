import React from "react";
import { Resizable, ResizableProps } from "react-resizable";
import { ResizableHandle } from "./styled";

export const ResizableCell: React.FC<ResizableProps> = ({
  onResize,
  width,
  ...restProps
}) =>
  width ? (
    <Resizable
      width={width}
      height={0}
      handle={
        <ResizableHandle
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[110, 0]}
      maxConstraints={[500, 0]}
    >
      <th {...restProps} />
    </Resizable>
  ) : (
    <th {...restProps} />
  );
