import * as React from "react";
import { SVGProps } from "react";
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="trash" className="anticon anticon-trash">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M19 7v14H5V7H4V5h5V3h6v2h5v2h-1ZM7 7v12h10V7H7Zm2 2h2v5H9V9Zm4 0h2v5h-2V9Z" />
    </svg>
    </span>
);
export default SvgTrash;
