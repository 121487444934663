export const Colors = {
	primary: '#000000',
	secondary: '#ff0000',
	
	black: '#232431',
	mainBlack: '#232431',
	darkBlue: '#013F54',
	mainDarkBlue: '#013F54',
	mainSemiDarkBlue: '#023749',
	blue: '#08566E',
	mainBlue: '#08566E',
	lightBlue: '#80A3C0',
	mainLightBlue: '#80A3C0',
	mainLighterBlue: '#B6CBDC',
	mainLightestBlue: '#E2EEF4',
	mainSnow: '#F3F8FB',
	white: '#FFF',
	mainWhite: '#FFF',
	
	orange: '#FF7C45',
	mainOrange: '#FF7C45',
	mainOrangeHover: '#E8642C',
	mainOrangeActive: '#CD4E18',

	red: '#FF626E',
	mainRed: '#FF626E',
	mainRedHover: '#BA1717',
	mainRedActive: '#981111',

	green: '#15BE4D',
	mainGreen: '#15BE4D',


	additionalYellow: '#FFF2DE',
	additionalRed: '#FFE8DF',
	additionalOrange: '#FF6626',

	inputMain: '#013F54',
	inputLight: '#E2EEF4',
	inputLightTransparent: '#E2EEF466',

	grey: '#747782',
	lightGrey: '#E5E7EB',
	borderGrey: '#B0B5BD',
};