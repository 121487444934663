import * as React from "react";
import { SVGProps } from "react";
const SvgStatReports = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="reports" className="anticon anticon-reports">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4h4v16H4V4Zm6 4h4v12h-4V8Zm10 4h-4v8h4v-8Z"
    />
  </svg>
</span>);
export default SvgStatReports;
