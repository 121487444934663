import * as React from "react";
import { SVGProps } from "react";
const SvgPaper = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="paper" className="anticon anticon-paper">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg"> 
    <path fillRule="evenodd" clipRule="evenodd" d="M10 3H20V21H4V9L10 3ZM11 5H18V19H6V10L11 5Z" />
    <rect x="10" y="3" width="2" height="8" />
    <rect x="4" y="9" width="7" height="2" />
</svg>
    </span>
);
export default SvgPaper;
