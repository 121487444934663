import styled from "styled-components";

export const StyledWrapper = styled.div`
    .styled-popup-select {
        .ant-select-item,
        .ant-select-item-option {
            display: flex;
            flex-direction: row-reverse;
            gap: 12px;
        }
    }
`;



