import React from "react";

import { Tabs as StyledTabs, TabsGlobalStyle } from "./styled";

const TabsComponent: typeof StyledTabs = (props) => (
  <>
    <StyledTabs {...props} />
    <TabsGlobalStyle />
  </>
);

TabsComponent.TabPane = StyledTabs.TabPane;

export default TabsComponent;
