import { Colors } from "@gp-frontend-ui/ui-kit-5v2";
import React from "react";
import type {CollapseProps} from "./components.types";
import {StyledCollapse} from "./styled";

export const Collapse: React.FC<CollapseProps> = ({
                                                      errorCaption,
                                                      items,
                                                      ...args
                                                  }) => {


    return (
        <StyledCollapse
            //style={{borderColor: args.collapsible === 'disabled' ? Colors.gray.main : ''}}
            items={items}
            {...args}
        />
    );
};

