import styled from "styled-components";
// import "../../vars.css";

import { HMixin, H1Mixin, H2Mixin, H3Mixin } from "./mixins";

export const TitleH1 = styled.h1`
  ${HMixin}
  ${H1Mixin}
`;

export const TitleH2 = styled.h2`
  ${HMixin}
  ${H2Mixin}
`;

export const TitleH3 = styled.h3`
  ${HMixin}
  ${H3Mixin}
`;
