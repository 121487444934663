import * as React from "react";
import { SVGProps } from "react";
const SvgDropdownIcon = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="dropdownicon" className="anticon anticon-dropdownicon">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.59 9L12 14.58L6.41 9L5 10.41L12 17.41L19 10.41L17.59 9Z" />
    </svg>
    </span>
);
export default SvgDropdownIcon;
