import React, { useState } from 'react';
import { Popover, Menu } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
type ContextMenuProps = {
	onSelectAll?: (selected?: boolean) => void, 
	children?: React.ReactNode, 
	zIndex?: number, 
	open?: boolean,
	onClose?: () => void;
};
const items = [{ key: 'selectall', label: 'Выбрать все' }, { key: 'deselectall', label: 'Отменить все' }];

export const ContextMenu: React.FC<ContextMenuProps> = ({open, onClose, onSelectAll, children, zIndex=2000}) => {
	const onClick:MenuClickEventHandler = ({key, domEvent}) => {
		switch(key) {
			case 'selectall':
				onSelectAll?.(true);
				break;
			case 'deselectall':
				onSelectAll?.(false);
				break;
		}
		domEvent.preventDefault(); domEvent.stopPropagation();
		onClose?.();
	};

	return <Popover 
		content={<Menu items={items} mode='inline' onClick={onClick}/>}
		title="Контекстное меню" 
		open={open}
		placement='rightTop'
		zIndex={zIndex}
		// onOpenChange={(v) => {
		// 	if(!v) onClose?.();
		// }}
	>
		{children}
	</Popover>;
};