import React from "react";
import { Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Resettable } from './types';
import {Button} from '@gp-frontend-ui/ui-kit-5v2';
import styled from "styled-components";

const ButtonStyled = styled(Button)`
	svg {
		width: 16px;
		height: 16px;
	}		
`

export const Reset: React.FC<Resettable> = ({onReset, allowReset, children}) => {
	if(allowReset)
		return (
			<Flex align='center'>
				{children}
				{allowReset
					? <ButtonStyled
						context={"pagetitle"}
						onClick={onReset}
						icon={<CloseOutlined/>}
						style={{flex:'0 0 32px'}}
					></ButtonStyled>
					: null
				}
			</Flex>
		);
	else
		return <>{children}</>;
}
