import styled, { css } from "styled-components";
import { Spin } from "antd";
import type { GetProps } from "antd";

export const StyledSpin: typeof Spin = styled(Spin)`
	svg {
		animation-name: spin;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}

	.ant-spin-nested-loading & {
		svg {
			position: absolute;
			top: 50%;
			bottom: 50%;
			left: 50%;
			right: 50%;
			margin: auto;
		}
	}

	.ant-spin-fullscreen {
		z-index: 10000;
	}
`;
