import * as React from 'react';
import type { Reference } from 'rc-table';
import {Table as AntTable, TableProps as AntTableProps} from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import type { GetProps } from 'antd';
import { RefTable } from 'antd/es/table/interface';
import { SpanMax, StyledTable } from './styled';
import type { ColumnsType } from 'antd/lib/table/interface';
import type { ResizeCallbackData } from "react-resizable";
import { ResizableCell } from "./ResizableCell";
import { Tooltip } from '../Tooltip';
import { ColumnsWithMaxType, ColumnWithMax, EllipsisProps, MAX_LENGTH } from './components.types';
import { RenderedCell } from 'rc-table/lib/interface';
// import { useOutsideClick } from "./useClickOutside";

const Table = <RecordType extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<AntTableProps<RecordType>> & React.RefAttributes<Reference>
) => {
  const ellipsification = (columns:ColumnsWithMaxType<RecordType>|undefined) => {
    return columns?.map(column => 
      column.maxlen 
        ? {...column, render: (text:string, record:RecordType, index:number) => {
              let content = column.render!==undefined ? column.render(text,record,index) : text;
              if(typeof content === "string")
                return <Table.Ellipsis<RecordType> text={content} record={record} maxlen={column.maxlen} />;
              else
                return content;
            }
          } 
        : column
    );
  };
  // <SpanMax></SpanMax>
// <Tooltip title={text}>{text.substring(0, MAX_LENGTH)}</Tooltip> : text;

  const { columns: columnInit, components, locale, ...other } = props;
  const [columns, setColumns] = React.useState<ColumnsType>(ellipsification(columnInit) as ColumnsType);
  //const ref = useOutsideClick(handleClickOutside);


  
  const customComponents = React.useMemo(
    () => ({
      header: {
        cell: ResizableCell,
      },
      ...components,
    }),
    [components]
  );

  const handleResize = React.useCallback(
    (index: number) =>
      (e: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
        setColumns((prevColumns) => {
          const columnsT = [...(prevColumns as ColumnsType)];
          columnsT[index] = {
            ...columnsT[index],
            width: size.width,
          };
          return columnsT;
        });
      },
    []
  );
  const resizablecolumns = React.useMemo(
    () =>
      columns?.map((col, index) => ({
        ...col,
        onHeaderCell: () => ({
          width: col.width,
          onResize: handleResize(index),
        }),
      })),
    [columns, handleResize]
  );

  return (  
    <StyledTable
      components={customComponents}
      columns={resizablecolumns as unknown as ColumnsType<object>}
      pagination={false}
      locale={{
        triggerDesc: "Нажмите для сортировки по убыванию",
        triggerAsc: "Нажмите для сортировки по возрастанию",
        cancelSort: "Нажмите для отмены сортировки",
        emptyText: "Нет данных",
        ...locale,
      }}
      // onRow={onRow}

      {...other}  
    />  
  );  
};


Table.Ellipsis = function Ellipsis<T>({text, record, maxlen}: EllipsisProps<T>): JSX.Element {
  return maxlen&&text&&(typeof text == "string")&&text.length>maxlen ? <>{text.substring(0, maxlen)}<Tooltip title={text}><SpanMax></SpanMax></Tooltip></> : <>{text}</>;
};

export {Table};