import styled, { css } from "styled-components";
import { Menu } from "antd";
import type { GetProps } from "antd";
import { MenuComponentToken } from '../Theme/token.menu';
import { MenuProps } from '.';

const token = MenuComponentToken;

export const StyledMenu: typeof Menu = styled(Menu)`

	${({direction}) => 
	direction === "rtl" && 
		css`
			.ant-menu-submenu-title,
			.ant-menu-item.ant-menu-item-only-child {
				padding-inline-end: unset!important;
				padding-inline: ${typeof token.padding === 'number' ? `${token.padding}px` : token.padding}!important;
				padding-inline-start: calc(${typeof token.padding === 'number' ? `${token.padding}px` : token.padding} + 20px)!important;
			}

			.ant-menu-submenu-arrow {
				&::before, &::after {
					width: 0;
				}

				width: 20px;
				height: 20px;
				inset-inline-end: unset;
				inset-inline-start: ${typeof token.padding === 'number' ? `${token.padding}px` : token.padding};
			}
		}
	`}

	.ant-menu-submenu-arrow {
		&::before, &::after {
			width: 0;
		}
		
		display: inline-block;
		width: 20px;
		height: 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.375 9.19195C6.875 9.55108 6.875 10.4489 7.375 10.8081L11.875 14.8736C12.375 15.2327 13 14.7838 13 14.0655V5.93445C13 5.21618 12.375 4.76726 11.875 5.1264L7.375 9.19195Z' fill='%23717681'/%3E%3C/svg%3E%0A");
	}
`;
