//import { TableColumn } from '@components/gp-typings/lib/model/tableColumn';
import { reduce, find, forEach, keys, includes } from 'lodash';
//import { PropertyDto, ColumnSettings } from './types';
import { defaultPropertiesAndSettings } from './defaultPropertiesAndSettings';
import {ColumnSettings, PropertyDto} from "./utils_types";
import {TableColumn} from "./settings_types";


/**
 * ложим на старые сохраненные настройки актуальный набор пропертей
 * @param columns - старые сеттинги, полученные с сервера
 * @param properties - проперти из формы
 * @returns
 */
export function prepareSettings(columns, properties:PropertyDto[]): ColumnSettings[] {
    // удаляем устаревшие \ неактуальные
    const settings = reduce(columns, function(result, value) {
        if(find(properties, ['key', String(value.key)])) {
            result.push(value);
        }
        return result;
    }, [] as ColumnSettings[]);

    forEach(properties, function(p) {
        if(!find(settings, ['key', p.key])) {
            settings.push({
                key: p.key,
                type: p.type,
                name: p.name,
                visibility: true,
                width: 100,
            });
        }
    });

    return settings;
}

export function getDefaultsSettings(formKey: number|string|undefined, properties: PropertyDto[]|undefined): ColumnSettings[] {
    let defaultSettings: ColumnSettings[];

    const defKeys = keys(defaultPropertiesAndSettings);
    const foundKey = includes(defKeys, String(formKey));
    const defKey = defaultPropertiesAndSettings[foundKey&&formKey ? formKey : '0'];

    if(properties)
        defaultSettings = properties
            .map((pd,index) => {
                const visible = includes(defKey.defaults, String(pd.key));
                const required = includes(defKey.required, String(pd.key));
                return ({
                    key: String(pd.key),
                    type: pd.type,
                    name: pd.name,
                    width: 150,
                    sort: index,
                    visibility: visible,
                    lock: required ? TableColumn.LockModsEnum.ENABLED : false,
                } as ColumnSettings);
            });
    else
        defaultSettings = defKey.properties
            .map((pd,index) => ({
                key: String(pd.key),
                type: pd.type,
                name: pd.name,
                width: 150,
                sort: index,
                visibility: includes(defKey.defaults, String(pd.key)),
                lock: includes(defKey.required, String(pd.key)) ? TableColumn.LockModsEnum.ENABLED : false,
            } as ColumnSettings));

    return defaultSettings;
}
