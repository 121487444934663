import styled from "styled-components";
import { Pagination as AntPagination } from "antd";
import { PaginationProps } from './components.types';
import {Colors} from "../../colors";

export const StyledPagination = styled(AntPagination)<PaginationProps>`
    width: 100%;
    justify-content: flex-end;

    .ant-pagination-item {
        border-color: ${Colors.cloudy.main};
    }

    .ant-pagination-item-active {
        border-color: ${Colors.blue.main};
    }

    .ant-pagination-next,
    .ant-pagination-prev {
        
        border: 1px solid ${Colors.cloudy.main};
    }

    .ant-pagination-total-text {
        margin-right: auto;
    }

    .ant-pagination-options-quick-jumper > input:focus {
        box-shadow: none;
        caret-color: ${Colors.orange.main};
    }
`;
