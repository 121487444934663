import React, {useEffect, useState} from 'react';
import "./ui-kit/static/montserrat.css";

import {
    Button,
    Flex,
    Space,
    Switch,
    TreeSelect,
    Tabs,
    Tooltip,
    ConfigProvider, Divider
} from 'antd';
import {PlusOutlined, SyncOutlined, ClockCircleOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type {TableProps} from 'antd';

import {createStyles, useTheme} from 'antd-style';
import {Colors} from './colors';
import {ThemeConfig} from "antd/lib";
import {
    Radio,
    Input,
    Checkbox,
    DatePicker,
    InputNumber,
    Modal,
    Table,
    Tag,
    Select,
    ButtonNew,
    Pagination,
    Collapse,
    DepartmentTree,
} from "./ui-kit";
import {RangePicker, SelectWRC,} from "@gp-frontend-ui/ui-kit-5v2";
import { filter, map } from 'lodash';
import { Ellipsis } from './components/Ellipsis/ellipsis';
import { Example } from './components/Ellipsis/example';
import {Btn} from "./components/Btn";

const dateFormat = 'YYYY/MM/DD';
dayjs.extend(customParseFormat);

const {SHOW_PARENT} = TreeSelect;

const demoOptions = [{label: "Отдел особого делопроизводства", value: 80014714},
    {label: "Аппарат прокуратуры Воронежской области", value: 80014754},
    {label: "Борисоглебская межрайонная прокуратура Воронежской области", value: 80014774},
    {label: "Воронежская межрайонная природоохранная прокуратура", value: 80014794},
    {
        label: "Старший помощник прокурора Республики Крым по правовому обеспечению",
        value: 80014814
    },
    {
        label: "Отдел по надзору за исполнением законодательства о противодействии коррупции (на правах управления) прокуратуры города Севастополя",
        value: 80014834
    },
    {
        label: "Отдел правовой статистики и защиты информации Восточно-Сибирской транспортной прокуратуры",
        value: 80014854
    },
    {label: "Военная прокуратура Северного флота (Аппарат, 5-й отдел)", value: 80014874},
    {label: "107 военная прокуратура гарнизона, в/ч - п.п. 90935", value: 80014894},
    {
        label: "Управление правовой статистики прокуратуры Рязанской области",
        value: 80014914
    },
    {
        label: "Старший помощник и помощник Приволжского транспортного прокурора по надзору за исполнением законов о противодействии коррупции",
        value: 80014934
    },
    {label: "Биробиджанская межрайонная природоохранная прокуратура", value: 80014954},
    {label: "Братская межрайонная природоохранная прокуратура", value: 80014974},
    {
        label: "Старшие помощники и помощники прокурора Ставропольского края по надзору за законностью исполнения уголовных наказаний",
        value: 80014994
    },
    {label: "Отдел кадров прокуратуры Астраханской области", value: 80015014},
    {
        label: "Старший помощник прокурора Липецкой области по организационным вопросам, контролю исполнения, рассмотрению обращений и приему граждан",
        value: 80024864
    },
    {label: "Отдел по надзору за соблюдением прав и свобод граждан", value: 80014715},
    {
        label: "Отдел по надзору за процессуальной деятельностью в органах Следственного комитета Российской Федерации",
        value: 80014716
    },
    {label: "Отдел государственной статистики", value: 80014717},
    {
        label: "Организационно-контрольное управление прокуратуры города Москвы",
        value: 80014718
    },
];

const treeData = [
    {
        title: 'Node1',
        value: '0-0',
        key: '0-0',
        children: [
            {
                title: 'Child Node1',
                value: '0-0-0',
                key: '0-0-0',
            },
        ],
    },
    {
        title: 'Node2',
        value: '0-1',
        key: '0-1',
        children: [
            {
                title: 'Child Node3',
                value: '0-1-0',
                key: '0-1-0',
            },
            {
                title: 'Child Node4',
                value: '0-1-1',
                key: '0-1-1',
            },
            {
                title: 'Child Node5',
                value: '0-1-2',
                key: '0-1-2',
            },
        ],
    },
];

const tabItems = [
    {
        key: '1',
        label: 'Указание следования',
        children: 'Поправить жирность шрифта в названиях табов',
    },
    {
        key: '2',
        label: 'История изменений',
        children: 'Content of Tab Pane 2',
    },
    {
        key: '3',
        label: 'Разное',
        children: 'Content of Tab Pane 3',
    },
];

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, {tags}) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? Colors.tag.info : Colors.tag.success;
                    if (tag === 'loser') {
                        color = Colors.tag.marked;
                    }
                    return (<Tooltip title="prompt text">
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    </Tooltip>);
                })}
            </>
        ),
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <a>Invite {record.name}</a>
                <Button danger>Delete</Button>
            </Space>
        ),
    },
];

const data: DataType[] = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sydney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];

const useStyle = createStyles(({token}) => ({
    'my-modal-body': {
        background: token.colorPrimary,
        padding: token.paddingSM,
    },
    'my-modal-mask': {
        boxShadow: `inset 0 0 15px #f00`,
    },
    'my-modal-header': {
        borderBottom: `1px dotted ${token.colorPrimary} !important`,
        background: token.colorPrimary,
    },
    'my-modal-footer': {
        color: token.colorPrimary,
    },
    'my-modal-content': {
        border: '1px solid #0f0 !important',
    },
}));


function App() {
    const [theme, setTheme] = useState<ThemeConfig>({});

    const getThemeData = async () => {
        const response = await fetch(
            "theme.json"
        ).then((response) => response.json());

        // Обновим состояние
        setTheme(response as ThemeConfig);
    };

    useEffect(() => {
        getThemeData();
    }, []);

    const [value, setValue] = useState(['0-0-0']);
    const onChange = (newValue: string[]) => {
        console.log('onChange ', newValue);
        setValue(newValue);
    };
    const tProps = {
        treeData,
        value,
        onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
    };


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState<false | 'footer' | 'centered' | 'styled'>(false);

    const modalHandleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const modalHandleCancel = () => {
        setOpen(false);
    };

    const {styles} = useStyle();
    const token = useTheme();
    const modalClassNames = {
        header: styles['my-modal-header'],
        mask: styles['my-modal-mask'],
        body: styles['my-modal-body'],
        content: styles['my-modal-content'],
        footer: styles['my-modal-footer'],
    };

    const [val, setVal] = useState<any>();
    const [dval, setDVal] = useState<number>(0);
    const [sval, setSVal] = useState<number>();
    const [msval, setMSVal] = useState<number[]>([]);
    const [sOptions, setSOptions] = useState(demoOptions);

    const defaultDate = dayjs('2024/01/01', dateFormat);
    const defaultDate2 = [defaultDate, defaultDate.endOf('month')];
    const [dateval, setDateVal] = useState<Dayjs|undefined>(defaultDate);
    const [date2val, setDate2Val] = useState<[Dayjs|undefined, Dayjs|undefined]>(defaultDate2 as [Dayjs, Dayjs]);

    return (
        <ConfigProvider theme={theme}>
            <Space style={{padding: 40}}>

                <Space direction="vertical" size={20}>

                    <Space direction="vertical" size={20}>
                        <h3>BTN</h3>
                        <ConfigProvider theme={{
                            components: {
                                Button: {
                                    algorithm: true,
                                    contentFontSize: 14,
                                    fontWeight: 600,
                                    borderRadius: 2,
                                    primaryColor: "rgb(255,255,255)",
                                    primaryShadow: "rgba(255,255,255,0.02)",
                                    colorPrimaryBorder: "rgb(8,86,110)",
                                    borderRadiusLG: 2,
                                    borderRadiusSM: 2,
                                    defaultShadow: "0 2px 0 rgba(200, 0, 0, 0.02)",
                                    colorPrimary: "rgb(8,86,110)",
                                    colorLink: "rgb(8,86,110)",
                                    colorPrimaryHover: "rgb(1,63,84)",
                                    colorLinkHover: "rgb(1,63,84)",
                                    colorPrimaryActive: "rgb(2,55,73)",
                                    colorLinkActive: "rgb(2,55,73)",
                                    defaultColor: "rgb(128,163,192)",
                                    defaultBg: "rgba(255,255,255,0)",
                                    defaultBorderColor: "rgb(128,163,192)",
                                    defaultHoverColor: "rgb(8,86,110)",
                                    defaultHoverBorderColor: "rgb(8,86,110)",
                                    defaultActiveBg: "rgba(8,86,110,0.15)",
                                    defaultActiveBorderColor: "rgb(8,86,110)",
                                    defaultActiveColor: "rgb(8,86,110)",
                                    borderColorDisabled: "rgb(217, 217, 217)",
                                    colorTextDisabled: "rgb(217, 217, 217)"

                                }
                            }
                        }}>
                            <Btn type="default">Btn Default</Btn>
                            <Btn type="default" accent>Btn Default Accent</Btn>
                            <Btn type="primary">Btn Primary</Btn>
                            <Btn type="primary" accent>Btn Primary Accent</Btn>
                            <Btn danger>Btn Default Danger</Btn>
                            <Btn type="primary" danger>Btn Primary Danger</Btn>
                            <div style={{marginTop: 10}}>
                                <Btn color="primary" variant="link">+ Добавить запись</Btn>
                                <Btn color="primary" variant="link" accent>+ Добавить вложенную запись</Btn>
                            </div>
                            <div style={{marginTop: 10}}>
                                <Btn color="primary" variant="text">Btn Primary Text</Btn>
                                <Btn color="primary" variant="text" accent>Btn Accent Text</Btn>
                            </div>
                        </ConfigProvider>

                    </Space>


                    <Space direction="vertical" size={20}>
                        <h3>Подразделения</h3>
                        <DepartmentTree/>
                    </Space>

                    <Space direction="vertical" size={20}>
                        <h3>New Button</h3>
                        <Flex vertical gap="middle">
                            <ButtonNew view={"primary"}>ButtonNew</ButtonNew>
                            <Example />
                        </Flex>
                    </Space>
                    <h3>Button</h3>
                    <Flex vertical gap="middle">
                        <h4>Кнопки из Figma</h4>
                        <Flex gap="middle" wrap>
                            <h5>Size small</h5>
                            <Button type="primary" size={"small"}>Type Primary</Button>
                            <Button type="primary" size={"small"} disabled>Primary Disabled</Button>
                            <Button size={"small"}>Type Default</Button>
                            <Button size={"small"} disabled>Default Disabled</Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <h5>Size middle</h5>
                            <Button type="primary" size={"middle"}>Type Primary</Button>
                            <Button type="primary" size={"middle"} disabled>Primary Disabled</Button>
                            <Button size={"middle"}>Type Default</Button>
                            <Button size={"middle"} disabled>Default Disabled</Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <h5>Size large</h5>
                            <Button type="primary" size={"large"}>Type Primary</Button>
                            <Button type="primary" size={"large"} disabled>Primary Disabled</Button>
                            <Button size={"large"}>Type Default</Button>
                            <Button size={"large"} disabled>Default Disabled</Button>
                        </Flex>
                        <p>на кнопках три состояния - normal, hover, active</p>
                        <p>Оранжевые кнопки будут реализованы в ui-kit через переопределние стилей.</p>
                    </Flex>
                    <Divider/>
                    <Flex vertical gap="middle">
                        <Flex gap="middle" wrap>
                            <Button color="primary" size="large" variant="solid" icon={<PlusOutlined/>}></Button>
                            <Button color="default" size="large" variant="solid" icon={<PlusOutlined/>}></Button>
                            <Button color="primary" variant="solid" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="solid" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="outlined" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="dashed" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="filled" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="text" icon={<PlusOutlined/>}></Button>
                            <Button color="default" variant="link" icon={<PlusOutlined/>}></Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button color="primary" size="large" variant="solid"
                                    icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" size="large" variant="solid"
                                    icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" variant="outlined" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" variant="dashed" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" variant="filled" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" variant="text" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button color="default" variant="link" icon={<SyncOutlined/>}>Проверить</Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button type="primary" size="large"
                                    icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" size="large" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" variant="outlined" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" variant="dashed" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" variant="filled" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" variant="text" icon={<SyncOutlined/>}>Проверить</Button>
                            <Button type="default" variant="link" icon={<SyncOutlined/>}>Проверить</Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button color="primary" variant="solid" icon={<SyncOutlined/>} loading>Проверить</Button>
                            <Button color="primary" variant="outlined" icon={<SyncOutlined/>} loading>Проверить</Button>
                            <Button color="primary" variant="dashed" icon={<SyncOutlined/>} loading>Проверить</Button>
                            <Button color="primary" variant="filled" icon={<SyncOutlined/>} loading>Проверить</Button>
                            <Button color="primary" variant="text" icon={<SyncOutlined/>} loading>Проверить</Button>
                            <Button color="primary" variant="link" icon={<SyncOutlined/>} loading>Проверить</Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button color="default" variant="solid">
                                Solid
                            </Button>
                            <Button color="default" variant="outlined">
                                Outlined
                            </Button>
                            <Button color="default" variant="dashed">
                                Dashed
                            </Button>
                            <Button color="default" variant="filled">
                                Filled
                            </Button>
                            <Button color="default" variant="text">
                                Text
                            </Button>
                            <Button color="default" variant="link">
                                Link
                            </Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button color="primary" variant="solid">
                                Solid
                            </Button>
                            <Button color="primary" variant="outlined">
                                Outlined
                            </Button>
                            <Button color="primary" variant="dashed">
                                Dashed
                            </Button>
                            <Button color="primary" variant="filled">
                                Filled
                            </Button>
                            <Button color="primary" variant="text">
                                Text
                            </Button>
                            <Button color="primary" variant="link">
                                Link
                            </Button>
                        </Flex>
                        <Flex gap="middle" wrap>
                            <Button color="danger" variant="solid">
                                Solid
                            </Button>
                            <Button color="danger" variant="outlined">
                                Outlined
                            </Button>
                            <Button color="danger" variant="dashed">
                                Dashed
                            </Button>
                            <Button color="danger" variant="filled">
                                Filled
                            </Button>
                            <Button color="danger" variant="text">
                                Text
                            </Button>
                            <Button color="danger" variant="link">
                                Link
                            </Button>
                        </Flex>
                    </Flex>

                    <h3>Select</h3>
                    <Flex vertical gap={48}>
                        <Select
                            allowClear
                            style={{width: '100%', minWidth: 400}}
                            placeholder="Выберите что-нибудь"
                            defaultValue={80014714}
                            onSearch={(query) => {
                                if(query) {
                                    const found = filter(demoOptions,
                                        option => {
                                            const res = option.label.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim());
                                            return res;
                                        }
                                    );
                                    setSOptions(found);
                                }
                                else {
                                    setSOptions(demoOptions);
                                }
                            }}
                            options={sOptions}
                            value={sval}
                            onChange={setSVal}
                        />
                        <SelectWRC
                            mode="multiple"
                            searchable
                            onSearch={() => {}}
                            allowClear
                            style={{width: '100%', minWidth: 400}}
                            placeholder="Выберите что-нибудь"
                            defaultValue={undefined}
                            value={msval}
                            onChange={setMSVal}
                            allowReset
                            onReset={()=>setMSVal([80014854])}
                            options={demoOptions}
                            withContextMenu
                            onSelectAll={(all) => {
                                console.log('all');
                                setMSVal(all ? map(demoOptions, opt => opt.value):[]);
                            } }
                        />
                        <TreeSelect {...tProps} />
                    </Flex>

                    <h3>Input</h3>
                    <Flex vertical gap={32}>
                        <Input placeholder="Введите что-нибудь 40" showCount maxLength={20} onChange={console.log}
                               size="large"/>
                        <Input placeholder="Введите что-нибудь 32" showCount maxLength={20} onChange={(e) => setVal(e.target.value)}
                               size="middle" allowReset defaultValue={"пробуем"} value={val} onReset={()=>setVal("пробуем")}/>
                        <Input placeholder="Введите что-нибудь 24" showCount maxLength={20} onChange={console.log}
                               size="small"/>
                        <Input placeholder="disabled" showCount maxLength={20} onChange={console.log} disabled/>
                        <Input defaultValue="Value disabled" showCount maxLength={20} onChange={console.log} disabled/>
                        <InputNumber placeholder="Введите число" allowReset value={dval} onReset={()=>setDVal(99)} onChange={(value) => setDVal(value as any)} />
                        <InputNumber placeholder="Введите число" allowReset disabled/>
                        <TextArea showCount maxLength={100} onChange={console.log} placeholder="can resize"/>
                        <TextArea
                            showCount
                            maxLength={100}
                            onChange={console.log}
                            placeholder="disable resize"
                            style={{height: 120, resize: 'none'}}
                        />

                        <Input variant="filled" prefix={<ClockCircleOutlined/>} suffix={<ClockCircleOutlined/>}
                               value="Ошибка" size="large" status="error"/>
                        <Input variant="filled" prefix={<ClockCircleOutlined/>} suffix={<ClockCircleOutlined/>}
                               value="Warning" size="large" status="warning"/>
                    </Flex>

                    <h3>Datepicker</h3>
                    <Flex vertical gap={32}>
                        <DatePicker
                            value={dateval}
                            defaultValue={defaultDate}
                            format={dateFormat}
                            size="large"
                            allowReset
                            onReset={()=>setDateVal(defaultDate)}
                            onChange={setDateVal}
                        />

                        <RangePicker
                            value={date2val}
                            defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
                            format={dateFormat}
                            placement="topRight"
                            // allowReset
                            // onReset={()=>setDate2Val(defaultDate2)}
                            onChange={(dates) => setDate2Val(dates as [Dayjs, Dayjs])}
                        />
                        <DatePicker defaultValue={dayjs('2015/01/01', dateFormat)} format={dateFormat}/>
                        <DatePicker defaultValue={dayjs('2015/01/01', dateFormat)} format={dateFormat}/>
                    </Flex>

                    <h3>Radio / Check</h3>
                    <Flex gap={64}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={1}>Option A</Radio>
                                <Radio checked={true} value={2}>Option B</Radio>
                                <Radio value={3}>Option C</Radio>
                                <Radio value={4}>More...</Radio>
                            </Space>
                        </Radio.Group>
                        <Radio.Group
                            options={[
                                {value: "cards", label: "cards"},
                                {value: "files", label: "files"},
                            ]}
                            direction="vertical"
                            //color="orange"
                            //styling="2024"
                        />
                        <Space direction="vertical">
                            <Checkbox defaultChecked={false}>Option A</Checkbox>
                            <Checkbox indeterminate={true}>Option B</Checkbox>
                            <Checkbox defaultChecked>Option C</Checkbox>
                            <Checkbox defaultChecked={false} disabled>Option A2</Checkbox>
                            <Checkbox indeterminate disabled>Option B2</Checkbox>
                            <Checkbox defaultChecked disabled>Option C2</Checkbox>
                        </Space>

                        <Checkbox.Group
                            options={[
                                {label: 'Apple', value: 'Apple'},
                                {label: 'Pear', value: 'Pear'},
                                {label: 'Orange', value: 'Orange'},
                            ]}
                            defaultValue={['Apple']}
                        />

                        <Space direction="vertical">
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked/>
                            <Switch checkedChildren="1" unCheckedChildren="0"/>
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                                defaultChecked
                            />
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked disabled/>
                            <Switch checkedChildren="1" unCheckedChildren="0" disabled/>
                        </Space>
                    </Flex>


                    <h3>Modal</h3>
                    <Flex gap={64}>
                        <Button type="primary" onClick={() => setOpen('footer')}>
                            Modal with footer
                        </Button>
                        <Button type="default" onClick={() => setOpen('centered')}>
                            centered
                        </Button>
                        <Button type="text" onClick={() => setOpen('styled')}>
                            styled
                        </Button>
                    </Flex>

                    <Modal
                        open={!!open}
                        enableFullScreen={true}
                        title="Title"
                        onOk={modalHandleOk}
                        onCancel={modalHandleCancel}
                        footer={[
                            <Button key="back" type="default" onClick={modalHandleCancel}>
                                Return
                            </Button>,
                            <Button key="submit" type="primary" loading={loading} onClick={modalHandleOk}>
                                Submit
                            </Button>,
                            <Button
                                key="link"
                                href="https://google.com"
                                type="primary"
                                loading={loading}
                                onClick={modalHandleOk}
                            >
                                Search on Google
                            </Button>,
                        ]}
                        centered={open === 'centered'}
                        classNames={open === 'styled' ? modalClassNames : undefined}
                    >
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                    </Modal>

                    <h3>Tabs</h3>
                    <Flex gap={64}>
                        <Tabs defaultActiveKey="1" items={tabItems} onChange={console.log}/>
                    </Flex>

                    {/* <h3>Table</h3>
                    <Table columns={columns} dataSource={data}/>
                    <Pagination showQuickJumper defaultCurrent={6} total={500} />
 */}
                    <h3>Collapse</h3>
                    <Space direction="vertical">
                        <Collapse
                            collapsible="header"
                            ghost
                            defaultActiveKey={['1']}
                            items={[
                                {
                                    key: '1',
                                    label: 'This panel can only be collapsed by clicking text',
                                    children: <p>Some contents...</p>,
                                },
                            ]}
                        />
                        <Collapse
                            collapsible="icon"
                            defaultActiveKey={['1']}
                            items={[
                                {
                                    key: '1',
                                    label: 'This panel can only be collapsed by clicking icon',
                                    children: <p>Some contents...</p>,
                                },
                            ]}
                        />
                        <Collapse
                            collapsible="disabled"
                            items={[
                                {
                                    key: '1',
                                    label: "This panel can't be collapsed",
                                    children: <p>Some contents...</p>,
                                },
                            ]}
                        />
                    </Space>
                </Space>
            </Space>
        </ConfigProvider>
    );
}

export default App;
