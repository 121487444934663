import * as React from "react";
import { SVGProps } from "react";
const SvgTableGraph = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="tablegraph" className="anticon anticon-tablegraph">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 5H13V9H20V14H13V19H4V14H11V10H4V5ZM11 8V7H6V8H11ZM18 12V11H13V12H18ZM11 16V17H6V16H11Z" />
    </svg>
    </span>
);
export default SvgTableGraph;
