import * as React from "react";
import { SVGProps } from "react";
const SvgXls = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="xls" className="anticon anticon-xls">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1739 17.2174H10.2174V12.6957H17.1739V9.21739H13V5.04348H6.04348V18.9565H17.1739V17.2174ZM18.2174 12.6957H19.6087V17.2174H18.2174V20H5V4H13.6957L18.2174 8.52174V12.6957ZM15.3061 16.1739L14.5792 15.2243L15.2748 14.3165H14.8052L14.3496 14.9252L13.8939 14.3165H13.4105L14.1026 15.2313L13.3792 16.1739H13.8592L14.3392 15.5304L14.8157 16.1739H15.3061ZM15.6261 16.1739H16.0609V13.593H15.6261V16.1739ZM17.2052 16.1983C17.727 16.1983 18.047 15.9722 18.047 15.6243C18.047 14.8974 16.8992 15.2313 16.8992 14.8557C16.8992 14.7339 17.0244 14.647 17.2887 14.647C17.4661 14.647 17.6435 14.6817 17.8209 14.7861L17.9879 14.4557C17.8209 14.3548 17.5392 14.2957 17.2922 14.2957C16.7913 14.2957 16.4748 14.5252 16.4748 14.8765C16.4748 15.6174 17.6226 15.2835 17.6226 15.6383C17.6226 15.767 17.5079 15.8435 17.2331 15.8435C17 15.8435 16.7496 15.767 16.5861 15.6591L16.4192 15.9896C16.5861 16.1078 16.8957 16.1983 17.2052 16.1983Z" fill="#00BED4"/>
    </svg>
    </span>
);
export default SvgXls;
