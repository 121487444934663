import React, {forwardRef, useMemo} from "react";
import useTheme from "antd/lib/config-provider/hooks/useTheme";
import {ConfigProvider} from "antd";

import {BtnProps} from "./components.types";
import {StyledBtn} from "./styled";
import {Tooltip} from "@gp-frontend-ui/ui-kit-5v2";

/**
 * Компонент кнопки <Btn>
 * Дополнительный параметр accent - меняет цвета типов  primary и default на оранжевый
 * (ex. <Btn type="default" accent> или <Btn type="primary" accent>)
 *
 * Для варианта link параметр accent работает с цветом primary
 * (<Btn color="primary" variant="link" accent>)
 *
 * Для варианта text параметр accent работает с цветом primary
 * (<Btn color="primary" variant="text" accent>)
 *
 * Для кнопки ghost параметр accent работает с типом primary
 * (<Btn type="primary" ghost accent>)
 */
export const Btn = forwardRef<HTMLButtonElement | HTMLAnchorElement, BtnProps>((props, ref) => {
    const theme = useTheme();

    const {
        children,
        className,
        accent,
        context,
        title,
        active,
        open,
        ...args
    } = props;

    const accentTheme = useMemo(() => {
        if (accent) {
            return {
                ...theme, components: {
                    Button: {
                        colorPrimary: "rgb(255,102,83)",
                        colorLink: "rgb(255,102,83)",
                        colorPrimaryHover: "rgb(255,124,69)",
                        colorLinkHover: "rgb(255,124,69)",
                        colorPrimaryActive: "rgb(255,78,3)",
                        colorLinkActive: "rgb(255,78,3)",
                        defaultColor: "rgb(255,102,83)",
                        defaultBg: "rgba(255,255,255,0)",
                        defaultBorderColor: "rgb(255,102,83)",
                        defaultHoverColor: "rgb(255,78,3)",
                        defaultHoverBorderColor: "rgb(255,78,3)",
                        defaultActiveBg: "rgba(255,78,3,0.15)",
                        defaultActiveBorderColor: "rgb(255,78,3)",
                        defaultActiveColor: "rgb(255,78,3)",
                    }
                }
            };
        }
        return theme;

    }, [accent, theme]);

    const buttonContent = useMemo(() =>

            <StyledBtn
                ref={ref}
                className={
                    (className ?? '') + ' '
                    + (context ?? '') + ' '
                    + (active ? 'active' : '') + ' '
                    + (accent ? 'accent' : '') + ' '
                    + (open ? 'open' : '')+ ' '
                }
                active={active ? 'true' : undefined}
                {...args}
            >
                <div style={{
                    textDecoration: args.variant === 'link' ? 'underline' : 'none',
                    fontWeight: args.variant === 'link' ? 700 : 600,
                    textUnderlineOffset: 2,
                    fontSize: args.variant === 'link' ? 12 : 14,
                }}>{children}</div>
            </StyledBtn>

, [ref, className, context, active, accent, open, args, children]);


    return (
        <ConfigProvider theme={accentTheme}>
            {title
                ? <Tooltip title={title}
                           placement={context === 'sidebar' ? 'rightBottom' : 'top'}
                >
                    {buttonContent}
                </Tooltip>
                : buttonContent
            }
        </ConfigProvider>
    );
});
