import * as React from "react";
import { SVGProps } from "react";
const SvgView = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="view" className="anticon anticon-view">
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M13.3334 2H6.00008L2.66675 5.33333V14H6.66675V12.6667H4.00008V6L6.66675 3.33333H12.0001V8.66667H13.3334V2Z" />
<rect x="6" y="2" width="1.33333" height="4.66667" />
<rect x="2.66675" y="5.33334" width="4" height="1.33333" />
<rect x="8" y="11.3333" width="4" height="1.33333" />
<path d="M10.3906 10.6667L11.3334 9.72385L13.6096 12L11.2707 14.274L10.3927 13.2706L11.7801 12.0567L10.3906 10.6667Z" />
    </svg>
</span>);
export default SvgView;
