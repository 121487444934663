import * as React from "react";
import { SVGProps } from "react";
const SvgTransfer = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="transfer" className="anticon anticon-transfer"> 
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12Z" fill="#15BE4D"/>
<rect x="11.2688" y="16.4851" width="2" height="6" transform="rotate(-135.354 11.2688 16.4851)" fill="#15BE4D"/>
<rect x="12.6567" y="12.2338" width="4" height="2" transform="rotate(-135.354 12.6567 12.2338)" fill="#15BE4D"/>
    </svg>
    </span>
);
export default SvgTransfer;