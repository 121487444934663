import * as React from "react";
import { SVGProps } from "react";
const SvgDelayed = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="delayed" className="anticon anticon-delayed">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M11.975 22C17.512 22 22 17.523 22 12S17.512 2 11.975 2C6.777 2 2.503 5.947 2 11h2.018a8.014 8.014 0 0 1 7.957-7c4.43 0 8.02 3.582 8.02 8s-3.59 8-8.02 8h-.027v2h.027Z" />
    <path d="M11 6h2v8h-2z" />
    <path clipRule="evenodd" d="M5 13h2v6.222l1.52-1.445L10 19.185 7.04 22l-.045-.042h-1.99L4.961 22 2 19.185l1.48-1.408L5 19.222V13Z" />
    <path d="M13 12h4v2h-4z" />
    </svg>
    </span>
);
export default SvgDelayed;