import React, { Key, ReactNode } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { get, indexOf, without } from "lodash";

import type { ColumnType } from "antd/lib/table";
import type {
  RenderExpandIconProps,
  TriggerEventHandler,
} from "rc-table/lib/interface";
import { Checkbox } from '../Checkbox';

export const data = [
  {
    id: 1,
    key: 1,
    numberIKUD: "1.2.123.999",
    formType: "Форма 13",
    created: "21.01.2007",
    children: [
      {
        id: 11,
        key: 11,
        numberIKUD: "1.2.123.888",
        formType: "Форма 14",
        created: "21.01.2006",
        children: [
          {
            id: 111,
            key: 111,
            numberIKUD: "1.2.123.888",
            formType: "Форма 14",
            created: "21.01.2006",
          },
          {
            id: 112,
            key: 112,
            numberIKUD: "1.2.123.777",
            formType: "Форма 15",
            created: "21.01.2005",
          },
        ],
      },
      {
        id: 12,
        key: 12,
        numberIKUD: "1.2.123.777",
        formType: "Форма 15",
        created: "21.01.2005",
      },
    ],
  },
  {
    id: 2,
    key: 2,
    numberIKUD: "1.2.123.888",
    formType: "Форма 14",
    created: "21.01.2006",
  },
  {
    id: 3,
    key: 3,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
  {
    id: 4,
    key: 4,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
  {
    id: 5,
    key: 5,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
  {
    id: 6,
    key: 6,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
  {
    id: 7,
    key: 7,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
  {
    id: 8,
    key: 8,
    numberIKUD: "1.2.123.777",
    formType: "Форма 15",
    created: "21.01.2005",
  },
];


export const sortDirections = [
  "ascend" as const,
  "descend" as const,
  "ascend" as const,
];

export type expandablePresetType<RecordType> = (
  onExpand?: TriggerEventHandler<RecordType>
) => {
  expandIcon: (props: RenderExpandIconProps<RecordType>) => React.ReactNode;
};

export const expandablePreset: expandablePresetType<any> = (onExpand) => ({
  expandIcon: ({ expanded, onExpand: defaultOnExpand, record }) =>
    record.children ? (
      <CaretRightOutlined
        onClick={(e) => {
          onExpand && onExpand(record, e);
          return defaultOnExpand(record, e);
        }}
        rotate={expanded ? 90 : 0}
      />
    ) : (
      ""
    ),
});


let selectedRowKeys: Key[] = [];
function selectAll(onoff: boolean) {
  if(onoff)
    selectedRowKeys = data.map(d => d.key);
  else
    selectedRowKeys = [];
}
export const rowSelectionPreset/* : TableRowSelection<DataType> */ = {
  type: "checkbox" as const,
  checkStrictly: false,
  selectedRowKeys: selectedRowKeys,
  renderCell: (checked: boolean, record: any, index: number, originNode: ReactNode) => {
    return <Checkbox
      key={record.key}
      checked={indexOf(selectedRowKeys, record.key) !== -1}
      onChange={(event) => {
        if(event.target.value)
          selectedRowKeys = [...selectedRowKeys, record.key];
        else
          without(selectedRowKeys, record.key);
      }}
    />;
  },
  columnTitle: <Checkbox checked={true}  onChange={(event)=>{selectAll(event.target.value)}}/>
};

const renderCell = (item: any) => (text: string, record: any) => {
  if (!!item.render) return item.render(text, record);

  return get(record, item.id, text);
};

export type cellAddonsType<T> = {
  [key: string]: ColumnType<T>;
};

interface customColumns<T> extends ColumnType<T> {
  id?: string;
}

export const processColumns = <T,>(
  columns: customColumns<T>[],
  cellAddons: cellAddonsType<T> = {}
) => {
  return columns.map((item) => {
    return {
      ...item,
      render: renderCell(item),
      width: 110,
      ellipsis: true,
      ...cellAddons[item.id || (item.dataIndex as string) || ""],
    };
  });
};
