import {Checkbox as AntCheckbox} from "antd";
import {CheckboxGroupProps, CheckboxProps} from './components.types';
import {StyledCheckboxGroup} from './styled';

function Checkbox({...arg}: CheckboxProps) {
    return (
        <AntCheckbox
            {...arg}
        />
    );
};

Checkbox.Group = function CheckboxGroup({...arg}: CheckboxGroupProps) {
    return (
        <StyledCheckboxGroup
            {...arg}
        />
    );
};


export {Checkbox};
