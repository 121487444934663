import * as React from "react";
import { SVGProps } from "react";
const SvgScd = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="scd" className="anticon anticon-scd">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1739 17.2174H10.2174V12.6957H17.1739V9.21739H13V5.04348H6.04348V18.9565H17.1739V17.2174ZM18.2174 12.6957H19.6087V17.2174H18.2174V20H5V4H13.6957L18.2174 8.52174V12.6957Z" fill="#80A3C0"/>
    <path d="M16.9858 16.1983C16.8072 16.1983 16.6461 16.1588 16.5023 16.08C16.3608 15.9988 16.2495 15.8875 16.1684 15.7461C16.0872 15.6046 16.0466 15.4377 16.0466 15.2452C16.0466 15.0528 16.0872 14.8858 16.1684 14.7443C16.2495 14.6029 16.3608 14.4928 16.5023 14.4139C16.6461 14.3351 16.8072 14.2957 16.9858 14.2957C17.1411 14.2957 17.2803 14.3304 17.4032 14.4C17.5261 14.4672 17.6234 14.5704 17.6953 14.7096C17.7672 14.8487 17.8032 15.0272 17.8032 15.2452C17.8032 15.4609 17.7684 15.6394 17.6988 15.7809C17.6292 15.92 17.533 16.0243 17.4101 16.0939C17.2872 16.1635 17.1458 16.1983 16.9858 16.1983ZM17.0379 15.8261C17.1423 15.8261 17.235 15.8029 17.3162 15.7565C17.3997 15.7101 17.4658 15.6429 17.5145 15.5548C17.5655 15.4667 17.591 15.3635 17.591 15.2452C17.591 15.1246 17.5655 15.0215 17.5145 14.9357C17.4658 14.8475 17.3997 14.7803 17.3162 14.7339C17.235 14.6875 17.1423 14.6643 17.0379 14.6643C16.9336 14.6643 16.8397 14.6875 16.7562 14.7339C16.675 14.7803 16.6089 14.8475 16.5579 14.9357C16.5092 15.0215 16.4849 15.1246 16.4849 15.2452C16.4849 15.3635 16.5092 15.4667 16.5579 15.5548C16.6089 15.6429 16.675 15.7101 16.7562 15.7565C16.8397 15.8029 16.9336 15.8261 17.0379 15.8261ZM17.6014 16.1739V15.7357L17.6188 15.2417L17.584 14.7478V13.593H18.0153V16.1739H17.6014Z" />
    <path d="M15.058 16.1983C14.8632 16.1983 14.6893 16.1577 14.5362 16.0765C14.3855 15.993 14.2672 15.8794 14.1814 15.7357C14.0956 15.5919 14.0527 15.4284 14.0527 15.2452C14.0527 15.0597 14.0956 14.8962 14.1814 14.7548C14.2672 14.611 14.3855 14.4986 14.5362 14.4174C14.6893 14.3362 14.8632 14.2957 15.058 14.2957C15.2388 14.2957 15.3977 14.3328 15.5345 14.407C15.6736 14.4788 15.7791 14.5855 15.851 14.727L15.5171 14.9217C15.4614 14.8336 15.393 14.7687 15.3119 14.727C15.233 14.6852 15.1472 14.6643 15.0545 14.6643C14.9478 14.6643 14.8516 14.6875 14.7658 14.7339C14.68 14.7803 14.6127 14.8475 14.564 14.9357C14.5153 15.0214 14.491 15.1246 14.491 15.2452C14.491 15.3658 14.5153 15.4701 14.564 15.5583C14.6127 15.6441 14.68 15.7101 14.7658 15.7565C14.8516 15.8029 14.9478 15.8261 15.0545 15.8261C15.1472 15.8261 15.233 15.8052 15.3119 15.7635C15.393 15.7217 15.4614 15.6568 15.5171 15.5687L15.851 15.7635C15.7791 15.9026 15.6736 16.0104 15.5345 16.087C15.3977 16.1612 15.2388 16.1983 15.058 16.1983Z" fill="white"/>
    <path d="M12.9895 16.1983C12.8341 16.1983 12.6845 16.1786 12.5408 16.1391C12.3993 16.0974 12.2868 16.0475 12.2034 15.9896L12.3703 15.6591C12.4538 15.7125 12.5535 15.7565 12.6695 15.7913C12.7854 15.8261 12.9013 15.8435 13.0173 15.8435C13.1541 15.8435 13.2526 15.8249 13.3129 15.7878C13.3755 15.7507 13.4068 15.7009 13.4068 15.6383C13.4068 15.5872 13.386 15.549 13.3442 15.5235C13.3025 15.4957 13.248 15.4748 13.1808 15.4609C13.1135 15.447 13.0382 15.4342 12.9547 15.4226C12.8735 15.411 12.7912 15.3959 12.7077 15.3774C12.6266 15.3565 12.5524 15.3275 12.4851 15.2904C12.4179 15.251 12.3634 15.1988 12.3216 15.1339C12.2799 15.069 12.259 14.9832 12.259 14.8765C12.259 14.7583 12.2926 14.6562 12.3599 14.5704C12.4271 14.4823 12.5211 14.4151 12.6416 14.3687C12.7645 14.32 12.9095 14.2957 13.0764 14.2957C13.2016 14.2957 13.328 14.3096 13.4555 14.3374C13.5831 14.3652 13.6886 14.4046 13.7721 14.4557L13.6051 14.7861C13.517 14.7328 13.4277 14.6968 13.3373 14.6783C13.2492 14.6574 13.1611 14.647 13.0729 14.647C12.9408 14.647 12.8422 14.6667 12.7773 14.7061C12.7147 14.7455 12.6834 14.7954 12.6834 14.8557C12.6834 14.9113 12.7042 14.953 12.746 14.9809C12.7877 15.0087 12.8422 15.0307 12.9095 15.047C12.9767 15.0632 13.0509 15.0771 13.1321 15.0887C13.2155 15.098 13.2979 15.113 13.379 15.1339C13.4602 15.1548 13.5344 15.1838 13.6016 15.2209C13.6712 15.2557 13.7268 15.3055 13.7686 15.3704C13.8103 15.4354 13.8312 15.52 13.8312 15.6243C13.8312 15.7403 13.7964 15.8412 13.7268 15.927C13.6596 16.0128 13.5634 16.08 13.4382 16.1287C13.3129 16.1751 13.1634 16.1983 12.9895 16.1983Z" fill="white"/>
  </svg>
    </span>
);
export default SvgScd;
