import React from "react";

import { SpanStyled, PStyled } from "./styled";

import type { TextProps } from "./components.types";

export const Text: React.FC<TextProps> = ({
  tag = "span",
  children,
  size,
  weight = "regular",
}) => {
  switch (tag) {
    case "span":
      return (
        <SpanStyled size={size} $weight={weight}>
          {children}
        </SpanStyled>
      );
    case "p":
      return (
        <PStyled size={size} $weight={weight}>
          {children}
        </PStyled>
      );
  }
};
