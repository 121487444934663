import React from 'react';

const Form2 = () => {
    return (
        <div className={"page-wrap"}>
            Form 2
        </div>
    );
};

export default Form2;
