import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const CheckboxComponentToken = {
    '2022': {
        // Container background color, e.g: default button, input box, etc. Be sure not to confuse this with `colorBgElevated`.	string	#ffffff
        colorBgContainer: Colors.mainWhite,
        // Control the background color of container in disabled state.	string	rgba(0, 0, 0, 0.04)
        colorBgContainerDisabled: Colors.mainWhite,
        // Default border color, used to separate different elements, such as: form separator, card separator, etc.	string	#d9d9d9
        colorBorder: Colors.mainDarkBlue,
        /** Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. 
        * After you have selected the brand color, we will automatically generate a complete color palette and 
        * assign it effective design semantics.	string	#1677ff
        */ 
        colorPrimary: Colors.mainDarkBlue,	
        // The stroke color under the main color gradient, used on the stroke of components such as Slider.	string	#91caff
        colorPrimaryBorder: Colors.mainDarkBlue,
        // Hover state under the main color gradient.	string	#4096ff
        colorPrimaryHover: Colors.mainDarkBlue,
        // Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string	rgba(0, 0, 0, 0.88)
        colorText: Colors.mainBlack,
        // Control the color of text in disabled state.	string	rgba(0, 0, 0, 0.25)
        colorTextDisabled: Colors.mainLighterBlue,
        // Pure white color don't changed by theme	string	#fff
        colorWhite: Colors.mainWhite,
        // SM size border radius, used in small size components, such as Button, Input, Select and other input components in small size	number	4
        borderRadiusSM: 0,
        borderRadius: 0,
        borderRadiusLG: 0,
        // Control the interactive size of control component.	number	16
        controlInteractiveSize: 16,
        // fontFamily	// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string	-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
        // fontSize	// The most widely used font size in the design system, from which the text gradient will be derived.	number	14
        // fontSizeLG	// Large font size	number	16
        // lineHeight	// Line height of text.	number	1.5714285714285714
        // Border style of base components	string	solid
        lineType: 'solid',
        // Border width of base components	number	1
        lineWidth: 2,
        // The default line width of the outline class components, such as Button, Input, Select, etc.	number	2
        lineWidthBold: 2,
        // Control the width of the line when the component is in focus state.	number	4
        lineWidthFocus: 2,
        // Control the margin of an element, with a small size.	number	8
        marginXS: 12,
        // motionDurationFast	// Motion speed, fast speed. Used for small element animation interaction.	string	0.1s
        // motionDurationMid	// Motion speed, medium speed. Used for medium element animation interaction.	string	0.2s
        // motionDurationSlow	// Motion speed, slow speed. Used for large element animation interaction.	string	0.3s
        // motionEaseInBack	// Preset motion curve.	string	cubic-bezier(0.71, -0.46, 0.88, 0.6)
        // motionEaseOutBack	// Preset motion curve.	string	cubic-bezier(0.12, 0.4, 0.29, 1.46)

        // Control the extra small padding of the element.	number	8
        paddingXS: 12,	
    },
    '2024': {
        // Container background color, e.g: default button, input box, etc. Be sure not to confuse this with `colorBgElevated`.	string	#ffffff
        colorBgContainer: Colors.mainWhite,
        // Control the background color of container in disabled state.	string	rgba(0, 0, 0, 0.04)
        colorBgContainerDisabled: Colors.lightGrey,
        // Default border color, used to separate different elements, such as: form separator, card separator, etc.	string	#d9d9d9
        colorBorder: Colors.mainOrange,
        /** Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. 
        * After you have selected the brand color, we will automatically generate a complete color palette and 
        * assign it effective design semantics.	string	#1677ff
        */ 
        colorPrimary: Colors.mainOrange,	
        // The stroke color under the main color gradient, used on the stroke of components such as Slider.	string	#91caff
        colorPrimaryBorder: Colors.mainOrange,
        // Hover state under the main color gradient.	string	#4096ff
        colorPrimaryHover: Colors.mainOrange,
        // Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string	rgba(0, 0, 0, 0.88)
        colorText: Colors.mainBlack,
        // Control the color of text in disabled state.	string	rgba(0, 0, 0, 0.25)
        colorTextDisabled: Colors.grey,
        // Pure white color don't changed by theme	string	#fff
        colorWhite: Colors.mainWhite,
        // SM size border radius, used in small size components, such as Button, Input, Select and other input components in small size	number	4
        borderRadiusSM: 0,
        borderRadius: 0,
        borderRadiusLG: 0,
        // Control the interactive size of control component.	number	16
        controlInteractiveSize: 16,
        // fontFamily	// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string	-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
        // fontSize	// The most widely used font size in the design system, from which the text gradient will be derived.	number	14
        // fontSizeLG	// Large font size	number	16
        // lineHeight	// Line height of text.	number	1.5714285714285714
        // Border style of base components	string	solid
        lineType: 'solid',
        // Border width of base components	number	1
        lineWidth: 1,
        // The default line width of the outline class components, such as Button, Input, Select, etc.	number	2
        lineWidthBold: 2,
        // Control the width of the line when the component is in focus state.	number	4
        lineWidthFocus: 2,
        // Control the margin of an element, with a small size.	number	8
        marginXS: 12,
        // motionDurationFast	// Motion speed, fast speed. Used for small element animation interaction.	string	0.1s
        // motionDurationMid	// Motion speed, medium speed. Used for medium element animation interaction.	string	0.2s
        // motionDurationSlow	// Motion speed, slow speed. Used for large element animation interaction.	string	0.3s
        // motionEaseInBack	// Preset motion curve.	string	cubic-bezier(0.71, -0.46, 0.88, 0.6)
        // motionEaseOutBack	// Preset motion curve.	string	cubic-bezier(0.12, 0.4, 0.29, 1.46)

        // Control the extra small padding of the element.	number	8
        paddingXS: 12,	
    },
};