import * as React from "react";
import { SVGProps } from "react";
const SvgHidden = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="hidden" className="anticon anticon-hidden"> 
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9072 7.41421L15.4929 6L12.6645 8.82843L9.83609 6L8.42188 7.41421L11.2503 10.2426L8.42188 13.0711L9.83609 14.4853L12.6645 11.6569L15.4929 14.4853L16.9072 13.0711L14.0787 10.2426L16.9072 7.41421Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6713 12.4952C15.0502 17.4918 9.62111 17.4918 4 12.4952L2.67127 13.99C9.05016 19.6601 15.6211 19.6601 22 13.99L20.6713 12.4952Z" />
</svg>
    </span>
);
export default SvgHidden;
