//import { TableColumn } from '@components/gp-typings/lib/model/tableColumn';
//import { UUID } from '@entities/Operation';
import {SortOrder} from "antd/es/table/interface";

export type TableColumnField = {
    /**
     * Поле
     */
    field?: string;
    /**
     * Тип поля
     */
    type?: TableColumnField.TypeEnum;
    /**
     * Наименование поля
     */
    fieldName?: string;
}
export namespace TableColumnField {
    export type TypeEnum = 'string' | 'date' | 'datetime' | 'integer' | 'boolean';
    export const TypeEnum = {
        String: 'string' as TypeEnum,
        Date: 'date' as TypeEnum,
        Datetime: 'datetime' as TypeEnum,
        Integer: 'integer' as TypeEnum,
        Boolean: 'boolean' as TypeEnum
    };
}

export type TableColumn = {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Наименование
     */
    name?: string;
    /**
     * Флаг включенности
     */
    enabled?: boolean;
    /**
     * Модификации блокировки столбца
     */
    lockMods?: TableColumn.LockModsEnum[];
    /**
     * Порядковый номер столбца
     */
    order?: number;
    /**
     * Тип значения
     */
    type?: TableColumn.TypeEnum;
    /**
     * Поля
     */
    fields?: TableColumnField[];
}
export namespace TableColumn {
    export type LockModsEnum = 'ENABLED';
    export const LockModsEnum = {
        ENABLED: 'ENABLED' as LockModsEnum
    };
    export type TypeEnum = 'string' | 'date' | 'datetime' | 'integer' | 'boolean';
    export const TypeEnum = {
        String: 'string' as TypeEnum,
        Date: 'date' as TypeEnum,
        Datetime: 'datetime' as TypeEnum,
        Integer: 'integer' as TypeEnum,
        Boolean: 'boolean' as TypeEnum
    };
}


export type CardPropertyType = 'string'|'date'|'time'|'date-time'|'decimal'|'number'|'integer'|'bool'|'reference'|'gar-fias'|'object'|'array'|'scalar-array'|'dict'|undefined;

export type PropertyDto = {
    key: string; // Идентификатор (ключ) столбца
    num?: string; // код-номер реквизита
    name: string; // Название столбца
    type?: CardPropertyType; // Тип столбца
    dict_key?: string; // Идентификатор (ключ) справочника
    ord?: number; // порядок
    width?: number; // ширина
    lock?: TableColumn.LockModsEnum|false; // запрещено отключать
    data?: any;
    req_code?: string;

    facet?: boolean;
};

export type ColumnSettings = {
    key: string;
    type?: CardPropertyType;
    name: string;
    visibility: boolean;
    width?: number;
    sort?: number;
    lock?: TableColumn.LockModsEnum|false;
    direction?: string;
    sortOrder?: SortOrder;
};

/**
 * Сохраненные прошлые пользовательские настройки с которых начинается новая сессия
 */
export type LocalSettings = {
    units: number[] | undefined;
    activities: number[] | undefined;
    forms: number[] | undefined;
};

/**
 * Направление деятельности.
 * Собирает учетные формы
 */
export type Activity = {
    id: number;
    key: string;
    name: string;
    forms: Form[];
};

/**
 * Учетная форма.
 */
export type Form = {
    key: number;
    title: string;
    short: string;
    // доступыне колонки для каждой учетной формы
    properties: PropertyDto[] | undefined;
};




export type Template = {
    id: number;
    key_doc: number|string;
    title: string;
    default?: boolean;
};

export type SettingsTemplate = {
    columns: ColumnSettings[];
} & Template;



/* DTOs */

/** Статус операции */
export type ApiResponseListSettingsTemplateDTO = {
    status:	'OK'|'ERROR';
    /** Данные ответа */
    response_data: SettingsTemplateDTO[];
}

export type ApiResponseSettingsTemplateDTO = {
    status:	'OK'|'ERROR';
    /** Данные ответа */
    response_data: SettingsTemplateDTO;
}

export type SettingsTemplateDTO = {
    /** ID */
    id: number;

    /** Название */
    title: string;

    /** Ключ документа */
    key_doc: string;

    /** Настройки */
    data: string;
};

export type ApiResponseObject = {
    status:	'OK'|'ERROR';
    /** Данные ответа */
    response_data: never;
}

// export type UrpActivitiesDto = {
//     activities: UrpActivityDto[];
//     cards_names: Dictionary<string>;
//     cards_short_names: Dictionary<string>;
// };

export type UrpActivityDto = {
    id: number; // Идентификатор
    name: string; // Название
    cards: UrpCardFormDto[];
};

export type UrpCardFormDto = {
    key: number; // Ключ
    root: boolean; // Карточка может быть создана без парента
    child_cards: number[]; // Список ключей дочерних форм
};

export type Required = string;
export type DefaultSettings = string;
export type FormDefaultSettings = {
    key: number;
    formNum: number;
    required: Required[];
    defaults: DefaultSettings[];
};

export enum Regime {regime55=55, drafts=99}

// export type DetailsParams = {
//     uuid: UUID;
//     actform: 'act'|'form';
//     actformId: string|number;
// };