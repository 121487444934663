import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const RadioComponentToken = {
	/// Size of Radio dot	number	8
	dotSize: 12,
	/// Radio size	number	16
	radioSize: 18,

	lineWidth: 5,
	colorPrimary: Colors.mainOrange,
	
	/// Background color of Radio button	string	#ffffff
	buttonBg: Colors.mainWhite,
	/// Background color of checked Radio button	string	#ffffff
	buttonCheckedBg: Colors.mainRed,

	/// Background color of checked and disabled Radio button	string	rgba(0, 0, 0, 0.15)
	buttonCheckedBgDisabled: Colors.lightGrey,
	/// Color of checked and disabled Radio button text	string	rgba(0, 0, 0, 0.25)
	buttonCheckedColorDisabled: Colors.borderGrey,
	/// Color of disabled Radio dot	string	rgba(0, 0, 0, 0.25)
	dotColorDisabled: Colors.borderGrey,


	/// Color of Radio button text	string	rgba(0, 0, 0, 0.88)
	buttonColor: Colors.mainBlack,
	/// Horizontal padding of Radio button	number	15
	buttonPaddingInline: 12,

		/// Background color of checked solid Radio button text when active	string	#0958d9
		buttonSolidCheckedActiveBg: Colors.mainOrange,
		/// Background color of checked solid Radio button text	string	#1677ff
		buttonSolidCheckedBg: Colors.mainOrange,
		/// Color of checked solid Radio button text	string	#fff
		buttonSolidCheckedColor: Colors.mainOrange,
		/// Background color of checked solid Radio button text when hover	string	#4096ff
		buttonSolidCheckedHoverBg: Colors.mainOrangeHover,
		
	/// Margin right of Radio button	number	8
	wrapperMarginInlineEnd: 8,
}