export const Colors = {
    black: '#000000D9',
    white: '#FFFFFF',

    blue: {
        dark: '#023749',
        main: '#013F54',
        main50: '#013F5488',
        light: '#08566E',
        light15: '#08566E2C',
    },

    orange: {
        dark: '#FF4E03',
        dark15: '#FF4E032C',
        main: '#FF6626',
        light: '#FF6626',
        light15: '#FF66262C',
    },

    cloudy: {
        dark: '#80A3C0',
        dark50: '#80A3C088',
        main: '#B6CBDC',
        light: '#80A3C0',
        superlight: '#F3F8FB',
    },

    gray: {
        dark: '#BDC2C5',
        main: '#D9D9D9',
        light: '#F5F5F5',
    },

    tag: {
        success: '#3ABD7E',
        error: '#FF4D4F',
        info: '#26A9F2',
        warning: '#FAAD14',
        marked: "#FF6388"
    },

    error: '#FF4D4F',
    error5: '#FF4D4F0C',
    warning: '#FAAD14',
    warning5: '#FAAD140C',
};

export const Font = {
    h1: '600 20/24',
    h2: '600 18/22',
    h3: '600 16/20',
    h4: '600 14/20',
    h5: '600 12/16',

    bodyLarge: '400 16/20',
    bodyNormal: '400 14/18',
    bodySmall: '400 12/16',
};
