import {map} from "lodash";

export const journalColumns = {
    key: 'journal',
    formNum: 0,
    required: [] as string[],
    defaults: [] as string[],
    properties: [
        {
            key: 'num',
            dataIndex: 'num',
            name: '№п/п',
            title: '№п/п',
            align: 'center',
            width: 60,
        },
        {
            key: 'id',
            dataIndex: 'id',
            name: 'Идентификатор',
            title: 'Идентификатор',
            //defaultSortOrder: 'descend',
            sorter: true,
            width: 140,
        },
        {
            key: 'user',
            dataIndex: 'user_login',
            name: 'Пользователь',
            title: 'Пользователь',
            align: 'left',
            sorter: true,
            width: 140,
        },
        {
            key: 'prok_key',
            dataIndex: 'department_name',
            name: 'Подразделение',
            title: 'Подразделение',
            align: 'left',
            sorter: true,
            ellipsis: true,
            width: 300,
        },
        {
            key: 'form_key',
            dataIndex: 'form_key',
            name: 'Учетная карточка',
            title: 'Учетная карточка',
            align: 'left',
            sorter: true,
            ellipsis: true,
            width: 200,
        },
        {
            key: 'create_date',
            dataIndex: 'create_date_f',
            name: 'Дата создания',
            title: 'Дата создания',
            align: 'left',
            sorter: true,
            width: 190,
        },
        {
            key: 'update_date',
            dataIndex: 'create_date',
            name: 'Дата последнего изменения',
            title: 'Дата последнего изменения',
            align: 'left',
            sorter: true,
            width: 200,
        },
        {
            key: 'version_count',
            dataIndex: 'version_count',
            name: 'Кол-во версий',
            title: 'Кол-во версий',
            align: 'center',
            sorter: true,
            width: 120,
        },

        {
            key: 'files',
            dataIndex: 'files',
            name: 'Файлы',
            title: 'Файлы',
            align: 'center',
            sorter: true,
            width: 120,
        },
        {
            key: 'deleted',
            dataIndex: 'deleted',
            name: 'Удалено',
            title: 'Удалено',
            align: 'center',
            sorter: true,
            width: 120,
        },
        {
            key: 'transferred',
            dataIndex: 'transferred',
            name: 'Передано',
            title: 'Передано',
            align: 'center',
            sorter: true,
            width: 120,
        }
    ],
};

journalColumns.required = map(journalColumns.properties, p => p.key);
journalColumns.defaults = map(journalColumns.properties, p => p.key);

export const defaultPropertiesAndSettings = {
    '0': {
        key: 0,
        formNum: 0,
        required: ['num', 'id', 'user', 'create_date', 'prok_key', 'files', 'form_key'],
        defaults: ['num', 'id', 'user', 'create_date', 'prok_key', 'files', 'form_key'],
        properties: [
            {
                key: 'num',
                name: '№п/п',
            },
            {
                key: 'id',
                name: 'Идентификатор',
            },
            {
                key: 'user',
                name: 'Пользователь',
            },
            {
                key: 'create_date',
                name: 'Дата создания',
            },
            {
                key: 'prok_key',
                name: 'Подразделение',
            },
            {
                key: 'files',
                name: 'Файлы',
            },
            {
                key: 'form_key',
                name: 'Вид Учетной карточки',
            },
        ],
    },
    'journal': journalColumns
};
