import * as React from "react";
import { SVGProps } from "react";
const SvgMark = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="mark" className="anticon anticon-mark">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5947 8L18 9.34604L10.7961 17L6 11.9045L7.40531 10.5585L10.7961 14.1225L16.5947 8Z" />
</svg>
    </span>
);
export default SvgMark;
