import * as React from "react";
import { SVGProps } from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="filter" className="anticon anticon-filter">
  <svg width='1em' height='1em' viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path className='passive' fillRule="evenodd" clipRule="evenodd" d="M8.99999 13.2868L3.19574 4H20.8042L15 13.2868V17.5352L8.99999 20.8685V13.2868ZM6.80423 6L11 12.7132V17.1315L13 16.4648V12.7132L17.1957 6H6.80423Z" fill="currentColor"/>
      <path className='active' fillRule="evenodd" clipRule="evenodd" d="M16 4H3.19574L8.99999 13.2868V20.8685L15 17.5352V13.2868L18.4888 7.70469C17.8553 7.44601 17.303 7.02956 16.8806 6.50416L13 12.7132V16.4648L11 17.1315V12.7132L6.80423 6H16.5351C16.1948 5.41165 16 4.72857 16 4Z" fill="currentColor"/>
      <circle className='active' cx="20" cy="4" r="2" fill="#EF5550"/>
  </svg>
</span>);
export default SvgFilter;
