import * as React from "react";
import { SVGProps } from "react";
const SvgAddFile = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="addfile" className="anticon anticon-addfile">  
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 3H20V21H4V8L9 3ZM10 5H18V19H6V9L10 5Z" />
    <rect x="9" y="3" width="2" height="7" />
    <rect x="12" y="11" width="2" height="6" />
    <rect x="10" y="13" width="6" height="2" />
    <rect x="4" y="8" width="6" height="2" />
    </svg>
    </span>
);
export default SvgAddFile;