import * as React from "react";
import { SVGProps } from "react";
const SvgHumburgerIcon = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="humburgericon" className="anticon anticon-humburgericon">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 7V9H7V7H17ZM17 11V13H7V11H17ZM17 17V15H7V17H17Z" />
  </svg>
    </span>
);
export default SvgHumburgerIcon;
