import styled, { createGlobalStyle } from "styled-components";
import { Tabs as TabsAntd } from "antd";

import { TypographyMixins } from "../Typography";

export const TabsGlobalStyle = createGlobalStyle`
  :root {
    --tabs-height: 45px;
  }
`;

export const Tabs = styled(TabsAntd)`
  background-color: var(--bgc-blue);

  .ant-tabs-nav {
    height: var(--tabs-height);
    color: var(--main-white);
    margin-bottom: 0;

    ${TypographyMixins.fontMixin}
    ${TypographyMixins.Text14_20Mixin}

    &::before {
      border: none;
    }
    box-sizing: border-box;
  }

  .ant-tabs-content-holder {
    overflow: auto;
    background-color: var(--main-white);
  }

  .ant-tabs-tab {
    padding: 12.5px 25px;
    margin: 0;
    border-right: 1px solid var(--main-blue);

    &:hover {
      color: var(--main-white);
    }

    &-active {
      background-color: var(--main-white);
      color: var(--bgc-blue);

      &:hover {
        color: var(--bgc-blue);
      }
    }
  }

  .ant-tabs-tab-btn {
    color: inherit !important;
    text-shadow: none !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
` as typeof TabsAntd;
