import styled, {css} from "styled-components";
import { Radio as AntRadio } from "antd";
import {Colors} from "../../colors";
//import { RadioComponentToken } from '../Theme/token.radio';


//const token = RadioComponentToken;

type Props = {
  type?: string;
};
type GroupProps = Record<string, any>;

export const StyledRadio = styled(AntRadio)`
  .ant-radio-inner {
    border: solid 1px ${Colors.cloudy.main};
    background-color: ${Colors.white};
    
    &::after {
      background-color: ${Colors.orange.main};
      border: 3px solid ${Colors.white};
    }
  }
` as typeof AntRadio;

export const StyledRadioGroup = styled(AntRadio.Group)`
  display: inline-flex;
  flex-direction: ${(props: GroupProps) => (props.direction === 'vertical' ? 'column' : 'row')};
  gap: 4px 8px;

  .ant-radio-inner {
    border: solid 1px ${Colors.cloudy.main};
    background-color: ${Colors.white};

    &::after {
      background-color: ${Colors.orange.main};
      border: 3px solid ${Colors.white};
    }
  }
`;
