import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from '@gp-frontend-ui/ui-kit-5v2';
import { EllipsisProps } from './types';
import { SpanMax } from './styled';

/**
 * рисует дизайнерское многоточие с тултипом
 * @param title текст или реакт элемент для отображения в тултипе
 * @param tooltipPlacement? положение тултипа относительно многоточия @see Tooltip
 * @returns 
 */
export const Ellipsis: React.FC<EllipsisProps> = ({title, tooltipPlacement: placement}: EllipsisProps): JSX.Element => {
	return <Tooltip title={title} placement={placement}><SpanMax></SpanMax></Tooltip>;
};