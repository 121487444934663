//@ts-nocheck
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';


/**
 * @component
 * Обертка над антовским чекбоксом
 * @property [vertical] - вертикальная ориентация
 */

export const StyledCheckboxGroup = styled(AntCheckbox.Group)<CheckboxGroupProps>`
	display: inline-flex;
	flex-direction: ${(props: GroupProps) => (props.direction === 'vertical' ? 'column' : 'row')};
	gap: 4px 8px;
`;
