import React from "react";
import {TextAreaProps as AntTextAreaProps} from 'antd/lib/input/TextArea';
import type {InputProps} from "./components.types";
import {StyledInput} from "./styled";
import { Reset } from '../Reset';
import { Resettable } from '../Reset/types';

/**
 * Надстройка над Ant Design компонентом. 
 * Описания свойств можно смотреть в документации ant::Input 
 * @param allowReset boolean? - (опциональный) отображение внешней кнопки сброса значения поля ввода в значение "по умолчанию"
 * @param onReset ()=>void? - (опциональный) обработчик кнопки сброса значения
 * @returns 
 */
function Input({onReset, allowReset, ...args}: InputProps) {
    return (<Reset allowReset={allowReset} onReset={onReset}>
        <StyledInput {...args} style={{...args.style, flex: 1}} />
    </Reset>
    );
}

Input.Textarea = function InputTextarea({onReset, allowReset, ...arg}: AntTextAreaProps & Resettable) {
    return (<Reset allowReset={allowReset} onReset={onReset}>
        <StyledInput.TextArea
            {...arg}
        />
    </Reset>);
};

export {Input};


