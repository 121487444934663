import React from 'react';
import {Btn} from "../components/Btn";
import {Button, ConfigProvider, Space} from "antd";

const ButtonsPage = () => {
    return (
        <Space direction="vertical" size={20}>
            <h3>BTN</h3>
            <ConfigProvider theme={{
                components: {
                    Button: {
                        algorithm: true,
                        contentFontSize: 14,
                        fontWeight: 600,
                        borderRadius: 2,
                        primaryColor: "rgb(255,255,255)",
                        primaryShadow: "rgba(255,255,255,0.02)",
                        colorPrimaryBorder: "rgb(8,86,110)",
                        borderRadiusLG: 2,
                        borderRadiusSM: 2,
                        defaultShadow: "0 2px 0 rgba(200, 0, 0, 0.02)",
                        colorPrimary: "rgb(8,86,110)",
                        colorLink: "rgb(8,86,110)",
                        colorPrimaryHover: "rgb(1,63,84)",
                        colorLinkHover: "rgb(1,63,84)",
                        colorPrimaryActive: "rgb(2,55,73)",
                        colorLinkActive: "rgb(2,55,73)",
                        defaultColor: "rgb(128,163,192)",
                        defaultBg: "rgba(255,255,255,0)",
                        defaultBorderColor: "rgb(128,163,192)",
                        defaultHoverColor: "rgb(8,86,110)",
                        defaultHoverBorderColor: "rgb(8,86,110)",
                        defaultActiveBg: "rgba(8,86,110,0.15)",
                        defaultActiveBorderColor: "rgb(8,86,110)",
                        defaultActiveColor: "rgb(8,86,110)",
                        borderColorDisabled: "rgb(217, 217, 217)",
                        colorTextDisabled: "rgb(217, 217, 217)",
                        textTextHoverColor: "rgb(1,63,84)",
                        textTextColor: "rgb(128,163,192)",
                        defaultGhostColor: "rgb(128,163,192)",
                        defaultGhostBorderColor: "rgb(128,163,192)"
                    }
                }
            }}>
                <div style={{marginTop: 10, display: 'flex', gap: 10}}>
                    <Btn type="default">Btn Default</Btn>
                    <Btn type="default" accent>Btn Default Accent</Btn>
                    <Btn type="primary">Btn Primary</Btn>
                    <Btn type="primary" accent>Btn Primary Accent</Btn>
                    <Btn danger>Btn Default Danger</Btn>
                    <Btn type="primary" danger>Btn Primary Danger</Btn>
                    <Btn disabled>Btn Disabled</Btn>
                </div>
                    <div style={{marginTop: 10, display: 'flex', gap: 10}}>
                        <Btn color="default" variant="link">+ Btn Default Link</Btn>
                        <Btn color="primary" variant="link">+ Btn Primary Link</Btn>
                        <Btn color="primary" variant="link" accent>+ Btn Accent Link</Btn>
                        <Btn color="danger" variant="link">+ Btn Danger Link</Btn>
                        <Btn color="default" variant="link" disabled>+ Btn Link Disabled</Btn>
                    </div>
                    <div style={{marginTop: 10, display: 'flex', gap: 10}}>
                        <Btn color="default" variant="text">Btn Default Text</Btn>
                        <Btn color="primary" variant="text">Btn Primary Text</Btn>
                        <Btn color="primary" variant="text" accent>Btn Accent Text</Btn>
                        <Btn color="danger" variant="text">Btn Danger Text</Btn>
                        <Btn color="default" variant="text" disabled>Btn Text Disabled</Btn>
                    </div>
                    <div style={{
                        marginTop: 10,
                        padding: 10,
                        height: 50,
                        backgroundColor: '#eee',
                        display: 'flex',
                        gap: 10
                    }}>
                        <Btn type="default" ghost>
                            Default Ghost
                        </Btn>
                        <Btn type="primary" ghost>
                            Primary Ghost
                        </Btn>
                        <Btn type="primary" ghost accent>
                            Primary Ghost Accent
                        </Btn>
                        <Btn ghost danger>
                            Danger Ghost
                        </Btn>
                        <Btn ghost disabled>
                            Disabled Ghost
                        </Btn>
                    </div>
            </ConfigProvider>
        </Space>
);
};

export default ButtonsPage;
