import * as React from "react";
import { SVGProps } from "react";
const SvgStatClassifiers = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="classifier" className="anticon anticon-classifier">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3h10v6h8v6h-8v6H3v-6h8V9H3V3Zm2 2v2h6V5H5Zm8 8v-2h6v2h-6Zm-8 6v-2h6v2H5Z"
    />
  </svg>
</span>);
export default SvgStatClassifiers;
