import * as React from "react";
import { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="search" className="anticon anticon-search">
    	<svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M9.91752 16.835C6.09708 16.835 3 13.738 3 9.91752C3 6.09708 6.09708 3 9.91752 3C13.738 3 16.835 6.09708 16.835 9.91752C16.835 11.4115 16.3614 12.7949 15.5562 13.9257L20 18.3695L18.3695 20L13.9257 15.5562C12.7949 16.3614 11.4115 16.835 9.91752 16.835ZM10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" />
		</svg>
	</span>
);
export default SvgSearch;

