import styled from 'styled-components';
import { Flex, Table } from 'antd';
import {Colors} from "@gp-frontend-ui/ui-kit-5v2";
//import { Colors } from '@gp-frontend-lib/ui-kit-5';

export const TemplateTable = styled(Table)`
	.ant-table {
		background: none;

		.ant-table-row {
			.ant-table-cell {
				padding: 8px;
				vertical-align: middle;
				
				&.ant-table-cell-row-hover {
					background-color: #80A3C055; 
				}
			}

		}
	}
	button {
		margin-left: 0;
	}

	${(props) => {
    const onRowImplementation = props.onRow && props.onRow({});
    if (onRowImplementation?.onClick) {
        return `
				tbody tr:hover {
					cursor: pointer;
				}
			`;
    }
    return;
}}
`;

export const StyledContainer = styled.div`
	
`;

export const StyledWrapperFlex = styled(Flex)`
	flex-direction: column;
	gap: 8px;
	overflow-y: clip;
	overflow-x: hidden;
    margin-bottom: 60px;
`;

export const StyledFooterFlex = styled(Flex)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
    padding: 10px 15px;
	//background-color: Colors.mainWhite;
	background-color: white;
  	border-top: 1px ${Colors.blue.light} solid;
`;

export const ControlPanel = styled(Flex)`
	font-size: 12px;
	color: white;
	&>span {
		display: block;
	}
`;
