import * as React from "react";
import { SVGProps } from "react";
const SvgList = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="list" className="anticon anticon-list">
  <svg width='1em' height='1em' viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      className='passive'
      clipRule="evenodd"
      d="M8 8.976V7h12v1.976H8ZM8 12.93v-1.977h12v1.976H8ZM8 16.882v-1.977h12v1.977H8ZM4 16.882v-1.977h2v1.977H4ZM6 8.976H4V7h2v1.976ZM4 12.93v-1.977h2v1.976H4Z"
      fill="currentColor"
    />
    <path
      className='active'
      clipRule="evenodd"
      d="M15.126 7H8v2h8.354a4 4 0 0 1-1.228-2ZM8 11v2h12v-2H8Zm0 4v2h12v-2H8Zm-4 0v2h2v-2H4Zm0-6h2V7H4v2Zm0 2v2h2v-2H4Z"
      fill="currentColor"
    />
    <circle className='active' cx={19} cy={6} r={2} fill="#FF626E" />
  </svg>
</span>);
export default SvgList;
