import * as React from "react";
import { SVGProps } from "react";
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="pdf" className="anticon anticon-pdf">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1739 17.2174H10.2174V12.6957H17.1739V9.21739H13V5.04348H6.04348V18.9565H17.1739V17.2174ZM18.2174 12.6957H19.6087V17.2174H18.2174V20H5V4H13.6957L18.2174 8.52174V12.6957ZM13.2713 14.2957C13.0174 14.2957 12.8018 14.3826 12.6557 14.56V14.3165H12.2418V16.8487H12.6765V15.9478C12.8261 16.1183 13.0348 16.1983 13.2713 16.1983C13.8139 16.1983 14.2139 15.8226 14.2139 15.2452C14.2139 14.6713 13.8139 14.2957 13.2713 14.2957ZM13.2226 15.8261C12.9096 15.8261 12.6696 15.6 12.6696 15.2452C12.6696 14.8904 12.9096 14.6643 13.2226 14.6643C13.5357 14.6643 13.7722 14.8904 13.7722 15.2452C13.7722 15.6 13.5357 15.8261 13.2226 15.8261ZM16.0122 13.593V14.5461C15.8661 14.3757 15.6539 14.2957 15.4139 14.2957C14.8748 14.2957 14.4748 14.6678 14.4748 15.2452C14.4748 15.8226 14.8748 16.1983 15.4139 16.1983C15.6678 16.1983 15.8835 16.1113 16.0296 15.9339V16.1739H16.447V13.593H16.0122ZM15.4661 15.8261C15.153 15.8261 14.913 15.6 14.913 15.2452C14.913 14.8904 15.153 14.6643 15.4661 14.6643C15.7791 14.6643 16.0191 14.8904 16.0191 15.2452C16.0191 15.6 15.7791 15.8261 15.4661 15.8261ZM17.7722 13.92C17.8626 13.92 17.9461 13.9513 18.0122 13.9965L18.1339 13.6696C18.0366 13.6 17.8905 13.5687 17.7409 13.5687C17.3096 13.5687 17.0766 13.8226 17.0766 14.2122V14.3304H16.7705V14.6783H17.0766V16.1739H17.5113V14.6783H18.0087V14.3304H17.4974V14.2191C17.4974 14.0209 17.5913 13.92 17.7722 13.92Z" fill="#FF626E"/>
    </svg>
    </span>
);
export default SvgPdf;
