import * as React from "react";
import { SVGProps } from "react";
const SvgFolders = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="folders" className="anticon anticon-folders">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 8H2V4H10L12 6H22V20H2V18H4H20V8H12H11L9 6H4V8Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 18H2V8H10L12 10H22V18H20V12H12H11L9 10H4V18Z" />
    </svg>
    </span>
    );
export default SvgFolders;