import React from "react";
import type {InputNumberProps} from "./components.types";

import {StyledInputNumber} from "./styled";
import { Reset } from '../Reset';

/**
 * Надстройка над Ant Design компонентом. 
 * Описания свойств можно смотреть в документации ant::InputNumber 
 * @param allowReset boolean? - (опциональный) отображение внешней кнопки сброса значения поля ввода в значение "по умолчанию"
 * @param onReset ()=>void? - (опциональный) обработчик кнопки сброса значения
 * @returns 
 */
export const InputNumber: React.FC<InputNumberProps> = ({
                                                            onReset, 
                                                            allowReset,
                                                            errorCaption,
                                                            ...args
                                                        }) => {
    return (
        <Reset allowReset={allowReset} onReset={onReset}>
            {/*  <InputNumberWrapper> */}
            <StyledInputNumber
                className="input-wrapper"
                status={!!errorCaption ? "error" : undefined}
                {...args}
            />
            {!!errorCaption && <span className="error-caption">{errorCaption}</span>}
            {/* </InputNumberWrapper> */}
        </Reset>
    );
};
