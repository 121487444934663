import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const ModalComponentToken = {
	/// Background color of content	string	#ffffff
	contentBg: Colors.mainWhite,
	/// Background color of footer	string	transparent
	footerBg: Colors.mainWhite,
	/// Background color of header	string	#ffffff
	headerBg: Colors.mainLightestBlue,
	/// Font color of title	string	rgba(0, 0, 0, 0.88)
	titleColor: Colors.mainDarkBlue,
	/// Font size of title	number	16
	titleFontSize: 20,
	/// Line height of title	number	1.5
	titleLineHeight: 1,


	padding: 0,
	radius: 0,
	radiusSM: 0,
	radiusLG: 0,
}