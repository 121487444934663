import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const CollapseComponentToken = {
	/// Background of content	string	#ffffff
	contentBg: 'transparent',
	/// Padding of content	undefined | Padding<string | number>	16px 16px
	contentPadding: '8px 8px 8px 16px',
	/// Background of header	string	rgba(0, 0, 0, 0.02)
	headerBg: 'transparent',
	/// Padding of header	undefined | Padding<string | number>	12px 16px
	headerPadding: '10px 0px',

	/// Default border color, used to separate different elements, such as: form separator, card separator, etc.	string #d9d9d9
	colorBorder: '#d9d9d9',
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: 'rgba(0, 0, 0, 0.88)',
	/// Control the color of text in disabled state.	string rgba(0, 0, 0, 0.25)
	colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Control the font color of heading.	string rgba(0, 0, 0, 0.88)
	colorTextHeading: Colors.mainDarkBlue,
	/// LG size border radius, used in some large border radius components, such as Card, Modal and other components.	number 8
	borderRadiusLG: 0,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	// fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 16,
	/// Control the font size of operation icon in Select, Cascader, etc. Normally same as fontSizeSM.	number 12
	fontSizeIcon: 12,
	/// Large font size	number 16
	fontSizeLG: 18,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.25,
	/// Line height of large text.	number 1.5
	lineHeightLG: 1.25,
	/// Border style of base components	string solid
	lineType: 'solid',
	/// Border width of base components	number 1
	lineWidth: 1,
	/// Control the margin of an element, with a medium-small size.	number 12
	marginSM: 12,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Motion speed, slow speed. Used for large element animation interaction.	string 0.3s
	motionDurationSlow: '0.3s',
	/// Preset motion curve.	string cubic-bezier(0.645, 0.045, 0.355, 1)
	motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
	/// Control the padding of the element.	number 16
	padding: 16,
	/// Control the large padding of the element.	number 24
	paddingLG: 24,
	/// Control the small padding of the element.	number 12
	paddingSM: 12,
	/// Control the extra small padding of the element.	number 8
	paddingXS: 8,
	/// Control the extra extra small padding of the element.	number 4
	paddingXXS: 4,
}