import React, {Key, useRef, useState} from "react";
import {Select as AntdSelect, Divider, Flex, InputRef, Popover, Space} from "antd";
import {StyledWrapper} from "./styled";
import {SelectProps, SelectType} from "./components.types";
import {DownOutlined, SearchOutlined, UpOutlined} from "@ant-design/icons";
import {Checkbox} from "../Checkbox";
import { Reset } from '../Reset';
import { Button, Input } from '@gp-frontend-ui/ui-kit-5v2';
import { Menu } from '../Menu';
import { BaseOptionType, LabelInValueType, RawValueType } from 'rc-select/lib/Select';
import { FlattenOptionData } from 'rc-select/lib/interface';
import { ContextMenu } from './ContextMenu';

const Select: SelectType = ({
                                onChange,
                                onSelectAll,
                                value,
                                defaultValue,
                                onMouseLeave: onPopupMouseLeave,
                                maxTagTextLength = 40,
                                onReset, allowReset,
                                searchable, onSearch, withContextMenu,
                                dropdownRender,
                                ...args
                            }: SelectProps) => {

    const [IsDropdownVisible, setIsDropdownVisible] = useState(false);
    const refWrapper = useRef<HTMLDivElement>(null);

    const spanRef = useRef(null);
    const [query, setQuery] = useState('');
    const [contextOpen, setContextOpen] = useState<RawValueType|false>(false);

    const maxTagPlaceholder = (values: any[]) => {
        if(values.length > 0) {
            return <span >+{values?.length}...</span>;
        }
        return <></>
    };

    return (
        <Reset allowReset={allowReset} onReset={onReset}>
                <AntdSelect
                    getPopupContainer={(node:HTMLElement) => refWrapper.current as HTMLElement}
                    popupClassName="styled-popup-select"
                    onDropdownVisibleChange={(e) => { setIsDropdownVisible(e); }}
                    suffixIcon={IsDropdownVisible ? <UpOutlined/> : <DownOutlined/>}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    menuItemSelectedIcon={({ isSelected, disabled }) => (
                        (args.mode && args.mode === 'multiple') ? <Checkbox checked={isSelected} disabled={disabled}/> : null
                    )}
                    maxTagPlaceholder={maxTagPlaceholder}
                    maxTagTextLength={maxTagTextLength}
                    maxTagCount={1}
                    optionRender={!withContextMenu ? undefined : (option: FlattenOptionData<BaseOptionType> , info: { index: number }) => {
                        const item = <span onContextMenu={(e)=> { e.preventDefault(); e.stopPropagation(); if(option.value) setContextOpen(option.value); }}>{option.label}</span>;

                        if(contextOpen === option.value) {
                            return <ContextMenu open={true} onSelectAll={onSelectAll} onClose={()=>setContextOpen(false)}>{item}</ContextMenu>;
                        }
                        else {
                            return item;
                        }
                    }}
                    dropdownRender={dropdownRender ?? (
                        searchable ? (menu) => (
                            <>{
                                searchable && onSearch 
                                ? <>
                                    <Flex style={{ padding: 8 }} align='center' gap={10}>
                                        <Input
                                            placeholder="Найти"
                                            /* ref={inputRef} */
                                            value={query}
                                            onChange={(e) => setQuery(e.target.value)}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            style={{flex:1}}
                                        />
                                        <Button view='accent-text' icon={<SearchOutlined />} onClick={() => onSearch(query)} />
                                    </Flex>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            : null
                            }
                            {menu}
                            </>
                        )
                        : undefined
                    )}
                    {...args}
                />
            {(args.mode && args.mode === 'multiple') && <StyledWrapper ref={refWrapper} className="popup-container"></StyledWrapper>}
        </Reset>
    );
};

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export {Select};