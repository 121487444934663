import React from "react";

import { TitleH1, TitleH2, TitleH3 } from "./styled";

import type { TitleProps } from "./components.types";

export const Title: React.FC<TitleProps> = ({ tag = "h1", children }) => {
  switch (tag) {
    case "h1":
      return <TitleH1>{children}</TitleH1>;
    case "h2":
      return <TitleH2>{children}</TitleH2>;
    case "h3":
      return <TitleH3>{children}</TitleH3>;
  }
};
