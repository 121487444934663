import * as React from "react";
import { SVGProps } from "react";
const SvgCreateTemplate = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="createtemplate" className="anticon anticon-createtemplate">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.116 4H9.411L5 8.444V20h4.411v-1.778H6.765v-8h4.41V5.778h6.177v4.444h1.764V4ZM9.411 8.444V6.667L7.647 8.444H9.41Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.178 20h2.24L20 13.337l-2.207-2.226-6.615 6.67V20Z" fill="#FF626E"/>
    </svg>
    </span>
);
export default SvgCreateTemplate;