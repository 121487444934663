import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const SelectComponentToken = {
	/// Background color of clear button	string #ffffff
	clearBg: 'transparent',
	/// Background color of multiple tag	string rgba(0, 0, 0, 0.06)
	multipleItemBg: 'rgba(0, 0, 0, 0.06)',
	/// Border color of multiple tag	string transparent
	multipleItemBorderColor: 'transparent',
	/// Border color of multiple tag when disabled	string transparent
	multipleItemBorderColorDisabled: 'transparent',
	/// Text color of multiple tag when disabled	string rgba(0, 0, 0, 0.25)
	multipleItemColorDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Height of multiple tag	number 24
	multipleItemHeight: 32,
	/// Height of multiple tag with large size	number 32
	multipleItemHeightLG: 40,
	/// Height of multiple tag with small size	number 16
	multipleItemHeightSM: 24,
	/// Background color of multiple selector when disabled	string rgba(0, 0, 0, 0.04)
	multipleSelectorBgDisabled: Colors.mainLightestBlue,
	/// Background color when option is active	string rgba(0, 0, 0, 0.04)
	optionActiveBg: Colors.mainLighterBlue,
	/// Font size of option	number 14
	optionFontSize: 14,
	/// Height of option	number 32
	optionHeight: 32,
	/// Line height of option	undefined | LineHeight<string | number> 1.5714285714285714
	optionLineHeight: 1.5714285714285714,
	/// Padding of option	undefined | Padding<string | number> 5px 12px
	optionPadding: '5px 12px',
	/// Background color when option is selected	string #e6f4ff
	optionSelectedBg: Colors.mainBlue,
	/// Text color when option is selected	string rgba(0, 0, 0, 0.88)
	optionSelectedColor: Colors.mainWhite,
	/// Font weight when option is selected	undefined | FontWeight 600
	optionSelectedFontWeight: 600,
	/// Background color of selector	string #ffffff
	selectorBg: Colors.mainLightestBlue,
	/// Inline end padding of arrow	number 18
	showArrowPaddingInlineEnd: 18,
	/// Height of single selected item with large size	number 40
	singleItemHeightLG: 40,
	/// z-index of dropdown	number 1050
	zIndexPopup: 1050,


	/// Container background color, e.g: default button, input box, etc. Be sure not to confuse this with `colorBgElevated`.	string #ffffff
	colorBgContainer: Colors.mainLightestBlue,
	/// Control the background color of container in disabled state.	string rgba(0, 0, 0, 0.04)
	colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
	/// Container background color of the popup layer, in dark mode the color value of this token will be a little brighter than `colorBgContainer`. E.g: modal, pop-up, menu, etc.	string #ffffff
	colorBgElevated: Colors.mainLightestBlue,
	/// Default border color, used to separate different elements, such as: form separator, card separator, etc.	string #d9d9d9
	colorBorder: Colors.mainLighterBlue,
	/// Used to represent the visual elements of the operation failure, such as the error Button, error Result component, etc.	string #ff4d4f
	colorError: Colors.mainRed,
	/// The background color of the error state.	string #fff2f0
	colorErrorBg: Colors.additionalRed,
	/// The hover state background color of the error state.	string #fff1f0
	colorErrorBgHover: Colors.mainRed,
	/// The hover state of the error color.	string #ff7875
	colorErrorHover: Colors.mainRed,
	/// Control the outline color of input component in error state.	string rgba(255, 38, 5, 0.06)
	colorErrorOutline: Colors.mainRed,
	/// The second level of fill color can outline the shape of the element more clearly, such as Rate, Skeleton, etc. It can also be used as the Hover state of the third level of fill color, such as Table, etc.	string rgba(0, 0, 0, 0.06)
	colorFillSecondary: Colors.mainLightestBlue,
	/// The third level of fill color is used to outline the shape of the element, such as Slider, Segmented, etc. If there is no emphasis requirement, it is recommended to use the third level of fill color as the default fill color.	string rgba(0, 0, 0, 0.04)
	colorFillTertiary: Colors.mainLightestBlue,
	/// Weak action. Such as `allowClear` or Alert close button	string rgba(0, 0, 0, 0.45)
	colorIcon: Colors.mainDarkBlue,
	/// Weak action hover color. Such as `allowClear` or Alert close button	string rgba(0, 0, 0, 0.88)
	colorIconHover: 'rgba(0, 0, 0, 0.88)',
	/// Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.	string #1677ff
	colorPrimary: Colors.mainDarkBlue,
	/// Hover state under the main color gradient.	string #4096ff
	colorPrimaryHover: '#4096ff',
	/// Used as the color of separator, this color is the same as colorBorderSecondary but with transparency.	string rgba(5, 5, 5, 0.06)
	colorSplit: 'rgba(5, 5, 5, 0.06)',
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: Colors.mainDarkBlue,
	/// Control the font color of text description.	string rgba(0, 0, 0, 0.45)
	colorTextDescription: 'rgba(0, 0, 0, 0.45)',
	/// Control the color of text in disabled state.	string rgba(0, 0, 0, 0.25)
	colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Control the color of placeholder text.	string rgba(0, 0, 0, 0.25)
	colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
	/// The fourth level of text color is the lightest text color, such as form input prompt text, disabled color text, etc.	string rgba(0, 0, 0, 0.25)
	colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
	/// The third level of text color is generally used for descriptive text, such as form supplementary explanation text, list descriptive text, etc.	string rgba(0, 0, 0, 0.45)
	colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
	/// Used to represent the warning map token, such as Notification, Alert, etc. Alert or Control component(like Input) will use these map tokens.	string #faad14
	colorWarning: Colors.mainOrange,
	/// The background color of the warning state.	string #fffbe6
	colorWarningBg: Colors.additionalOrange,
	/// The hover state background color of the warning state.	string #fff1b8
	colorWarningBgHover: '#fff1b8',
	/// The hover state of the warning color.	string #ffd666
	colorWarningHover: '#ffd666',
	/// Control the outline color of input component in warning state.	string rgba(255, 215, 5, 0.1)
	colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
	/// Border radius of base components	number 6
	borderRadius: 0,
	/// LG size border radius, used in some large border radius components, such as Card, Modal and other components.	number 8
	borderRadiusLG: 0,
	/// SM size border radius, used in small size components, such as Button, Input, Select and other input components in small size	number 4
	borderRadiusSM: 0,
	/// XS size border radius, used in some small border radius components, such as Segmented, Arrow and other components with small border radius.	number 2
	borderRadiusXS: 0,
	/// Control the secondary box shadow style of an element.	string 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
	boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
	/// The height of the basic controls such as buttons and input boxes in Ant Design	number 32
	controlHeight: 32,
	/// LG component height	number 40
	controlHeightLG: 40,
	/// SM component height	number 24
	controlHeightSM: 24,
	/// Control the outline color of input component.	string rgba(5, 145, 255, 0.1)
	controlOutline: 'rgba(5, 145, 255, 0.1)',
	/// Control the outline width of input component.	number 2
	controlOutlineWidth: 2,
	/// Control the horizontal padding of an element.	number 12
	controlPaddingHorizontal: 12,
	/// Control the horizontal padding of an element with a small-medium size.	number 8
	controlPaddingHorizontalSM: 8,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	// fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'","
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 14,
	/// Control the font size of operation icon in Select, Cascader, etc. Normally same as fontSizeSM.	number 12
	fontSizeIcon: 12,
	/// Large font size	number 16
	fontSizeLG: 16,
	/// Small font size	number 12
	fontSizeSM: 12,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Border style of base components	string solid
	lineType: 'solid',
	/// Border width of base components	number 1
	lineWidth: 1,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Motion speed, slow speed. Used for large element animation interaction.	string 0.3s
	motionDurationSlow: '0.3s',
	/// Preset motion curve.	string cubic-bezier(0.645, 0.045, 0.355, 1)
	motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
	/// Preset motion curve.	string cubic-bezier(0.78, 0.14, 0.15, 0.86)
	motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
	/// Preset motion curve.	string cubic-bezier(0.755, 0.05, 0.855, 0.06)
	motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
	/// Preset motion curve.	string cubic-bezier(0.08, 0.82, 0.17, 1)
	motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
	/// Preset motion curve.	string cubic-bezier(0.23, 1, 0.32, 1)
	motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
	/// Control the small padding of the element.	number 12
	paddingSM: 12,
	/// Control the extra small padding of the element.	number 8
	paddingXS: 8,
	/// Control the extra extra small padding of the element.	number 4
	paddingXXS: 4,
}