import * as React from "react";
import { SVGProps } from "react";
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="expand" className="anticon anticon-expand">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15 12L9 16L9 8L15 12Z" />
</svg>
    </span>
);
export default SvgExpand;
