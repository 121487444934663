import * as React from "react";
import { SVGProps } from "react";
const SvgSign = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="sign" className="anticon anticon-sign">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.73672 7.08979L5.8707 7.58979C4.91411 8.14207 4.58636 9.36525 5.13865 10.3218L8.13865 15.518C8.69093 16.4746 9.91411 16.8023 10.8707 16.25L11.7367 15.75C12.3778 15.3799 12.7365 14.7084 12.7371 14.0178L13.8348 13.384L14.8348 15.1161L18.3348 21.1782C18.8871 22.1348 20.1103 22.4626 21.0669 21.9103L21.9329 21.4103C22.8895 20.858 23.2172 19.6348 22.6649 18.6782L19.1649 12.6161L18.1649 10.884L19.2627 10.2502C19.8611 10.595 20.6219 10.6202 21.263 10.25L22.129 9.75004C23.0856 9.19776 23.4134 7.97458 22.8611 7.01799L19.8611 1.82184C19.3088 0.865253 18.0856 0.537502 17.129 1.08979L16.263 1.58979C15.6219 1.95993 15.2632 2.63141 15.2627 3.32203L8.73706 7.0896C8.13868 6.74479 7.37783 6.71965 6.73672 7.08979ZM17.1649 9.15197L12.8348 11.652L11.9688 12.152L9.96877 8.68786L16.031 5.18786L18.031 8.65197L17.1649 9.15197ZM20.0669 20.1782L17.5669 15.8481L18.4329 15.3481L20.9329 19.6782L20.0669 20.1782ZM15.5669 12.384L16.4329 11.884L17.4329 13.6161L16.5669 14.1161L15.5669 12.384ZM21.129 8.01799L20.263 8.51799L17.263 3.32184L18.129 2.82184L21.129 8.01799ZM7.73672 8.82184L6.8707 9.32184L9.8707 14.518L10.7367 14.018L7.73672 8.82184ZM12.9999 20L1.99986 20C0.895293 20 -0.000136582 20.8955 -0.000136703 22L-0.000136852 25L14.9999 25L14.9999 22C14.9999 20.8955 14.1044 20 12.9999 20ZM1.99986 22L12.9999 22L12.9999 23L1.99986 23L1.99986 22Z" />
    </svg>
    </span>
);
export default SvgSign;
