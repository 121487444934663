import * as React from "react";
import { SVGProps } from "react";
const SvgSort = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="sort" className="anticon anticon-sort">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 20V16H20V20H4ZM8 14V10L20 10V14H8ZM12 4L12 8L20 8V4L12 4Z" />
    </svg>
    </span>
);
export default SvgSort;
