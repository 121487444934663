import * as React from "react";
import { SVGProps } from "react";
const SvgWeb = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="web" className="anticon anticon-web">
	<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"  {...props} xmlns="http://www.w3.org/2000/svg">
		<rect x="7" width="2" height="16" />
		<rect x="13.8931" width="2" height="20" transform="rotate(44 13.8931 0)" />
		<rect width="2" height="17" transform="matrix(-0.45399 0.891007 0.891007 0.45399 1.32617 3.47656)" />
		<path fillRule="evenodd" clipRule="evenodd" d="M7.84069 1.92969L2 5.4341V12.7811L7.03997 14.0411L14.108 11.6851L12.8949 3.19323L7.84069 1.92969ZM8.158 4.07029L11.105 4.80729L11.892 10.3153L6.96 11.9593L4 11.2193V6.56529L8.158 4.07029Z" />
	</svg>
</span>);
export default SvgWeb;
