import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const DropdownComponentToken = {
	/// Vertical padding of dropdown	undefined | PaddingBlock<string | number> 5
	paddingBlock: 5,
	/// z-index of dropdown	number 1050
	zIndexPopup: 1050,


	/// Container background color of the popup layer, in dark mode the color value of this token will be a little brighter than `colorBgContainer`. E.g: modal, pop-up, menu, etc.	string #ffffff
	colorBgElevated: '#ffffff',
	/// Used to represent the visual elements of the operation failure, such as the error Button, error Result component, etc.	string #ff4d4f
	colorError: '#ff4d4f',
	/// Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.	string #1677ff
	colorPrimary: '#1677ff',
	/// The stroke color under the main color gradient, used on the stroke of components such as Slider.	string #91caff
	colorPrimaryBorder: '#91caff',
	/// Used as the color of separator, this color is the same as colorBorderSecondary but with transparency.	string rgba(5, 5, 5, 0.06)
	colorSplit: 'rgba(5, 5, 5, 0.06)',
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: 'rgba(0, 0, 0, 0.88)',
	/// Control the font color of text description.	string rgba(0, 0, 0, 0.45)
	colorTextDescription: 'rgba(0, 0, 0, 0.45)',
	/// Control the color of text in disabled state.	string rgba(0, 0, 0, 0.25)
	colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Control the highlight color of text with background color, such as the text in Primary Button components.	string #fff
	colorTextLightSolid: '#fff',
	/// LG size border radius, used in some large border radius components, such as Card, Modal and other components.	number 8
	borderRadiusLG: 0,
	/// SM size border radius, used in small size components, such as Button, Input, Select and other input components in small size	number 4
	borderRadiusSM: 0,
	/// XS size border radius, used in some small border radius components, such as Segmented, Arrow and other components with small border radius.	number 2
	borderRadiusXS: 0,
	/// Control the secondary box shadow style of an element.	string 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
	boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
	/// Control the background color of control component item when active.	string #e6f4ff
	controlItemBgActive: '#e6f4ff',
	/// Control the background color of control component item when hovering and active.	string #bae0ff
	controlItemBgActiveHover: '#bae0ff',
	/// Control the background color of control component item when hovering.	string rgba(0, 0, 0, 0.04)
	controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
	/// Control the horizontal padding of an element.	number 12
	controlPaddingHorizontal: 12,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	// fontFamily: '-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 14,
	/// Control the font size of operation icon in Select, Cascader, etc. Normally same as fontSizeSM.	number 12
	fontSizeIcon: 12,
	/// Small font size	number 12
	fontSizeSM: 12,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Control the width of the line when the component is in focus state.	number 4
	lineWidthFocus: 4,
	/// Control the margin of an element, with a small size.	number 8
	marginXS: 8,
	/// Control the margin of an element, with the smallest size.	number 4
	marginXXS: 4,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Preset motion curve.	string cubic-bezier(0.78, 0.14, 0.15, 0.86)
	motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
	/// Preset motion curve.	string cubic-bezier(0.755, 0.05, 0.855, 0.06)
	motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
	/// Preset motion curve.	string cubic-bezier(0.08, 0.82, 0.17, 1)
	motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
	/// Preset motion curve.	string cubic-bezier(0.23, 1, 0.32, 1)
	motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
	/// Control the extra small padding of the element.	number 8
	paddingXS: 8,
	/// Control the extra extra small padding of the element.	number 4
	paddingXXS: 4,
	/// The size of the component arrow	number 16
	sizePopupArrow: 16,
}