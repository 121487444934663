import styled from "styled-components";
import { Input as AntInput } from "antd";
import {Colors} from "../../colors";

export const StyledInput = styled(AntInput)`
    color: ${Colors.black}; 
    caret-color: ${Colors.orange.dark};
    
    &.ant-input-disabled {
        color: ${Colors.blue.main50};
        ::placeholder {
            color: ${Colors.blue.main50};
        }
    }
`;

