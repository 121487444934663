import styled from "styled-components";

export const ResizableHandle = styled.span.withConfig({ shouldForwardProp: (props) => props !== 'handleAxis' })`
  position: absolute;
  right: 0px;
  bottom: 3px;
  z-index: 1;
  width: 10px;
  height: 80%;
  cursor: col-resize;
  border-right: 1px solid var(--main-steelblue);
`;
