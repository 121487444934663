import * as React from "react";
import { SVGProps } from "react";
const SvgRequisites = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="requisites" className="anticon anticon-requisites">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.94281 7.94281H12.2252L10.4224 9.66667L11.8168 11L16 7V16H7L11 11.8168L9.66667 10.4224L7.94281 12.2252V7.94281ZM6.05719 7.94281V12.2252L4.33333 10.4224L3 11.8168L7 16H3V21H16V16H21V3H16V7L11.8168 3L10.4224 4.33333L12.2252 6.05719H7.94281V6H6.05719V6.05719H6V7.94281H6.05719ZM14 18H5V19H14V18ZM19 5V14H18V5H19Z" />
</svg>
    </span>
);
export default SvgRequisites;
