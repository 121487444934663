import * as React from "react";
import { SVGProps } from "react";
const SvgSetting = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="setting" className="anticon anticon-setting">
  <svg width='1em' height='1em' viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path className='passive' clipRule="evenodd" d="M18.784 4.75 22 10.25l-2.538 1.448a6.873 6.873 0 0 1 0 .604L22 13.749l-3.216 5.502-2.537-1.447c-.172.109-.35.21-.53.303V21H9.283v-2.893a6.981 6.981 0 0 1-.53-.303l-2.538 1.447L3 13.749l2.538-1.447a6.92 6.92 0 0 1 0-.604L3 10.251l3.216-5.502 2.538 1.447c.171-.108.348-.21.53-.303V3h6.432v2.893c.182.093.359.195.53.303l2.538-1.447Zm-2.59 3.778-1.015-.64a4.987 4.987 0 0 0-.378-.217l-1.085-.558V5h-2.432v2.113l-1.085.558c-.13.067-.255.139-.378.216l-1.015.64-1.862-1.06-1.197 2.048 1.842 1.05-.053 1.22a4.907 4.907 0 0 0 0 .43l.053 1.22-1.842 1.05 1.197 2.049 1.862-1.062 1.015.64c.123.078.249.15.378.217l1.085.558V19h2.432v-2.113l1.085-.558c.13-.067.255-.139.378-.216l1.015-.64 1.862 1.06 1.197-2.048-1.842-1.05.053-1.22a4.939 4.939 0 0 0 0-.43l-.053-1.22 1.842-1.05-1.197-2.048-1.862 1.06ZM12.5 14.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm0-2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
    <path className='active' fill="currentColor" clipRule="evenodd" d="M16.167 6.146a7.002 7.002 0 0 0-.45-.253V3H9.283v2.893a6.984 6.984 0 0 0-.53.303L6.216 4.749 3 10.251l2.538 1.447a6.92 6.92 0 0 0 0 .604L3 13.749l3.216 5.502 2.538-1.447c.171.109.348.21.53.303V21h6.432v-2.893c.182-.093.359-.194.53-.303l2.538 1.447L22 13.749l-2.538-1.447a6.873 6.873 0 0 0 0-.604L22 10.251l-.832-1.424a4 4 0 0 1-2.313.007l.398.68-1.842 1.052.053 1.22a4.939 4.939 0 0 1 0 .429l-.053 1.22 1.842 1.05-1.197 2.049-1.862-1.062-1.015.64c-.123.078-.249.15-.378.217l-1.085.558V19h-2.432v-2.113l-1.085-.558a4.931 4.931 0 0 1-.378-.216l-1.015-.64-1.862 1.06-1.197-2.048 1.842-1.05-.053-1.22a4.907 4.907 0 0 1 0-.43l.053-1.22-1.842-1.05 1.197-2.048 1.862 1.06 1.015-.64c.123-.077.249-.15.378-.216l1.085-.558V5h2.432v2.113l1.085.558c.13.067.255.139.378.216l1.015.64 1.07-.609a4 4 0 0 1-1.097-1.772ZM10 12a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0Zm3 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>
    <circle className='active' cx={20} cy={4} r={2} fill="#EF5550" />
  </svg>
</span>);
export default SvgSetting;
