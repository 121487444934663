import React, { LegacyRef, forwardRef, useMemo } from "react";
import { Button as AntButton, ConfigProvider, } from "antd";
import { omit } from 'lodash';

import { ContextToken, type ButtonProps } from "./components.types";
import { StyledButton, StyledButtonWrapper } from "./styled";
import { globalThemeToken } from '../Theme/token';
import { Tooltip } from '../Tooltip';

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {
  const {
    type="default", // primary | dashed | link | text | default
    children,
    href,
    title,
    open,
    context,
    color,
    className,
    active,
    ...arg
  } = props;
/*   let config = globalThemeToken;
  if(globalThemeToken.components?.Button && context && ContextToken[context]) {
    config = clone(globalThemeToken);
    config.components!.Button = {...globalThemeToken.components.Button, ...ContextToken[context]};
  } */

  const getStyling = useMemo((): any => {
    let theme = globalThemeToken.components!.Button;
    if(context) {
      if(context === 'styling') {
        if(color) {
          theme = {...theme, ...ContextToken[context][color]};
        }
      }
      else {
        theme = {...theme, ...ContextToken[context]};
      }
    }
    return theme;
  }, [context, color]);

  const buttonContent = useMemo(() => 
    <ConfigProvider theme={{components: {
      Button: getStyling
    }}}>
      <StyledButton 
        className={(className??'') + ' ' + (context??'') + ' ' + (active?'active':'') + ' ' + (open?'open':'')}
        href={href} 
        ref={ref} 
        type={type || (href ? "link" : undefined)}
        active={active?'true':undefined}
        {...arg} 
      >
        {children}
      </StyledButton>
    </ConfigProvider>
    
  , [getStyling, className, context, active, open, href, ref, type, arg, children]);

  return( 
    <ConfigProvider theme={globalThemeToken}>
        {title 
          ? <Tooltip title={title} placement={context==='sidebar' ? 'rightBottom' : 'bottom'}>{buttonContent}</Tooltip>
          : buttonContent
        }
    </ConfigProvider>
  );
});
