import * as React from "react";
import { SVGProps } from "react";
const SvgStatusNormal = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="statusnormal" className="anticon anticon-statusnormal">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM16.7071 9.80761L15.2929 8.3934L11 12.6863L8.70711 10.3934L7.29289 11.8076L11 15.5147L16.7071 9.80761Z" fill="#15BE4D"/>
  </svg>
    </span>
);
export default SvgStatusNormal;
