import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const FormToken = {
	/// Inline layout form item margin bottom	number 0
	inlineItemMarginBottom: 0,
	/// Form item margin bottom	number 24
	itemMarginBottom: 24,
	/// Label colon margin-inline-end	number 8
	labelColonMarginInlineEnd: 8,
	/// Label colon margin-inline-start	number 2
	labelColonMarginInlineStart: 2,
	/// Label color	string rgba(0, 0, 0, 0.88)
	labelColor: Colors.darkBlue,
	/// Label font size	number 14
	labelFontSize: 14,
	/// Label height	number 32
	labelHeight: 40,
	/// Required mark color	string #ff4d4f
	labelRequiredMarkColor: '#ff4d4f',
	/// Vertical layout label margin	undefined | Margin<string | number> 0
	verticalLabelMargin: 0,
	/// Vertical layout label padding	undefined | Padding<string | number> 0 0 8px
	verticalLabelPadding: '0 0 8px',

	/// Default border color, used to separate different elements, such as: form separator, card separator, etc.	string #d9d9d9
	colorBorder: '#d9d9d9',
	/// Used to represent the visual elements of the operation failure, such as the error Button, error Result component, etc.	string #ff4d4f
	colorError: '#ff4d4f',
	/// Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.	string #1677ff
	colorPrimary: '#1677ff',
	/// Used to represent the token sequence of operation success, such as Result, Progress and other components will use these map tokens.	string #52c41a
	colorSuccess: '#52c41a',
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: 'rgba(0, 0, 0, 0.88)',
	/// Control the font color of text description.	string rgba(0, 0, 0, 0.45)
	colorTextDescription: 'rgba(0, 0, 0, 0.45)',
	/// Used to represent the warning map token, such as Notification, Alert, etc. Alert or Control component(like Input) will use these map tokens.	string #faad14
	colorWarning: '#faad14',
	/// The height of the basic controls such as buttons and input boxes in Ant Design	number 32
	controlHeight: 40,
	/// LG component height	number 40
	controlHeightLG: 48,
	/// SM component height	number 24
	controlHeightSM: 32,
	/// Control the outline color of input component.	string rgba(5, 145, 255, 0.1)
	controlOutline: Colors.borderGrey,
	/// Control the outline width of input component.	number 2
	controlOutlineWidth: 1,
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	//fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 14,
	/// Large font size	number 16
	fontSizeLG: 16,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Border style of base components	string solid
	lineType: 'solid',
	/// Border width of base components	number 1
	lineWidth: 1,
	/// Control the margin of an element, with a medium size.	number 16
	margin: 16,
	/// Control the margin of an element, with a large size.	number 24
	marginLG: 24,
	/// Control the margin of an element, with the smallest size.	number 4
	marginXXS: 4,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Motion speed, slow speed. Used for large element animation interaction.	string 0.3s
	motionDurationSlow: '0.3s',
	/// Preset motion curve.	string cubic-bezier(0.645, 0.045, 0.355, 1)
	motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
	/// Preset motion curve.	string cubic-bezier(0.215, 0.61, 0.355, 1)
	motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
	/// Preset motion curve.	string cubic-bezier(0.12, 0.4, 0.29, 1.46)
	motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
	/// Control the small padding of the element.	number 12
	paddingSM: 12,
	/// Control the maximum width of large screens.	number 1199
	screenLGMax: 1199,
	/// Control the maximum width of medium screens.	number 991
	screenMDMax: 991,
	/// Control the maximum width of small screens.	number 767
	screenSMMax: 767,
	/// Control the maximum width of extra small screens.	number 575
	screenXSMax: 575,
};