import React, { useCallback, useEffect, useState } from "react";
import {  Dropdown } from "antd";
import type MenuClickEventHandler from "rc-menu";
import { ContextMenuProps } from './component.types';
import { MenuProps } from '../../ui-kit/Menu/components.types';

const items: MenuProps['items'] = [
	{
	  label: 'отметить все',
	  key: 'select_all',
	},
	{
	  label: 'отменить все',
	  key: 'deselect_all',
	},
	{
	  type: 'divider',
	},
	{
	  label: 'отметить подчиненных',
	  key: 'select_children',
	},
	{
	  label: 'отменить подчиненных',
	  key: 'deselect_children',
	},
	{
	  type: 'divider',
	},
	{
	  label: 'отметить уровень иерархии',
	  key: 'select_siblings',
	},
	{
	  label: 'отменить уровень иерархии',
	  key: 'deselect_siblings',
	},
	// {
	//   type: 'divider',
	// },
	// {
	//   label: 'раскрыть все',
	//   key: 'expand_all',
	// },
	// {
	//   label: 'свернуть все',
	//   key: 'collapse_all',
	// },
  ];

const ContextMenu = ({
	children,
	onSelect,
	node,
	...props
}: ContextMenuProps) => {
  const [value, setValue] = useState<string>();
  const [open, setOpen] = useState<boolean>(true);

  const onClickHandler = useCallback((info: any) => {
	info.domEvent.preventDefault();
	info.domEvent.stopPropagation();

	setOpen(true);
	onSelect?.(node, info.key);
  }, [node, onSelect]);


  return (
	<Dropdown 
            menu={{ 
              items,
              onClick: onClickHandler
            }} 
            trigger={['contextMenu']} 
            placement='bottomRight' 
            destroyPopupOnHide={true}
            
          >
            <span>{children}</span>
	</Dropdown>
  );
};

// TreeSelect.Option = StyledTreeSelect.Option;
// TreeSelect.OptGroup = StyledTreeSelect.OptGroup;

export { ContextMenu };
