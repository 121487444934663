import { ConfigProvider, Space } from 'antd';
import { Ellipsis } from './ellipsis';
import { Button, theme } from '@gp-frontend-ui/ui-kit-5v2';

export const Example = () => {
	return (
        <ConfigProvider theme={theme}><Space>

			<Ellipsis tooltipPlacement='right' title='Простая строка' />
			<Ellipsis tooltipPlacement='top' title='Простая длинная длинная длинная длинная длинная длинная длинная длинная длинная длинная длинная длинная строка' />
			<Ellipsis tooltipPlacement='bottom' title={<Space direction='vertical'>
				<div>Строка с кнопкой</div>
				<Button>вот этой</Button>
			</Space>} />
			
		</Space></ConfigProvider> 
	);
}
