import styled, { createGlobalStyle } from "styled-components";
import { TreeSelect as AntTreeSelect } from "antd";

export const StyledWrapper = styled.div`
	.deptree-dropdown {
		width: 500px;

		.ant-select-tree-node-content-wrapper {
			.ant-select-tree-title {
				.ant-dropdown-trigger span {
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

	}
`;

export const MultiselectTags = styled.span`
	
`;

export const StyledTreeSelect = styled(AntTreeSelect)`
	width: 100%;
	min-width: 400px;
` as typeof AntTreeSelect;

export const StyledTreeSelectDropdown = createGlobalStyle`
`;
