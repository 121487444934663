import {Link, Route, Routes} from "react-router-dom";
import App from "./App";
import FormEdit from "./pages/formEdit";
import Form2 from "./pages/form2";
import FilterPage from "./pages/filter";
//import TablePage from './pages/table1';
import TablePage2 from './pages/table2';
import ColumnsSettingsPage from "./pages/columns_settings";
import ButtonsPage from "./pages/buttons";
import CollapsesPage from "./pages/collapses";

export const Pages = () => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", gap: 15}}>
                <Link to={"/"}>Главная</Link>
                <Link to={"/filters"}>Filters</Link>
                <Link to={"/form-1"}>FormEdit</Link>
                <Link to={"/form-2"}>Form-2</Link>
                {/*<Link to={"/table-1"}>Table 1</Link>*/}
                <Link to={"/table-2"}>Table 2</Link>
                <Link to={"/columns"}>Columns</Link>
                <Link to={"/buttons"}>Buttons</Link>
                <Link to={"/collapses"}>Collapses</Link>
            </div>
            <div>
                <Routes>
                    <Route index element={<App/>}/>
                    <Route path={"filters"} element={<FilterPage/>}/>
                    <Route path={"form-1"} element={<FormEdit/>}/>
                    <Route path={"form-2"} element={<Form2/>}/>
                    {/*<Route path={"table-1"} element={<TablePage />}/>*/}
                    <Route path={"table-2"} element={<TablePage2 />}/>
                    <Route path={"columns"} element={<ColumnsSettingsPage />}/>
                    <Route path={"buttons"} element={<ButtonsPage />}/>
                    <Route path={"collapses"} element={<CollapsesPage />}/>
                </Routes>
            </div>
        </div>
    );
};
