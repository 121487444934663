import styled from 'styled-components';
import { Colors } from '../Theme';


export const StyledButton = styled.button<{ 
  size?: number,
  color?: string,
  hoverColor?: string,
  background?: string,
  $isActive?: boolean;
}>`
  & > span {
    & > svg {
      > path {
        fill: ${({color}) => color};
      }
      > .active {
        visibility: ${({$isActive}) => $isActive ? 'visible' : 'hidden'}
      }
      > .passive {
        visibility: ${({$isActive}) => !$isActive ? 'visible' : 'hidden'}
      }
    }
/*     position: relative;
    display: block;
    line-height: 1px;
    &::after {
      content: ' ';
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='3' fill='%23EF5550' stroke='%2308566E' strokeWidth='2'/%3E%3C/svg%3E%0A");
      position: absolute;
      width: 8px;
      height: 8px;
      top: 4px;
      right: 4px;
      visibility: ${({$isActive}) => $isActive ? 'visible' : 'hidden'};
      z-index:2;
    } */


}




  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: ${({size}) =>  `${size}px` ?? 'unset'};
  height: ${({size}) =>  `${size}px` ?? 'unset'};
  min-width: ${({size}) =>  `${size}px` ?? 'unset'};
  min-height: ${({size}) =>  `${size}px` ?? 'unset'};
  margin: 0;
  //padding: ${({size}) =>  size&&size>24 ? `calc( (${size}px - 24px) / 2 )` : 0};
  padding: 0;
  background-color: ${({background}) => background??'transparent'};
  color: ${({color}) => color??Colors.mainWhite};

  opacity: ${({disabled}) => disabled ? 0.2 : 1};
  
  &:hover {
    color: ${({color,hoverColor}) => hoverColor??(color===Colors.mainOrange?Colors.mainLightBlue:Colors.mainOrange)}};
  }
`;