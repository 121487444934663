import styled, { css } from "styled-components";
import { Button as AntButton} from "antd";
import type { ButtonProps as AntdButtonProps } from "antd";

import { TypographyMixins } from "../Typography";
import { ButtonProps } from '.';
import { Colors } from '../Theme/colors';


export const StyledButtonWrapper = styled.div`
  &>span {
    display: block;
  }
`;

export const StyledButton = styled(AntButton)<ButtonProps>`
  &.styling {
    &.ant-btn-text {
      color: ${Colors.mainBlue};
      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${Colors.mainBlue};
      }
    }
    &.ant-btn-default.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: #ffb3b4;
      color: ${Colors.mainWhite};
    }
  }

  &.pagetitle {
    max-width: 32px;
    max-height: 32px;
    &.ant-btn-primary {
      max-width: 48px;
      max-height: 48px;
      width: 48px;
      height: 48px;
    }
    &.active {
      position: relative;
      &::after {
        content: ' ';
        position: absolute;
        border: 2px solid #FF626E;
        display: block;
        width: 4px;
        height: 4px;
        bottom: 4px;
        right: 4px;
      }
    }
    &:disabled {
      // background: ${Colors.mainDarkBlue};
    }
  }

  &.sidebar {
    &.open {
      background-color: ${Colors.mainWhite};
      color: ${Colors.mainDarkBlue};
      border: none;
    }  
  }

  &.table {
    &:disabled, &[disabled] {
      border: none;
    }
    &.off {
      color: #aaa;
    }
  }

  .active {
    display: ${(props) => props.active ? 'block' : 'none'}
  }
  .passive {
    visibility: ${(props) => props.active ? 'hidden' : 'visible'}
  }

  svg {
      position: relative;
    &::after {
      content: '*';
      display: block;
      width: 10px;
      height: 10px;
      color: red;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 5;
    }
  }

`;

// <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="passive" clip-rule="evenodd" d="M8 8.976V7h12v1.976H8ZM8 12.93v-1.977h12v1.976H8ZM8 16.882v-1.977h12v1.977H8ZM4 16.882v-1.977h2v1.977H4ZM6 8.976H4V7h2v1.976ZM4 12.93v-1.977h2v1.976H4Z" fill="currentColor"></path><path class="active" clip-rule="evenodd" d="M15.126 7H8v2h8.354a4 4 0 0 1-1.228-2ZM8 11v2h12v-2H8Zm0 4v2h12v-2H8Zm-4 0v2h2v-2H4Zm0-6h2V7H4v2Zm0 2v2h2v-2H4Z" fill="currentColor"></path><circle class="active" cx="19" cy="6" r="2" fill="#FF626E"></circle></svg>


export const StyledButton4: typeof AntButton = styled(AntButton)`
  height: unset;
  padding: 0;

  ${({ href }) =>
    !href &&
    css`
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      height: 45px;
      column-gap: 10px;
      padding: 10px 22px;
      background-color: var(--main-orange);
      color: var(--main-white);
      border: none;
      border-radius: 0;
      box-shadow: none;
      ${TypographyMixins.Text13_15Mixin}
      font-weight: 600;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--btn-main-hover);
        color: var(--main-white);
      }

      &[disabled] {
        background-color: var(--btn-main-disabled);
        color: var(--main-white);

        &:hover {
          background-color: var(--btn-main-disabled);
          color: var(--main-white);
        }

        span {
          opacity: 0.5;
        }
      }

      &.ant-btn > .anticon + span {
        margin-left: 0;
      }

      &.ant-btn-sm {
        height: 32px;
        padding: 4px 16px;
      }

      &.ant-btn-lg {
        height: 64px;
        padding: 20px 22px;
      }

      &.ant-btn-ghost {
        background-color: transparent;
        color: var(--text-main);
      }
    `}

    ${({type}) => 
      type === "primary" && 
        css`
          background-color: var(--btn-bgc-secondary);
          color: var(--main-orange);
          text-shadow: none;

          &:hover {
            background-color: var(--btn-secondary-hover);
            color: var(--main-dark-orange);
          }

          &[disabled] {
            background-color: var(--btn-bgc-secondary);
            color: var(--main-orange);

            &:hover {
              background-color: var(--btn-bgc-secondary);
              color: var(--main-orange);
            }

            span {
              opacity: 0.5;
            }
          }
      `}

  ${({ href }) =>
    href &&
    css`
      line-height: unset !important;
      color: var(--main-blue);

      &:hover {
        color: var(--main-blue);
        border-bottom: 1px solid var(--main-blue);
      }
    `}
`;