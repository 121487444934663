import {forwardRef} from "react";
import {Button as AntdButton} from "antd";
import {ButtonProps} from "./components.types";


export const ButtonNew = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {
        const {
            view ,
            children,
            //href,
            //title,
            //open,
            //context,
            //color,
            //className,
            //active,
            ...arg
        } = props;

        return <AntdButton {...arg}>{ children} - {view}</AntdButton>;
    }
);