import * as React from "react";
import { SVGProps } from "react";
const SvgStatRequests = (props: SVGProps<SVGSVGElement>) => (<span role="img" aria-label="requests" className="anticon anticon-requests">
  <svg
    width='1em'
    height='1em'
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3h18v18H3V3Zm12 7h4V5h-4v5Zm-2 0V5H5v5h8Zm-8 4h4v5H5v-5Zm14 0v5h-8v-5h8Z"
    />
  </svg>
</span>);
export default SvgStatRequests;
