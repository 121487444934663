import {ColumnSettings} from "./settings_types";
import {Dictionary} from "./types";
import {Form} from "./useColumnsSettings";

export const dataColumns = [
    {
        "key": "num",
        "name": "№ п/п",
        "width": 150,
        "sort": 0,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "id",
        "name": "Идентификатор записи",
        "width": 150,
        "sort": 1,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "user",
        "name": "Изменено пользователем",
        "width": 150,
        "sort": 2,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "create_date",
        "name": "Дата создания",
        "width": 150,
        "sort": 3,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "prok_key",
        "name": "Подразделение органа прокуратуры",
        "width": 150,
        "sort": 4,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "files",
        "name": "Файлы",
        "width": 150,
        "sort": 5,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "form_key",
        "name": "Учетная карточка",
        "width": 150,
        "sort": 6,
        "visibility": true,
        "lock": "ENABLED"
    },
    {
        "key": "101873468",
        "type": "date",
        "name": "дата окончания надзорной проверки",
        "width": 150,
        "sort": 7,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873469",
        "type": "reference",
        "name": "основная характеристика выявленных однотипных нарушений законодательства",
        "width": 150,
        "sort": 8,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873470",
        "type": "integer",
        "name": "количество выявленных однотипных нарушений законодательства",
        "width": 150,
        "sort": 9,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873471",
        "type": "reference",
        "name": "дополнительная характеристика выявленных однотипных нарушений законодательства",
        "width": 150,
        "sort": 10,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873472",
        "type": "reference",
        "name": "в том числе (для транспортных прокуров)",
        "width": 150,
        "sort": 11,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873474",
        "type": "integer",
        "name": "количество выявленных однотипных нарушений законодательства",
        "width": 150,
        "sort": 12,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101873477",
        "type": "integer",
        "name": "количество лиц, допустивших нарушения",
        "width": 150,
        "sort": 13,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101874003",
        "type": "reference",
        "name": "характер выявленных нарушений",
        "width": 150,
        "sort": 14,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101874004",
        "type": "integer",
        "name": "количество выявленных нарушений законов",
        "width": 150,
        "sort": 15,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101874005",
        "type": "integer",
        "name": "признано незаконно проведенными проверок (контрольных (надзорных) мероприятий)",
        "width": 150,
        "sort": 16,
        "visibility": true,
        "lock": false
    },
    {
        "key": "101874006",
        "type": "reference",
        "name": "проверяемый орган (Для отчета ГМК)",
        "width": 150,
        "sort": 17,
        "visibility": true,
        "lock": false
    }
] as ColumnSettings[];

export const dataForms  = {
    "75264151": {
        "key": 75264151,
        "title": "1-УРП Надзор за процессуальной деятельностью органов предварительного расследования. Проверки",
        "short": "1-УРП"
    },
    "75264191": {
        "key": 75264191,
        "title": "2-УРП Надзор за процессуальной деятельностью органов предварительного расследования. Акты",
        "short": "2-УРП"
    },
    "75264211": {
        "key": 75264211,
        "title": "3-УРП  Надзор за процессуальной деятельностью органов предварительного расследования. Рассмотрение ходатайств и жалоб в суде",
        "short": "3-УРП"
    },
    "75264251": {
        "key": 75264251,
        "title": "11-УРП Надзор за исполнением законов учреждениями и органами, исполняющими уголовные наказания",
        "short": "11-УРП"
    },
    "75264271": {
        "key": 75264271,
        "title": "12-УРП Надзор за исполнением законов",
        "short": "12-УРП"
    },
    "75264311": {
        "key": 75264311,
        "title": "20-УРП Правовое просвещение",
        "short": "20-УРП"
    },
    "75264331": {
        "key": 75264331,
        "title": "21-УРП Координация деятельности правоохранительных органов по борьбе с преступностью",
        "short": "21-УРП"
    },
    "75264351": {
        "key": 75264351,
        "title": "22-УРП Административная практика прокурора",
        "short": "22-УРП"
    },
    "75264371": {
        "key": 75264371,
        "title": "23-УРП Нормотворческая деятельность прокурора и проверка законности нормативных правовых актов",
        "short": "23-УРП"
    },
    "75264411": {
        "key": 75264411,
        "title": "25-УРП Санкционирование решений налоговых органов об аресте имущества",
        "short": "25-УРП"
    },
    "75264431": {
        "key": 75264431,
        "title": "27-УРП. Иск (заявление) прокурора в порядке гражданского, административного, уголовного или арбитражного судопроизводства",
        "short": "27-УРП"
    },
    "75264451": {
        "key": 75264451,
        "title": "28.0.1-УРП Представления (жалобы) прокурора (апелляционная инстанция)",
        "short": "28.0.1-УРП"
    },
    "79925954": {
        "key": 79925954,
        "title": "26-УРП Надзор за исполнением законов контролирующими органами при проведении проверок",
        "short": "26-УРП"
    },
    "79964134": {
        "key": 79964134,
        "title": "29-УРП Информация по заявлениям, жалобам и иным обращениям",
        "short": "29-УРП"
    },
    "80006554": {
        "key": 80006554,
        "title": "13-УРП Протест прокурора",
        "short": "13-УРП"
    },
    "80006574": {
        "key": 80006574,
        "title": "14-УРП Представление прокурора",
        "short": "14-УРП"
    },
    "80006594": {
        "key": 80006594,
        "title": "15-УРП Предостережение о недопустимости нарушения закона",
        "short": "15-УРП"
    },
    "80006614": {
        "key": 80006614,
        "title": "16-УРП Привлечение к уголовной ответственности",
        "short": "16-УРП"
    },
    "80006654": {
        "key": 80006654,
        "title": "18-УРП Направление информаций",
        "short": "18-УРП"
    },
    "80058264": {
        "key": 80058264,
        "title": "4-УРП Участие прокурора в рассмотрении уголовных дел судом. Рассмотрение дела судом первой инстанции",
        "short": "4-УРП"
    },
    "80059464": {
        "key": 80059464,
        "title": "5-УРП Участие прокурора в рассмотрении уголовных дел судом. Представление прокурора на решения суда первой инстанции",
        "short": "5-УРП"
    },
    "80060084": {
        "key": 80060084,
        "title": "6-УРП Участие прокурора в рассмотрении уголовных дел судом. Рассмотрение уголовного дела в суде апелляционной инстанции",
        "short": "6-УРП"
    },
    "80061024": {
        "key": 80061024,
        "title": "7-УРП Участие прокурора в рассмотрении уголовных дел судом. Представление прокурора на решение суда апелляционной инстанции по уголовному делу",
        "short": "7-УРП"
    },
    "80061684": {
        "key": 80061684,
        "title": "8-УРП Участие прокурора в рассмотрении уголовных дел судом. Рассмотрение дела в суде кассационной инстанции",
        "short": "8-УРП"
    },
    "80062344": {
        "key": 80062344,
        "title": "9-УРП Участие прокурора в рассмотрении уголовных дел судом. Надзорное представление прокурора",
        "short": "9-УРП"
    },
    "80062964": {
        "key": 80062964,
        "title": "10-УРП Участие прокурора в рассмотрении уголовных дел судом. Рассмотрение уголовного дела в суде надзорной инстанции",
        "short": "10-УРП"
    },
    "81120495": {
        "key": 81120495,
        "title": "32-УРП Сведения о заключении с подозреваемым досудебных соглашений о сотрудничестве",
        "short": "32-УРП"
    },
    "81120496": {
        "key": 81120496,
        "title": "30-УРП Решения, повлекшие признание права на реабилитацию",
        "short": "30-УРП"
    },
    "81120497": {
        "key": 81120497,
        "title": "31-УРП Требования о возмещении имущественного, морального вреда и о восстановлении трудовых, пенсионных, жилищных и иных прав",
        "short": "31-УРП"
    },
    "81120535": {
        "key": 81120535,
        "title": "41-УРП Надзор за исполнением законов в оборонно-промышленном комплексе",
        "short": "41-УРП"
    },
    "81120536": {
        "key": 81120536,
        "title": "33-УРП Участие органов прокуратуры в международном сотрудничестве ",
        "short": "33-УРП"
    },
    "81120556": {
        "key": 81120556,
        "title": "35-УРП. Участие прокурора \nв рассмотрении дела судом апелляционной инстанции ",
        "short": "35-УРП"
    },
    "81120557": {
        "key": 81120557,
        "title": "36-УРП. Рассмотрено в кассационной инстанции ",
        "short": "36-УРП"
    },
    "81120561": {
        "key": 81120561,
        "title": "34-УРП. Участие прокурора в рассмотрении дела судом первой инстанции \n",
        "short": "34-УРП"
    },
    "81120564": {
        "key": 81120564,
        "title": "37-УРП. Рассмотрено в Верховном суде Российской Федерации\n",
        "short": "37-УРП"
    },
    "81120616": {
        "key": 81120616,
        "title": "42-УРП Предупреждение прокурора",
        "short": "42-УРП"
    },
    "92564607": {
        "key": 92564607,
        "title": "43-УРП. Надзор за исполнением законов при осуществлении оперативно-разыскной деятельности ",
        "short": "43-УРП"
    },
    "92564627": {
        "key": 92564627,
        "title": "44-УРП Соблюдение прав и свобод человека и гражданина",
        "short": "44-УРП"
    },
    "92564647": {
        "key": 92564647,
        "title": "45-УРП Рассмотрения жалоб на действия и решения органов, осуществляющих оперативно-розыскную деятельность",
        "short": "45-УРП"
    },
    "100547392": {
        "key": 100547392,
        "title": "47-УРП Сведения о расследовании уголовного дела",
        "short": "47-УРП"
    },
    "100671552": {
        "key": 100671552,
        "title": "49-УРП Надзор за исполнением законов в сфере противодействия легализации преступных доходов, финансированию экстремизма и терроризма",
        "short": "49-УРП"
    },
    "101232522": {
        "key": 101232522,
        "title": "27.1-УРП. Участие прокурора в рассмотрении судом первой инстанции иска (заявления), поданного другим лицом\n(в порядке гражданского и административного судопроизводства)",
        "short": "27.1-УРП"
    },
    "101233052": {
        "key": 101233052,
        "title": "28.1-УРП. Участие прокурора в пересмотре судебных актов по гражданскому или административному делу, возбужденному по инициативе прокурора,  а также по инициативе других лиц. \nАпелляционная инстанция (результат пересмотра дел)",
        "short": "28.1-УРП"
    },
    "101234002": {
        "key": 101234002,
        "title": "19.2-УРП Взаимодействие со СМИ и общественностью",
        "short": "19.2-УРП"
    },
    "101850872": {
        "key": 101850872,
        "title": "28.0.2-УРП Представления (жалобы) прокурора (кассационная инстанция)",
        "short": "28.0.2-УРП"
    },
    "101852832": {
        "key": 101852832,
        "title": "28.2-УРП. Участие прокурора в пересмотре судебных актов по гражданскому или административному делу, возбужденному по инициативе прокурора, а также по инициативе других лиц. \nКассационная инстанция (результат пересмотра дел)",
        "short": "28.2-УРП"
    },
    "101853312": {
        "key": 101853312,
        "title": "28.0.3-УРП Представления (жалобы) прокурора (надзорная инстанция)",
        "short": "28.0.3-УРП"
    },
    "101855272": {
        "key": 101855272,
        "title": "28.3-УРП Участие прокурора в пересмотре судебных актов по делу, возбужденным по инициативе прокурора, а также по инициативе других лиц. Результат пересмотра судебного акта (надзорная инстанция)",
        "short": "28.3-УРП"
    },
    "101898513": {
        "key": 101898513,
        "title": "11.1-УРП Протест прокурора",
        "short": "11.1-УРП"
    },
    "101898514": {
        "key": 101898514,
        "title": "11.2-УРП Направлено исков",
        "short": "11.2-УРП"
    },
    "101898515": {
        "key": 101898515,
        "title": "11.3-УРП Представление прокурора",
        "short": "11.3-УРП"
    },
    "101898516": {
        "key": 101898516,
        "title": "11.4-УРП Привлечение к административной ответственности",
        "short": "11.4-УРП"
    },
    "101898517": {
        "key": 101898517,
        "title": "11.5-УРП Привлечение к уголовной ответственности",
        "short": "11.5-УРП"
    },
    "101898521": {
        "key": 101898521,
        "title": "11.6-УРП Предостережение",
        "short": "11.6-УРП"
    },
    "101898682": {
        "key": 101898682,
        "title": "130-УРП. Предостережение о недопустимости нарушения закона",
        "short": "130-УРП"
    },
    "101898684": {
        "key": 101898684,
        "title": "150-УРП. Направление информаций",
        "short": "150-УРП"
    },
    "101898686": {
        "key": 101898686,
        "title": "100-УРП. Надзор за исполнением законов",
        "short": "100-УРП",
        "properties": [
            {
                "key": "num",
                "name": "№ п/п"
            },
            {
                "key": "id",
                "name": "Идентификатор записи"
            },
            {
                "key": "user",
                "name": "Изменено пользователем"
            },
            {
                "key": "create_date",
                "name": "Дата создания"
            },
            {
                "key": "prok_key",
                "name": "Подразделение органа прокуратуры"
            },
            {
                "key": "files",
                "name": "Файлы"
            },
            {
                "key": "form_key",
                "name": "Учетная карточка"
            },
            {
                "key": "101873468",
                "name": "дата окончания надзорной проверки",
                "type": "date",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "10",
                "mask": {
                    "key": 70138557,
                    "name": "dd.MM.yyyy",
                    "mask_name": "Дата (ДД.ММ.ГГГГ)"
                },
                "gui": {
                    "primary_key": 4,
                    "name": "Календарь"
                }
            },
            {
                "key": "101873469",
                "name": "основная характеристика выявленных однотипных нарушений законодательства",
                "type": "reference",
                "is_hidden": false,
                "is_multi": false,
                "dict_key": "101417887",
                "req_code": "20",
                "gui": {
                    "primary_key": 7,
                    "name": "Раскрывающийся список"
                }
            },
            {
                "key": "101873470",
                "name": "количество выявленных однотипных нарушений законодательства",
                "type": "integer",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "30",
                "gui": {
                    "primary_key": 3,
                    "name": "Числовое поле"
                }
            },
            {
                "key": "101873471",
                "name": "дополнительная характеристика выявленных однотипных нарушений законодательства",
                "type": "reference",
                "is_hidden": false,
                "is_multi": false,
                "dict_key": "101417888",
                "req_code": "40",
                "gui": {
                    "primary_key": 7,
                    "name": "Раскрывающийся список"
                }
            },
            {
                "key": "101873472",
                "name": "в том числе (для транспортных прокуров)",
                "type": "reference",
                "is_hidden": false,
                "is_multi": false,
                "dict_key": "101417889",
                "req_code": "50",
                "gui": {
                    "primary_key": 7,
                    "name": "Раскрывающийся список"
                }
            },
            {
                "key": "101873474",
                "name": "количество выявленных однотипных нарушений законодательства",
                "type": "integer",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "60",
                "gui": {
                    "primary_key": 3,
                    "name": "Числовое поле"
                }
            },
            {
                "key": "101873477",
                "name": "количество лиц, допустивших нарушения",
                "type": "integer",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "70",
                "gui": {
                    "primary_key": 3,
                    "name": "Числовое поле"
                }
            },
            {
                "key": "101874003",
                "name": "характер выявленных нарушений",
                "type": "reference",
                "is_hidden": false,
                "is_multi": false,
                "dict_key": "101418008",
                "req_code": "80",
                "gui": {
                    "primary_key": 7,
                    "name": "Раскрывающийся список"
                }
            },
            {
                "key": "101874004",
                "name": "количество выявленных нарушений законов",
                "type": "integer",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "90",
                "gui": {
                    "primary_key": 3,
                    "name": "Числовое поле"
                }
            },
            {
                "key": "101874005",
                "name": "признано незаконно проведенными проверок (контрольных (надзорных) мероприятий)",
                "type": "integer",
                "is_hidden": false,
                "is_multi": false,
                "req_code": "100",
                "gui": {
                    "primary_key": 3,
                    "name": "Числовое поле"
                }
            },
            {
                "key": "101874006",
                "name": "проверяемый орган (Для отчета ГМК)",
                "type": "reference",
                "is_hidden": false,
                "is_multi": false,
                "dict_key": "101418009",
                "req_code": "110",
                "gui": {
                    "primary_key": 7,
                    "name": "Раскрывающийся список"
                }
            }
        ]
    },
    "101898687": {
        "key": 101898687,
        "title": "110-УРП. Протест прокурора",
        "short": "110-УРП"
    },
    "101898688": {
        "key": 101898688,
        "title": "120-УРП. Представление прокурора",
        "short": "120-УРП"
    },
    "101898689": {
        "key": 101898689,
        "title": "140-УРП. Привлечение к уголовной ответственности",
        "short": "140-УРП"
    },
    "101898690": {
        "key": 101898690,
        "title": "160-УРП. Санкционирование решений налоговых органов об аресте имущества",
        "short": "160-УРП"
    },
    "101898691": {
        "key": 101898691,
        "title": "170-УРП. Иск (заявление) прокурора в порядке гражданского, административного, уголовного или арбитражного судопроизводства",
        "short": "170-УРП"
    },
    "101898692": {
        "key": 101898692,
        "title": "121-УРП. Представление прокурора в сфере противодействия коррупции",
        "short": "121-УРП"
    },
    "101898694": {
        "key": 101898694,
        "title": "520-УРП. Направление информаций об устранении нарушений",
        "short": "520-УРП"
    },
    "101898695": {
        "key": 101898695,
        "title": "101-УРП. Надзор за исполнением законов в сфере противодействия коррупции",
        "short": "101-УРП"
    },
    "101898696": {
        "key": 101898696,
        "title": "151-УРП. Направление информаций в сфере противодействия коррупции",
        "short": "151-УРП"
    },
    "101898697": {
        "key": 101898697,
        "title": "171-УРП. Исковая работа и внесудебные меры прокурора в сфере противодействия коррупции и внесудебные меры, направленные на возмещение ущерба, причиненного актами коррупции",
        "short": "171-УРП"
    },
    "101898699": {
        "key": 101898699,
        "title": "300-УРП. Нормотворческая деятельность прокурора и проверка законности нормативных правовых актов",
        "short": "300-УРП"
    },
    "101898700": {
        "key": 101898700,
        "title": "500-УРП. Проверка оперативно-служебных документов",
        "short": "500-УРП"
    },
    "101898701": {
        "key": 101898701,
        "title": "510-УРП. Представление прокурора",
        "short": "510-УРП"
    },
    "101898702": {
        "key": 101898702,
        "title": "530-УРП. Требование прокурора",
        "short": "530-УРП"
    },
    "101898703": {
        "key": 101898703,
        "title": "800-УРП. Надзор за исполнением законов учреждениями и органами, исполняющими уголовные наказания",
        "short": "800-УРП"
    },
    "101898704": {
        "key": 101898704,
        "title": "810-УРП. Протест прокурора",
        "short": "810-УРП"
    },
    "101898706": {
        "key": 101898706,
        "title": "820-УРП. Направлено исков (заявлений)",
        "short": "820-УРП"
    },
    "101898708": {
        "key": 101898708,
        "title": "200-УРП. Административная практика прокурора",
        "short": "200-УРП"
    },
    "101898710": {
        "key": 101898710,
        "title": "830-УРП. Представление прокурора",
        "short": "830-УРП"
    },
    "101898711": {
        "key": 101898711,
        "title": "840-УРП. Привлечение к уголовной ответственности",
        "short": "840-УРП"
    },
    "101898712": {
        "key": 101898712,
        "title": "850-УРП. Предостережение, информация прокурора",
        "short": "850-УРП"
    },
    "101898713": {
        "key": 101898713,
        "title": "900-УРП. Координация деятельности правоохранительных органов по борьбе с преступностью",
        "short": "900-УРП"
    },
    "101898714": {
        "key": 101898714,
        "title": "910-УРП. Функционирование межведомственных рабочих групп по отдельным направлениям совместной деятельности правоохранительных органов по борьбе с преступностью",
        "short": "910-УРП"
    },
    "101898715": {
        "key": 101898715,
        "title": "1000-УРП. Рассмотрение запросов о выдаче лиц",
        "short": "1000-УРП"
    },
    "101898716": {
        "key": 101898716,
        "title": "1010-УРП. Рассмотрение запросов о правовой помощи по уголовным делам",
        "short": "1010-УРП"
    },
    "101898717": {
        "key": 101898717,
        "title": "1020-УРП. Рассмотрение запросов об осуществлении уголовного преследования",
        "short": "1020-УРП"
    },
    "101898718": {
        "key": 101898718,
        "title": "1030-УРП. Рассмотрение запросов о правовой помощи по делам об административных правонарушениях",
        "short": "1030-УРП"
    },
    "101898719": {
        "key": 101898719,
        "title": "1040-УРП. Рассмотрение запросов об осуществлении административного преследования",
        "short": "1040-УРП"
    },
    "101898720": {
        "key": 101898720,
        "title": "1050-УРП. Рассмотрение запросов о правоохранительном содействии",
        "short": "1050-УРП"
    },
    "101898721": {
        "key": 101898721,
        "title": "1060-УРП. Рассмотрение материалов о передаче лиц, страдающих психическими расстройствами, для проведения принудительного лечения",
        "short": "1060-УРП"
    },
    "101898722": {
        "key": 101898722,
        "title": "1070-УРП. Участие в международных мероприятиях",
        "short": "1070-УРП"
    },
    "101898723": {
        "key": 101898723,
        "title": "1080-УРП. Организационно-правовое обеспечение международного сотрудничества",
        "short": "1080-УРП"
    },
    "101898724": {
        "key": 101898724,
        "title": "201-УРП. Взысканные штрафы по административным делам",
        "short": "201-УРП"
    },
    "101898726": {
        "key": 101898726,
        "title": "610-УРП. Надзор за процессуальной деятельностью органов предварительного расследования. Акты",
        "short": "610-УРП"
    },
    "101898727": {
        "key": 101898727,
        "title": "210-УРП. Согласие на назначение административного наказания лицу, обладающему особым правовым статусом",
        "short": "210-УРП"
    },
    "101898728": {
        "key": 101898728,
        "title": "220-УРП. Направление ходатайства, постановления, материалов для привлечения к административной ответственности лица, обладающего особым правовым статусом",
        "short": "220-УРП"
    },
    "101898729": {
        "key": 101898729,
        "title": "230-УРП. Представление о даче согласия на привлечение к административной ответственности лица, обладающего особым правовым статусом",
        "short": "230-УРП"
    },
    "101898730": {
        "key": 101898730,
        "title": "240-УРП. Рассмотрение дела об административном правонарушении, совершенным лицом, обладающим особым правовым статусом",
        "short": "240-УРП"
    },
    "101898732": {
        "key": 101898732,
        "title": "620-УРП. Надзор за процессуальной деятельностью органов предварительного расследования. Рассмотрение ходатайств и жалоб в суде",
        "short": "620-УРП"
    },
    "101898733": {
        "key": 101898733,
        "title": "600-УРП. Надзор за процессуальной деятельностью органов предварительного расследования. Проверки",
        "short": "600-УРП"
    },
    "101898734": {
        "key": 101898734,
        "title": "400-УРП. Участие прокурора в рассмотрении судом первой инстанции гражданского, административного или арбитражного дела",
        "short": "400-УРП"
    },
    "101898735": {
        "key": 101898735,
        "title": "410-УРП. Апелляционная инстанция (представления прокурора)",
        "short": "410-УРП"
    },
    "101898736": {
        "key": 101898736,
        "title": "420-УРП. Участие прокурора в пересмотре судебных актов в суде апелляционной инстанции",
        "short": "420-УРП"
    },
    "101898737": {
        "key": 101898737,
        "title": "430-УРП. Кассационная инстанция (представления прокурора)",
        "short": "430-УРП"
    },
    "101898738": {
        "key": 101898738,
        "title": "440-УРП. Участие прокурора в пересмотре судебных актов в суде кассационной инстанции",
        "short": "440-УРП"
    },
    "101898739": {
        "key": 101898739,
        "title": "460-УРП. Защита интересов органов прокуратуры",
        "short": "460-УРП"
    },
    "101898740": {
        "key": 101898740,
        "title": "950-УРП. Взаимодействие со СМИ и общественностью",
        "short": "950-УРП"
    },
    "101898741": {
        "key": 101898741,
        "title": "960-УРП. Отклики на выступления в СМИ (повторные выступления, трансляции)",
        "short": "960-УРП"
    },
    "101898742": {
        "key": 101898742,
        "title": "970-УРП. Правовое просвещение",
        "short": "970-УРП"
    },
    "101898743": {
        "key": 101898743,
        "title": "350-УРП. Надзор за исполнением законов контролирующими органами при проведении проверок",
        "short": "350-УРП"
    },
    "101898928": {
        "key": 101898928,
        "title": "860-УРП. Надзор за исполнением законов учреждениями и органами, исполняющими уголовные наказания\n",
        "short": "860-УРП"
    },
    "journal": {
        "key": -1,
        "title": "Журнал изменений записей",
        "short": "Журнал",
        "properties": [
            {
                "key": "num",
                "dataIndex": "num",
                "name": "№п/п",
                "title": "№п/п",
                "align": "center",
                "width": 60
            },
            {
                "key": "id",
                "dataIndex": "id",
                "name": "Идентификатор",
                "title": "Идентификатор",
                "sorter": true,
                "width": 140
            },
            {
                "key": "user",
                "dataIndex": "user_login",
                "name": "Пользователь",
                "title": "Пользователь",
                "align": "left",
                "sorter": true,
                "width": 140
            },
            {
                "key": "prok_key",
                "dataIndex": "department_name",
                "name": "Подразделение",
                "title": "Подразделение",
                "align": "left",
                "sorter": true,
                "ellipsis": true,
                "width": 300
            },
            {
                "key": "form_key",
                "dataIndex": "form_key",
                "name": "Учетная карточка",
                "title": "Учетная карточка",
                "align": "left",
                "sorter": true,
                "ellipsis": true,
                "width": 200
            },
            {
                "key": "create_date",
                "dataIndex": "create_date_f",
                "name": "Дата создания",
                "title": "Дата создания",
                "align": "left",
                "sorter": true,
                "width": 190
            },
            {
                "key": "update_date",
                "dataIndex": "create_date",
                "name": "Дата последнего изменения",
                "title": "Дата последнего изменения",
                "align": "left",
                "sorter": true,
                "width": 200
            },
            {
                "key": "version_count",
                "dataIndex": "version_count",
                "name": "Кол-во версий",
                "title": "Кол-во версий",
                "align": "center",
                "sorter": true,
                "width": 120
            },
            {
                "key": "files",
                "dataIndex": "files",
                "name": "Файлы",
                "title": "Файлы",
                "align": "center",
                "sorter": true,
                "width": 120
            },
            {
                "key": "deleted",
                "dataIndex": "deleted",
                "name": "Удалено",
                "title": "Удалено",
                "align": "center",
                "sorter": true,
                "width": 120
            },
            {
                "key": "transferred",
                "dataIndex": "transferred",
                "name": "Передано",
                "title": "Передано",
                "align": "center",
                "sorter": true,
                "width": 120
            }
        ]
    }
};

export const dataTableKey = [
    101898686
];