import { find, forEach, take, filter, zipObject, isEmpty, omit, map, sortBy, reduce, without, flatMap, union, uniq } from 'lodash';
import { DepartmentShortInfo, TreeNode } from './component.types';
import { Key } from 'react';

const ROOT_GP = '79941914';

export function translateToNode(dep: DepartmentShortInfo): TreeNode {
  return {
    ...dep, 
    key: dep.key ? String(dep.key) : undefined, 
    parent_key: dep.parent_key ? String(dep.parent_key) : undefined, 
    value: dep.key ? String(dep.key) : undefined, 
    isLeaf: true,
    urp_code: dep.urp_code ? dep.urp_code : dep.code
  } as TreeNode;
}

export function getDescendants(subtree:TreeNode, withSelf=true): string[] {
  const start = withSelf ? [subtree.key] : [];
  const descendants: string[] = reduce(subtree.children, (result, value, key) => {
    result.push(value.key);
    if(!isEmpty(value.children)) {
      const grandchildren = getDescendants(value);
      result.push(...grandchildren);
    }
    return result;
  }, start);
  return uniq(descendants);
}

export function getSiblings(subtree:TreeNode, parent:TreeNode, deep:boolean): string[] {
  const siblings = without(parent.children, subtree);
  const siblingKeys = map(siblings, dep => dep.key);
  if(!deep) {
    return uniq(siblingKeys);
  }
  else {
    const withDescendants = flatMap(siblings, dep => getDescendants(dep));
    return uniq( union(siblingKeys, map(withDescendants, String)) );
  }
}
// { id: 1, pId: 0, value: '1', title: 'Expand to load' },
// export function getChildren(list: Record<string, DepartmentShortInfo>, parentId: number, deep?: boolean): Record<string, TreeNode> {
//   const segment = take(filter(list, { 'parent_key': parentId }), 1000) ?? [];

//   let children: TreeNode[];
//   if (deep) { // все поддерево
//     children = getDescendants(list, parentId);
//   }
//   else {
//     children = segment.map((node: DepartmentShortInfo) => {
//       return translateToNode(node);
//     });
//   }

//   return zipObject(children.map(item => item.key as number), children);
// }

export function buildTree(list: Record<string, TreeNode>, parent: string, isRoot?: boolean) {
  const result: TreeNode[] = [];

  const children = filter(list, { 'parent_key': parent });
  const segment = take(children, 5) ?? [];
  children.forEach(node => {
    node.children = buildTree(list, node.key);
  });

  const root: TreeNode = list[parent];
  if (isRoot && root) { // самый корневой объект
    root.children = children;
    result.push(root);
  }
  else {
    result.push(...children);
  }
  return result; 
}

export function buildTreeData(rawData: DepartmentShortInfo[], parent: string = undefined): TreeNode[] {
  const result:TreeNode[] = [];
  if(!parent) {
    const root = filter(rawData, (dep) => {
      if(dep.key === ROOT_GP)
        console.log(dep);
      return !dep.parent_key;
    }) as TreeNode[];
    result.push(...root);
  }
  else {
    const children = filter(rawData, {parent_key: String(parent)});
    result.push(...children);
  }

  forEach(result, node => {
    node.children = sortBy( buildTreeData(rawData, String(node.key)), ['ord', 'name'] );
    if(!isEmpty(node.children)) {
      node.isLeaf = false;
    }
  });
  return result;
}
