import {Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
//import {CardPropertyType, ColumnSettings, SettingsTemplate} from "@entities/Settings/types";
//import {useDispatch, useSelector} from "react-redux";
// import {
//     getSettingsTemplateListAction, saveSettingsAction,
//     selectActiveFormKeys,
//     selectColumns,
//     selectForms,
//     selectTemplates,
// } from "@entities/Settings";
import {clone, cloneDeep, each, find, findIndex, pullAt} from "lodash";
//import {getDefaultsSettings} from "@entities/Settings/utils";
// import { ModuleCtx } from '@features/Module/context';
import {CardPropertyType, ColumnSettings, PropertyDto, SettingsTemplate} from "./settings_types";
import {getDefaultsSettings} from "./utils";
import {Dictionary} from "./types";
import {dataColumns, dataForms, dataTableKey} from "./data";


type useColumnsSettingsType = (forms: Dictionary<Form>, columns: ColumnSettings[], onClose: () => void) => {
    tableKey: number | undefined,
    sortedColumns: ColumnSettings[],
    clientTemplates: SettingsTemplate[],
    selectedTemplate: number | undefined,
    setClientTemplates: Dispatch<SetStateAction<SettingsTemplate[]>>,
    chooseTemplatesHandler: (templateId?: number) => boolean,
    onOk: () => void,
    onCancel: () => void,
    onChange: (data) => void,
    dirty: boolean,
}

export type Form = {
    key: number,
    title: string,
    short: string,
    properties?: PropertyDto[] | undefined
}

export const useColumnsSettings: useColumnsSettingsType = (forms, columns, onClose) => {
    //const dispatch = useDispatch();
    //const {type: module} = useContext(ModuleCtx);
    let module = 'datainput';
    //const tableKey = useSelector(selectActiveFormKeys);
    //const forms = useSelector(selectForms);
    //const forms = dataForms as Dictionary<Form>;
    //const columns = useSelector(selectColumns);
    //const templates = useSelector(selectTemplates);

    const [dirty, setDirty] = useState(false);
    const [sortedColumns, setSortedColumns] = useState<ColumnSettings[]>([]);
    const [clientTemplates, setClientTemplates] = useState<SettingsTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<number>();
    const [tKey, setTKey] = useState<number>();


    const init = useCallback((columns) => {
        const newSortedColumns = cloneDeep(columns);
        if (newSortedColumns[0]?.key !== 'num') {
            const idx = findIndex(newSortedColumns, {key: 'num'});
            const num = pullAt(newSortedColumns, idx)[0];
            newSortedColumns.unshift(num);
        }

        setSortedColumns(newSortedColumns);
        setDirty(false);
    }, []);

    useEffect(() => {
        //if(!columns || !isOpen) return;
        if (!columns) return;
        init(columns);
    }, [columns, init]); // , isOpen

    /*useEffect(() => {
        // if(isOpen && tableKey && tableKey.length>0) {
        if (tableKey && tableKey.length > 0) {
            let tKey: number;
            if (module === 'journal') {
                tKey = -1;
            }
            else {
                if (tableKey.length === 1) {
                    tKey = tableKey[0];
                }
                else {
                    tKey = 0;
                }
            }
            setTKey(tKey);
            setSelectedTemplate(undefined);
            //dispatch( getSettingsTemplateListAction(tKey) );
        }
    }, [tableKey, module]); // , isOpen */

    useEffect(() => {
        if (tKey !== undefined) {
            const form = find(forms, ['key', tKey]);
            const settings = getDefaultsSettings(form?.key ?? 0, form?.properties);
            const combine: SettingsTemplate[] = [
                {
                    id: 0,
                    title: 'По умолчанию',
                    columns: settings,
                    default: true,
                    key_doc: 0
                }
            ];
        }
    }, [columns, forms, tKey]); // templates

    const onCancel = useCallback(() => {
        init(columns);
        onClose();
    }, [onClose, init]);

    const chooseTemplatesHandler = useCallback((templateId?: number) => {
        if (templateId !== undefined) {
            const template = find(clientTemplates, ['id', templateId]);

            if (template && sortedColumns) {
                const restColumns = clone(columns);
                const newColumnList: ColumnSettings[] = [];

                if (template.columns[0].key !== 'num') {
                    const idx = findIndex(template.columns, {key: 'num'});
                    const num = pullAt(template.columns, idx)[0];
                    template.columns.unshift(num);
                }

                template
                    .columns
                    .forEach((item: { key: any; visibility: any; }) => {
                        const idx = findIndex(restColumns, {key: item.key});
                        if (idx !== -1) {
                            const toadd = pullAt(restColumns, [idx])[0];
                            // @ts-ignore
                            toadd.visibility = item.visibility;
                            newColumnList.push(toadd as ColumnSettings);
                        }

                    });
                newColumnList.push(...each(restColumns, (item) => item.visibility = false));
                setSortedColumns(newColumnList);
                setDirty(true);
            }
        }
        setSelectedTemplate(templateId);
        return true;
    }, [clientTemplates, sortedColumns]);

    const onChange = useCallback((data: any) => {
        setSortedColumns(data);
        setDirty(true);

        setSelectedTemplate(undefined);
    }, []);

    const onOk = useCallback(() => {
        if (sortedColumns) {
            //dispatch( saveSettingsAction({forKey: tKey, settings: sortedColumns}) );
        }
        onClose();
    }, [tKey, sortedColumns, onClose]);

    return {
        tableKey: tKey,
        sortedColumns,
        clientTemplates,
        setClientTemplates,
        selectedTemplate,
        chooseTemplatesHandler,
        onOk,
        onCancel,
        onChange,
        dirty,
    };
}; /* /useColumnsSettings */
