import * as React from "react";
import { SVGProps } from "react";
const SvgEditActive = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="editactive" className="anticon anticon-editactive">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M14.826 5 19 9.174 9.174 19l-3.757-.417L5 14.826 14.826 5Zm-1.743 4L7 15.083l.192 1.725L8.917 17 15 10.917 13.083 9ZM14 8.082 15.905 10 17 8.898 15.075 7 14 8.082Z" fill="#FF7C45"/>
    </svg>
    </span>
);
export default SvgEditActive;
