import styled from "styled-components";
import { InputNumber as AntInputNumber } from "antd";
import { InputNumberProps } from './components.types';
import {Colors} from "../../colors";

export const StyledInputNumber = styled(AntInputNumber)<InputNumberProps>`
       caret-color: ${Colors.orange.dark};

    &.ant-input-disabled {
        ::placeholder {
            color: ${Colors.blue.main50};
        }
    }
`;

