import * as React from "react";
import { SVGProps } from "react";
const SvgChain = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="chain" className="anticon anticon-chain">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9642 1.5L22.9139 7.44975L15.5499 14.8137L13.3253 12.5891L12.2142 13.7003L14.5139 16L7.14994 23.364L1.2002 17.4142L8.56416 10.0503L10.7999 12.286L11.9111 11.1749L9.6002 8.86396L16.9642 1.5ZM14.7395 11.1749L16.9499 8.96447L15.5357 7.55025L13.3253 9.76066L12.4286 8.86396L16.9642 4.32843L20.0855 7.44975L15.5499 11.9853L14.7395 11.1749ZM9.38573 13.7003L8.56416 12.8787L4.02862 17.4142L7.14994 20.5355L11.6855 16L10.7999 15.1145L8.46466 17.4497L7.05045 16.0355L9.38573 13.7003Z" />
    </svg>
    </span>
);
export default SvgChain;
