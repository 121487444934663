import * as React from "react";
import { SVGProps } from "react";
const SvgListA = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.126 7H8v2h8.354a4 4 0 0 1-1.228-2ZM8 11v2h12v-2H8Zm0 4v2h12v-2H8Zm-4 0v2h2v-2H4Zm0-6h2V7H4v2Zm0 2v2h2v-2H4Z"
      fill="#21293D"
    />
    <circle cx={19} cy={6} r={2} fill="#FF626E" />
  </svg>
);
export default SvgListA;
