import * as React from "react";
import { SVGProps } from "react";
const SvgNoSigned = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="nosigned" className="anticon anticon-nosigned">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM14.4142 8.51472L15.8284 9.92893L13.5858 12.1708L15.8284 14.4142L14.4142 15.8284L12.1716 13.585L9.92893 15.8284L8.51472 14.4142L10.7574 12.1708L8.51472 9.92893L9.92893 8.51472L12.1716 10.7565L14.4142 8.51472Z" fill="#FF626E"/>
    </svg>
    </span>
);
export default SvgNoSigned;
