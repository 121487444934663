import * as React from "react";
import { SVGProps } from "react";
const SvgZoomIn = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="zoomin" className="anticon anticon-zoomin">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9175 17.835C7.09708 17.835 4 14.738 4 10.9175C4 7.09708 7.09708 4 10.9175 4C14.738 4 17.835 7.09708 17.835 10.9175C17.835 12.4115 17.3614 13.7949 16.5562 14.9257L21 19.3695L19.3695 21L14.9257 16.5562C13.7949 17.3614 12.4115 17.835 10.9175 17.835ZM11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 8H10V10H8V12H10V14H12V12H14V10H12V8Z" />
    </svg>
    </span>
);
export default SvgZoomIn;