import styled, { css } from "styled-components";

import {
  Text14_20Mixin,
  Text13_18Mixin,
  Text13_15Mixin,
  Text12_16Mixin,
  Text10_14Mixin,
  Text8_10Mixin,
} from "./mixins";
import { fontMixin } from "../mixins";

import type { TextProps } from "./components.types";
type StypedTextProps = Omit<TextProps, 'weight'>&{$weight?: "regular" | "medium" | "semibold"};

const weightness = {
  regular: "400",
  medium: "500",
  semibold: "600",
};

const sizeMap = {
  "14/20": Text14_20Mixin,
  "13/18": Text13_18Mixin,
  "13/15": Text13_15Mixin,
  "12/16": Text12_16Mixin,
  "10/14": Text10_14Mixin,
  "8/10": Text8_10Mixin,
};

export const SpanStyled = styled.span<StypedTextProps>`
  ${fontMixin}
  ${({ size }) => sizeMap[size]}
  font-weight: ${({ $weight }) => weightness[$weight || "regular"]};
`;

export const PStyled = styled.p<StypedTextProps>`
  ${fontMixin}
  ${({ size }) => sizeMap[size]}
  font-weight: ${({ $weight }) => weightness[$weight || "regular"]};
`;
