import * as React from "react";
import { SVGProps } from "react";
const SvgTemplates = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="templates" className="anticon anticon-templates"> 
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 3H5H19V5V15H21V21H19H5V19V9H3V3ZM17 5H5V7H7V19H19V17H17V5ZM16 7V9H8V7H16ZM16 13V11H8V13H16ZM16 15V17H8V15H16Z" />
    </svg>
    </span>
);
export default SvgTemplates;
