import type { CSSProperties } from 'react';
import {Colors} from './colors';
export const TableComponentToken = {
	/// Background color of table sorted column	string #fafafa
	bodySortBg: 'transparent',
	/// Border color of table	string #f0f0f0
	borderColor: '#f0f0f0',
	/// Font size of table cell (large size by default)	number 14
	cellFontSize: 12,
	/// Font size of table cell (middle size)	number 14
	cellFontSizeMD: 10,
	/// Font size of table cell (small size)	number 14
	cellFontSizeSM: 8,
	/// Vertical padding of table cell	number 16
	cellPaddingBlock: 16,
	/// Vertical padding of table cell (middle size)	number 12
	cellPaddingBlockMD: 12,
	/// Vertical padding of table cell (small size)	number 8
	cellPaddingBlockSM: 8,
	/// Horizontal padding of table cell (large size by default)	number 16
	cellPaddingInline: 16,
	/// Horizontal padding of table cell (middle size)	number 8
	cellPaddingInlineMD: 8,
	/// Horizontal padding of table cell (small size)	number 8
	cellPaddingInlineSM: 8,
	/// Background of expand button	string #ffffff
	expandIconBg: 'transparent',
	/// Color of filter dropdown	string #ffffff
	filterDropdownBg: '#ffffff',
	/// Background of filter dropdown menu item	string #ffffff
	filterDropdownMenuBg: '#ffffff',
	/// Background color of fixed table header when sorted	string #f0f0f0
	fixedHeaderSortActiveBg: '#f0f0f0',
	/// Background of footer	string #fafafa
	footerBg: '#fafafa',
	/// Color of footer text	string rgba(0, 0, 0, 0.88)
	footerColor: 'rgba(0, 0, 0, 0.88)',
	/// Background of table header	string #fafafa
	headerBg: Colors.mainLighterBlue,
	/// Border radius of table header	number 8
	headerBorderRadius: 0,
	/// Color of table header text	string rgba(0, 0, 0, 0.88)
	headerColor: Colors.mainBlue,
	/// Background color of table header filter button when hovered	string rgba(0, 0, 0, 0.06)
	headerFilterHoverBg: 'rgba(0, 0, 0, 0.06)',
	/// Background color of table header when sorted	string #f0f0f0
	headerSortActiveBg: '#f0f0f0',
	/// Background color of table header when sorted and hovered	string #f0f0f0
	headerSortHoverBg: '#f0f0f0',
	/// Split border color of table header	string #f0f0f0
	headerSplitColor: '#f0f0f0',
	/// Background color of table expanded row	string rgba(0, 0, 0, 0.02)
	rowExpandedBg: Colors.mainLightestBlue,
	/// Background color of table hovered row	string #fafafa
	rowHoverBg: Colors.additionalRed,
	/// Background color of table selected row	string #e6f4ff
	rowSelectedBg: Colors.additionalRed,
	/// Background color of table selected row when hovered	string #bae0ff
	rowSelectedHoverBg: Colors.additionalRed,
	/// Width of selection column	string | number 32
	selectionColumnWidth: 32,
	/// Background of sticky scrollbar	string rgba(0, 0, 0, 0.25)
	stickyScrollBarBg: 'rgba(0, 0, 0, 0.25)',
	/// Border radius of sticky scrollbar	number 100
	stickyScrollBarBorderRadius: 100,

	// GLOBAL

	/// Container background color, e.g: default button, input box, etc. Be sure not to confuse this with `colorBgElevated`.	string #ffffff
	colorBgContainer: '#ffffff',
	/// Control the color of hyperlink.	string #1677ff
	colorLink: Colors.mainBlue,
	/// Control the color of hyperlink when clicked.	string #0958d9
	colorLinkActive: Colors.mainDarkBlue,
	/// Control the color of hyperlink when hovering.	string #69b1ff
	colorLinkHover: Colors.mainDarkBlue,
	/// Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.	string #1677ff
	colorPrimary: Colors.mainBlue,
	/// Used as the color of separator, this color is the same as colorBorderSecondary but with transparency.	string rgba(5, 5, 5, 0.06)
	colorSplit: 'rgba(5, 5, 5, 0.06)',
	/// Default text color which comply with W3C standards, and this color is also the darkest neutral color.	string rgba(0, 0, 0, 0.88)
	colorText: Colors.mainDarkBlue,
	/// Control the font color of text description.	string rgba(0, 0, 0, 0.45)
	colorTextDescription: 'rgba(0, 0, 0, 0.45)',
	/// Control the color of text in disabled state.	string rgba(0, 0, 0, 0.25)
	colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
	/// Control the font color of heading.	string rgba(0, 0, 0, 0.88)
	colorTextHeading: 'rgba(0, 0, 0, 0.88)',
	/// Border radius of base components	number 6
	borderRadius: 0,
	/// Control the secondary box shadow style of an element.	string 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
	boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
	/// Control the interactive size of control component.	number 16
	controlInteractiveSize: 16,
	/// Control the background color of control component item when active.	string #e6f4ff
	controlItemBgActive: '#e6f4ff',
	/// Control the background color of control component item when hovering.	string rgba(0, 0, 0, 0.04)
	controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
	/// The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.	string -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
	// fontFamily: '-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'',
	/// The most widely used font size in the design system, from which the text gradient will be derived.	number 14
	fontSize: 12,
	/// Control the font size of operation icon in Select, Cascader, etc. Normally same as fontSizeSM.	number 12
	fontSizeIcon: 12,
	/// Small font size	number 12
	fontSizeSM: 12,
	/// Control the font weight of heading components (such as h1, h2, h3) or selected item.	number 600
	fontWeightStrong: 600,
	/// Line height of text.	number 1.5714285714285714
	lineHeight: 1.5714285714285714,
	/// Border style of base components	string solid
	lineType: 'solid',
	/// Border width of base components	number 1
	lineWidth: 1,
	/// Control the margin of an element, with a medium size.	number 16
	margin: 16,
	/// Control the margin of an element, with the smallest size.	number 4
	marginXXS: 4,
	/// Motion speed, medium speed. Used for medium element animation interaction.	string 0.2s
	motionDurationMid: '0.2s',
	/// Motion speed, slow speed. Used for large element animation interaction.	string 0.3s
	motionDurationSlow: '0.3s',
	/// Control the opacity of the loading state.	number 0.65
	opacityLoading: 0.65,
	/// Control the padding of the element.	number 16
	padding: 16,
	/// Control the extra small padding of the element.	number 8
	paddingXS: 8,
	/// Control the extra extra small padding of the element.	number 4
	paddingXXS: 4,
}