import * as React from "react";
import { SVGProps } from "react";
const SvgMap = (props: SVGProps<SVGSVGElement>) => (
    <span role="img" aria-label="map" className="anticon anticon-map">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.7318 3 6 4.09272 6 6V12V18L6.00628 18.157C6.15218 19.9671 8.82258 21 12 21C15.2682 21 18 19.9073 18 18V12V6C18 4.09272 15.2682 3 12 3ZM16 12V8.31516C14.9318 8.76231 13.5242 9 12 9C10.4758 9 9.06818 8.76231 8 8.31516V12C8 12.0191 8.24239 12.2484 8.83586 12.4857C9.64147 12.808 10.7764 13 12 13C13.2236 13 14.3585 12.808 15.1641 12.4857C15.6587 12.2879 15.9095 12.0958 15.9793 12.0261L16 12ZM8 14.3152C9.06818 14.7623 10.4758 15 12 15C13.5242 15 14.9318 14.7623 16 14.3152V18L15.9793 18.0261C15.9095 18.0958 15.6587 18.2879 15.1641 18.4857C14.3585 18.808 13.2236 19 12 19C10.7764 19 9.64147 18.808 8.83586 18.4857C8.24239 18.2484 8 18.0191 8 18V14.3152ZM16 6C16 5.98088 15.7576 5.75165 15.1641 5.51426C14.3585 5.19202 13.2236 5 12 5C10.7764 5 9.64147 5.19202 8.83586 5.51426C8.24239 5.75165 8 5.98088 8 6C8 6.01912 8.24239 6.24835 8.83586 6.48574C9.64147 6.80798 10.7764 7 12 7C13.2236 7 14.3585 6.80798 15.1641 6.48574C15.6587 6.28791 15.9095 6.09576 15.9793 6.02613L16 6Z"/>
    </svg>
    </span>
);
export default SvgMap;
