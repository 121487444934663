import React from "react";
import type {CollapseProps} from "./components.types";
import {StyledCollapse} from "./styled";

export const Collapse: React.FC<CollapseProps> = ({
                                                      errorCaption,
                                                      items,
                                                      ...args
                                                  }) => {


    return (
        <StyledCollapse
            items={items}
            {...args}
        />
    );
};

